import React from 'react'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'

const NavChangeDeposit = () => {
  return (
    <div className='changeNavStyle'>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.default.url} className='item'>واریز تومان</NavLink>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.high.url} className='item itemWithNew'>
        واریز شناسه‌دار
        <span className='txtNew'>جدید</span>
      </NavLink>
      <NavLink to={menu.financial.childs.depositAll.childs.depositCoin.url} className='item'>واریز ارز دیجیتال</NavLink>
    </div>
  )
}

export default NavChangeDeposit