import _ from "lodash"
import { IoMdCloseCircle } from "react-icons/io"
import { detect_bank } from "../../../helpers/bankName"
import { useSelector } from "react-redux"

const CardDetail = ({ data, close }: any) => {
  const userfullName = useSelector((state: any) => state.publicApis.userFullName)

  return (
    <div className="cardBankStyle cardDetail">
      <IoMdCloseCircle className='close' size={30} onClick={close} />
      <div className="bodyDetail">
        <div className='body'>
          <div className='top'>
            <p id='confirm'>تایید شد</p>
            <div>
              <h4>{data.bank}</h4>
              <img className="imgBank" src={`${!_.isEmpty(detect_bank(data?.number).bank_logo) ? detect_bank(data?.number).bank_logo : "/icons/iranflag.svg"}`} alt="/icons/iranflag.svg" />
            </div>
          </div>
          <h4 id='number' className="fontCard">{data?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</h4>
          <p id='name'>{userfullName ?? ""}</p>
          <h5 id='shaba'>شماره شبا: {data.iban}</h5>
          <img className="imgShadowBank" src={`${!_.isEmpty(detect_bank(data?.number).bank_logo) ? detect_bank(data?.number).bank_logo : "/icons/iranflag.svg"}`} alt="/icons/iranflag.svg" />
        </div>
      </div>
    </div>
  )
}

export default CardDetail