import React, { useState } from 'react';
import { CircularProgress, DialogContent, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as DialogShow from '../../../services/redux/dialog';
import { IoMdCloseCircle } from 'react-icons/io';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import * as BackdropShow from '../../../services/redux/backdrop';
import { useTranslation } from 'react-i18next';
import VerifyCodeEmailPhone from './VerifyCodeEmailPhone';
import useSnack from '../../../hooks/useSnack';

const EditEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [myLoading, setMyLoading] = useState(false);


  const send = async () => {
    if (!email.endsWith(".com") || !email.concat("@")) {
      snackUse.showError("لطفا ایمیل را وارد کنید");
      setErrorEmail(true);
      setTimeout(() => { setErrorEmail(false) }, 1000);
      return
    }

    setMyLoading(true)

    const result = await gather(`${config.api}/v1/user/email`).post({ email: email })

    if (result.code === 200) {
      // dispatch(DialogShow.show(<ConfirmCodeEmailPhone flag={false} value={oldEmail} newValue={email} />))
      dispatch(DialogShow.show(<VerifyCodeEmailPhone flag={false} value={email} />));
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false)
  }

  return (
    <>
      <div className="titleCloseDialog">
        <h3>افزودن ایمیل جدید</h3>
        <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
      </div>
      <DialogContent>
        <div className="contentDetailDialog dialogAddEmail">
          <FormControl className={`formControlLogin ${errorEmail ? "redBorder" : ""}`} variant="outlined">
            <InputLabel>ایمیل جدید</InputLabel>
            <OutlinedInput fullWidth label="ایمیل جدید" type='email'
              onChange={(e) => setEmail(e.target.value)} placeholder='ایمیل خود را وارد کنید'
              startAdornment={<img crossOrigin='anonymous' src="/icons/emailLogin.svg" height="22px" alt="" />} />
          </FormControl>

          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress style={{ color: "#fff" }} size={25} /></div>
              :
              <button onClick={send} className="submit">ثبت</button>
          }
        </div>
      </DialogContent>
    </>
  )
}

export default EditEmail;