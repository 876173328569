import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import gather from "../../helpers/gather";
import { config } from "../../config/config";
import _ from "lodash";
import { digital } from "../../config/variable";

const initialState = {
  userFullName: "",
  listCoin: {} as any[],
  listCoinObjAll: {} as any,
  listCoinObj: {} as any,
  listCoinObjByID: {} as any,
  listLevelObj: {} as any,
  listLevelObjByID: {} as any,
  listNetwork: {} as any[],
  listNetworkObj: {} as any,
  listNetworkObjByID: {} as any,
  profile: {} as any,
  asset: {} as any[],
  assetObj: {} as any[],
  balance: [] as any[],
  mainWalletObj: {} as any,
  cardBanck: [] as any[],
  orderList: [] as any[],
  listAllPairObj: {} as any,
  listVoucherObj: {} as any,
  listVoucherObjID: {} as any,
  listGatewayObj: {} as any,
  codeUp25: [] as any[],
  settingApi: {} as any[],
  alarmApi: {} as any[],
  alarmNumUnread: 0,
  alarmReadApi: {} as any[],
  noticeApi: {} as any[],
  pageLearnApi: {} as any[],
}

export const listCoin = createAsyncThunk('listCoin/fetchListCoin', async () => {
  const data = await gather(`${config.api}/v1/coin`).get();
  return data;
})
export const listLevel = createAsyncThunk('listLevel/fetchListLevel', async () => {
  const data = await gather(`${config.api}/v1/level`).get();
  return data
})
export const listNetwork = createAsyncThunk('listNetwork/fetchListNetwork', async () => {
  const data = await gather(`${config.api}/v1/network`).get();
  return data;
})
export const profile = createAsyncThunk('profile/fetchProfile', async () => {
  const data = await gather(`${config.api}/v1/user`).get();
  return data;
})
export const asset = createAsyncThunk('asset/fetchAsset', async () => {
  const data = await gather(`${config.api}/v1/asset`).get();
  return data;
})
export const balance = createAsyncThunk('balance/fetchBalance', async () => {
  const data = await gather(`${config.api}/v1/balance`).get();
  return data;
})
export const cardBanck = createAsyncThunk('cardBanck/fetchCardBanck?sortBy=createdAt&orderBy=desc', async () => {
  const data = await gather(`${config.api}/v1/card`).get();
  return data;
})
export const order = createAsyncThunk('order/fetchOrder', async () => {
  const data = await gather(`${config.api}/v1/order`).get();
  return data;
})
export const codeUp25 = createAsyncThunk('codeUp25/fetchcodeUp25', async () => {
  const data = await gather(`${config.api}/v1/cashCode`).post({});
  return data;
})
export const setting = createAsyncThunk('setting/fetchSetting', async () => {
  const data = await gather(`${config.api}/v1/setting`).get();
  return data;
})

export const alarm = createAsyncThunk('alarm/fetchAlarm', async () => {
  const data = await gather(`${config.api}/v1/alarm`).get();
  return data;
})

export const alarmRead = createAsyncThunk('alarmRead/fetchAlarmRead', async () => {
  const data = await gather(`${config.api}/v1/alarm/read`).get();
  return data;
})

export const notice = createAsyncThunk('notice/fetchNotice', async () => {
  const data = await gather(`${config.api}/v1/notice`).get();
  return data;
})

export const pageLearn = createAsyncThunk('pageLearn/fetchpageLearn', async () => {
  const data = await gather(`${config.api}/v1/page`).get();
  return data;
})

export const PublicApis = createSlice({
  name: "publicApis",
  initialState,
  reducers: {
    updateWallet: (state, action) => {
      if (action?.payload?.amount) {
        state.mainWalletObj[action?.payload?.coin ?? 'tmn'] = action?.payload
      }
    },
    setZeroCountAlarm: (state) => {
      state.alarmNumUnread = 0
    }
  }
  , extraReducers: (builder) => {
    builder
      .addCase(listCoin.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.listCoin = action.payload?.data
          action.payload?.data?.forEach(function (value: any) {
            state.listCoinObjAll[value.symbol?.toLocaleLowerCase()] = value;
            state.listCoinObjAll[value.symbol?.toLocaleLowerCase()].icon = `${config.api}/icons/${value.symbol.toUpperCase()}.png`;

            value?.networks?.forEach((n: any) => {
              if (!n?.voucher?.create?.isActive && !n?.voucher?.use?.isActive) {
                let newItem: any = {};
                let newItem2: any = {};

                state.listCoinObj[value.symbol?.toLocaleLowerCase()] = value;
                state.listCoinObj[value.symbol?.toLocaleLowerCase()].icon = `${config.api}/icons/${value.symbol?.toUpperCase()}.png`;

                state.listCoinObjByID[value._id] = value;
                state.listCoinObjByID[value._id].icon = `${config.api}/icons/${value.symbol?.toUpperCase()}.png`;

                if (value.symbol?.toLocaleLowerCase() !== "usdt") {
                  newItem = _.cloneDeep(value);
                  newItem.icon = `${config.api}/icons/${value.symbol?.toUpperCase()}.png`;
                  newItem.pairSymbolShow = value.symbol?.toUpperCase() + "/USDT";
                  newItem.pairSymbol = value.symbol?.toLocaleLowerCase() + "usdt";
                  newItem.myPair = "usdt";
                  newItem.myPairFa = "تتر";
                  state.listAllPairObj[value.symbol?.toLocaleLowerCase() + "usdt"] = newItem
                }

                newItem2 = _.cloneDeep(value);
                newItem2.icon = `${config.api}/icons/${value.symbol?.toUpperCase()}.png`;
                newItem2.pairSymbolShow = value.symbol?.toUpperCase() + "/TMN";
                newItem2.pairSymbol = value.symbol?.toLocaleLowerCase() + "tmn";
                newItem2.myPair = "tmn";
                newItem2.myPairFa = "تومان";
                state.listAllPairObj[value.symbol?.toLocaleLowerCase() + "tmn"] = newItem2
              }

              if (digital.includes(value.symbol?.toLocaleLowerCase()) && value.pair.tmn.otc) {
                state.listVoucherObjID[value._id] = value;
                state.listVoucherObj[value.symbol?.toLocaleLowerCase()] = value;
                state.listVoucherObj[value.symbol?.toLocaleLowerCase()].icon = `${config.api}/icons/${value.symbol.toUpperCase()}.png`;
              }

              if (!_.isEmpty(n?.gateway) && n?.gateway?.isActive) {
                state.listGatewayObj[value.symbol?.toLocaleLowerCase()] = value;
                state.listGatewayObj[value.symbol?.toLocaleLowerCase()].icon = `${config.api}/icons/${value.symbol.toUpperCase()}.png`;
              }
            })
          })
        }
      })
      .addCase(listLevel.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          action.payload?.data?.forEach(function (value: any) {
            state.listLevelObj[value.index] = value;
            state.listLevelObjByID[value._id] = value;
          })
        }
      })
      .addCase(listNetwork.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.listNetwork = action.payload?.data
          action.payload?.data?.forEach(function (value: any) {
            state.listNetworkObj[value.symbol] = value;
            state.listNetworkObjByID[value._id] = value;
          })
        }
      })
      .addCase(profile.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.profile = action.payload?.data;
          state.userFullName = (action.payload?.data?.name ?? "") + " " + (action.payload?.data?.family ?? "")
        }
      })
      .addCase(asset.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.asset = action.payload?.data;
          action.payload?.data?.forEach(function (value: any) {
            state.assetObj[value.coin?.symbol] = value;
            state.mainWalletObj[value.coin?.symbol] = value;
          })
        }
      })
      .addCase(balance.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.balance = action.payload?.data;
          state.mainWalletObj["tmn"] = action.payload?.data
        }
      })
      .addCase(cardBanck.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.cardBanck = action.payload?.data;
        }
      })
      .addCase(order.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.orderList = action.payload?.data;
        }
      })
      .addCase(codeUp25.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          if (action.payload?.data) {
            const newList = action.payload?.data?.map((i: any, n: number) => i?.data);
            let addSort = newList?.flat()?.map((i: any) => {
              if (i?.bank == "mellat") {
                return { ...i, "sort": 0 }
              } else {
                return { ...i, "sort": 1 }
              }
            })
            state.codeUp25 = addSort;
          }
        }
      })
      .addCase(setting.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.settingApi = action.payload?.data;

        }
      })
      .addCase(alarm.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.alarmApi = action.payload?.data;

          let count = 0
          action.payload?.data?.forEach((item: any) => {
            if (!item?.isRead) {
              count += 1
            }
          })

          state.alarmNumUnread = count
        }
      })
      .addCase(alarmRead.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.alarmReadApi = action.payload?.data;

        }
      })
      .addCase(notice.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.noticeApi = action.payload?.data;
        }
      })
      .addCase(pageLearn.fulfilled, (state, action) => {
        if (action.payload.code === 200) {
          state.pageLearnApi = action.payload;
        }
      })
  }
})

export const {
  updateWallet, setZeroCountAlarm
} = PublicApis.actions;

export default PublicApis.reducer;