import * as BackdropShow from "../../../services/redux/backdrop";
import VerificationInput from "react-verification-input";
import { config } from "../../../config/config";
import { useTranslation } from "react-i18next";
import gather from "../../../helpers/gather";
import { useDispatch } from "react-redux";
import Countdown from "react-countdown";
import { useState } from "react";
import _ from "lodash";
import { IoMdCloseCircle } from "react-icons/io";
import * as DialogShow from "../../../services/redux/dialog";
import useSnack from "../../../hooks/useSnack";
import EditEmail from "./EditEmail";

const ConfirmEmailCode = ({ value }: { value: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [showTimer, setShowTimer] = useState({ flag: 1, show: true, myTime: Date.now() + 240000 });
  const [code, setCode] = useState('');

  const send = async (code: any) => {
    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/user/email/confirm`).post({ code })

    if (result.code === 200) {
      dispatch(DialogShow.show(<EditEmail />));
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      dispatch(BackdropShow.hide());
      setCode("");
    }

    dispatch(BackdropShow.hide());
  }

  const reloadCode = async () => {
    if (showTimer.show) return;

    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/user/email`).post({ "email": value })

    if (result.message === 200) {
      setShowTimer({ flag: 1, show: true, myTime: Date.now() + 240000 });
      setCode('')
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3 >AAA</h3>;
    } else {
      return <span>0{minutes}:{seconds < 10 && "0"}{seconds}</span>;
    }
  };


  return (
    <>
      <div className="titleCloseDialog">
        <h3>کد تاییدارسالی به ایمیل</h3>
        <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
      </div>
      <div className="verfiyEmailUser">
        <div className="text-right">
          <span className="textvr">کد تایید به ایمیل {value} ارسال شد</span>
        </div>
        <div className='codeNumberBox'>
          <VerificationInput
            onComplete={(e) => send(e)}
            onChange={(e) => { Number(e) >= 0 && setCode(e) }}
            value={code}
            placeholder='-'
            inputProps={{ type: "tel" }}
            classNames={{
              container: "containerVerify",
              character: "characterVerify",
              characterFilled: "character--filled",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
          />
        </div>
        <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
          {showTimer.show &&
            <div className="btn">
              <Countdown date={showTimer.myTime} renderer={renderer}
                onComplete={() => setShowTimer({ flag: 0, show: false, myTime: Date.now() + 240000 })} />
            </div>}
          {!showTimer.show &&
            <h5 className="again">ارسال مجدد</h5>}
          <div className="progress"></div>
        </div>
      </div>
    </>
  )
}

export default ConfirmEmailCode;