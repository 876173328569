import React, { memo, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import NavChangeHistory from './item/NavChangeHistory'
import History from '../depositAll/items/History'
import { menu } from '../../config/menu'
import useLevelIndex from '../../hooks/useLevelIndex'
import Footer from '../../components/Footer'
import PaymentHistory from './item/PaymentHistory'

const HistoryDepositFiat = () => {
  // const checkLevelindex = useLevelIndex();

  useEffect(() => {
    document.title = menu.financial.childs.transactionHistory.childs.depositCash.title;
  }, []);

  // useEffect(() => {
  //   checkLevelindex.checkIndex();
  // }, [checkLevelindex.myProfile()])

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='transactionHistory'>
            <NavChangeHistory />
            <PaymentHistory flag={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default memo(HistoryDepositFiat)