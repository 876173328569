import React, { useState } from 'react';
import { CircularProgress, DialogContent, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as DialogShow from '../../../services/redux/dialog';
import { IoMdCloseCircle } from 'react-icons/io';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import ConfirmPhoneCode from './ConfirmPhoneCode';
import { toEnglishDigits } from '../../../helpers/tools';
import useSnack from '../../../hooks/useSnack';

const EditPhone = ({ oldMobile }: { oldMobile: any }) => {
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [errorMobile, setErrorMobile] = useState(0);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);


  const handleMobile = (e: any) => {
    setMobile(e.target.value)
    let newMobile = e.target.value.startsWith("0") ? e.target.value : '0' + e.target.value;
    if (newMobile.length === 11) {
      setErrorMobile(2);
    } else {
      setErrorMobile(0)
    }
  }

  const send = async () => {
    let newMobile = mobile.startsWith("0") ? mobile : '0' + mobile;
    if (newMobile.length !== 11) {
      setErrorMobile(1);
      setTimeout(() => { setErrorMobile(0) }, 3000);
      return
    }
    setErrorMobile(2);

    setMyLoading(true)

    const result = await gather(`${config.api}/v1/user/mobile`).post({ "mobile": toEnglishDigits(mobile?.toString()) })

    if (result.code === 200) {
      dispatch(DialogShow.show(<ConfirmPhoneCode value={oldMobile} newValue={toEnglishDigits(mobile?.toString())} />))
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false)
  }

  return (
    <>
      <div className="titleCloseDialog">
        <h3>تلفن همراه جدید</h3>
        <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
      </div>
      <DialogContent>
        <div className="contentDetailDialog">
          <FormControl className={`formControlLogin ${errorMobile === 1 ? "redBorder" : ""}`} variant="outlined">
            <InputLabel>شماره همراه جدید</InputLabel>
            <OutlinedInput fullWidth label="شماره همراه جدید" type='number'
              onChange={handleMobile} placeholder='شماره همراه جدید را وارد کنید'
              startAdornment={
                <div className="iconInput cityCode">
                  <p>+98</p>
                  <img crossOrigin='anonymous' src="/icons/iranflag.svg" height="22px" alt="" />
                </div>}
              endAdornment={<>
                <img crossOrigin='anonymous' className={`doneit ${errorMobile === 2 ? "" : "none"}`} src="/icons/donelogin.svg" alt="" />
                <img crossOrigin='anonymous' className={`errorit ${errorMobile === 1 ? "" : "none"}`} src="/icons/loginerror.svg" alt="" /></>} />
          </FormControl>
          <div className="textInfoDialog">
            <span className="iconInfo"></span>
            <h6>با توجه به احراز هویت شما، نیاز است که شماره موبایل جدید حتما به نام صاحب حساب کاربری باشد.</h6>
          </div>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress style={{ color: "#fff" }} size={25} /></div>
              :
              <button onClick={send} className="submit">ادامه</button>
          }
        </div>
      </DialogContent>
    </>
  )
}

export default EditPhone