import { Box, CircularProgress, Divider, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { config } from '../../../../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { zeroFormat, removeComma, toEnglishDigits } from '../../../../helpers/tools';
import { marksSlidBar } from '../../../../config/variable';
import { useTranslation } from 'react-i18next';
import * as BackdropShow from '../../../../services/redux/backdrop';
import gather from '../../../../helpers/gather';
import _ from 'lodash';
import CustomInputText from '../../../../components/CustomInputText';
import * as DialogShow from '../../../../services/redux/dialog';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink, useSearchParams } from 'react-router-dom';
import useTicker from '../../../../hooks/useTicker';
import DetailWDCoin from '../../../withdrawAll/dialogs/DetailWDCoin';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { menu } from '../../../../config/menu';
import NeedLogin from '../../../../components/NeedLogin';
import useSnack from '../../../../hooks/useSnack';

const BodyOrderPerfict = ({ orderChange, setOrderChange, coin, setReload }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const listVoucherObj: any = useSelector((state: any) => state.publicApis?.listVoucherObj);
  const mainWalletObj = useSelector((state: any) => state.user?.mainWalletObj);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [payment, setPayment] = useState(0);
  const [amount, setAmount] = useState(0);
  const [valueSlider, setValueSlider] = useState(100);
  const ticker = useTicker();
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const pastWallet = async () => {
    const text = await navigator.clipboard.readText();
    setAddress(text);
  }

  const handelChange = (e: any) => {
    setAmount(removeComma(e));
    const price = ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn');
    const total = orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setPayment(removeComma(zeroFormat(total, listVoucherObj[coin?.symbol]?.precision, false)));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(e)) / balance) * 100);
    setValueSlider(pers);
  }

  const handelChangeTMN = (e: any) => {
    setPayment(removeComma(e));
    const price = ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn');
    const total = !orderChange
      ? Number(removeComma(e)) / Number(price ?? 0)
      : Number(removeComma(e)) * Number(price ?? 0)

    setAmount(removeComma(zeroFormat(total, listVoucherObj[coin?.symbol]?.precision, false)));

    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;
    const pers = 100 - ((Number(removeComma(total)) / balance) * 100);
    setValueSlider(pers);
  }

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);
    const balance = mainWalletObj[orderChange ? 'tmn' : coin?.symbol]?.free;

    const percent = 100 - Number(newValue);

    const amount = balance * (percent / 100) || 0;
    handelChange(zeroFormat(amount, listVoucherObj[coin?.symbol]?.precision, false))
  }

  const sellStep1 = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/deposit/gateway`).post({
      "coin": coin?._id,
      "network": coin?.networks[0]?.network?._id,
      "amount": Number(toEnglishDigits(amount?.toString())),
      "callback": config.website + '/order/currency?amount=' + Number(toEnglishDigits(amount?.toString())) + '&coin=' + coin._id,
    });

    if (result.message === 200) {
      snackUse.showSaccess("درحال انتقال به سایت مربوطه");
      window.location.href = result.data.url;
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }


  const buy = async () => {
    if (_.isEmpty(coin)) {
      snackUse.showError('لطفا یک ارز را انتخاب کنید');
      return
    }

    if (payment <= 0) {
      snackUse.showError('مقدار را وارد کنید');
      return
    }

    if ((address?.trim()?.length < 9 ||
      (coin?.symbol == "wm" && !address?.startsWith("Z")) ||
      (coin?.symbol == "pm" && !address?.startsWith("U")))) {
      snackUse.showError('آدرس کیف پول را بررسی کنید');
      setAddressError(true);
      setTimeout(() => { setAddressError(false); }, 2000);
      return
    }

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/order/digital/buy`).post({
      "coin": coin?._id,
      "network": coin?.networks[0]?.network?._id,
      "pair": "tmn",
      "amount": Number(toEnglishDigits(payment?.toString())),
      "receiver": toEnglishDigits(address),
    });

    if (result.message === 200) {
      setValueSlider(100);
      setAmount(0);
      setPayment(0);
      setReload(new Date().getTime()?.toString());
      dispatch(DialogShow.show(<DetailWDCoin data={result?.data} deposit={false} close={() => dispatch(DialogShow.hide())} />));
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }


  useEffect(() => {
    setAmount(0);
    setPayment(0);
  }, [coin])

  useEffect(() => {
    setTimeout(async () => {
      const success = searchParams.get('success');
      const message = searchParams.get('message');
      if (success && message == "200") {
        dispatch(BackdropShow.show());

        const amount = searchParams.get('amount');
        const coinParams = searchParams.get('coin');

        const result = await gather(`${config.api}/v1/order`).post({
          coin: coinParams,
          price: ticker.sell(coin?.symbol + 'tmn'),
          pair: 'tmn',
          side: 'sell',
          trade: 'otc',
          amount: Number(toEnglishDigits(amount?.toString())),
          stop:null
        });

        if (result.code === 200) {
          snackUse.showSaccess('سفارش شما با موفقیت ثبت شد');
        } else {
          snackUse.showError(t(result.message) || t("99999"));
        }

        dispatch(BackdropShow.hide());
      }

      searchParams.delete('amount');
      searchParams.delete('hash');
      searchParams.delete('success');
      searchParams.delete('message');
      searchParams.delete('coin');

      setSearchParams(searchParams);
    }, 100);

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='stepOrder'>
        <div>
          <span className='icon-SecurityCard'></span>
          <h4>مرحله دوم <b>نوع معامله</b></h4>
          <div className='dividerVer'></div>
        </div>
        <p>نوع معامله را مشخص کنید</p>
      </div>
      <div className='bodyFastOrder bodyOrderCurrency'>
        <div className='right'>
          <div className='btnChangeOrder'>
            <button className={`buy ${orderChange && 'active'}`} onClick={() => { setOrderChange(true); setPayment(0); setAmount(0) }}>
              <span className='m-IconBuy'></span>
              <p>خرید</p>
            </button>
            <button className={`sell ${!orderChange && 'active'}`} onClick={() => { setOrderChange(false); setPayment(0); setAmount(0) }}>
              <span className='m-IconSell'></span>
              <p>فروش</p>
            </button>
          </div>
          <div className='enterAmount'>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={!orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} value={amount > 0 ? amount : ''} onChange={(e) => { handelChange(e.target.value) }} placeholder='پرداخت میکنم' />
            </div>
            <div className='btnExchange' onClick={() => { setOrderChange(!orderChange); setPayment(amount); setAmount(payment) }}>
              <LazyLoadImage crossOrigin='anonymous' src='/icons/m-IconRepeat.svg' alt='' />
            </div>
            <div className='imgInput'>
              <LazyLoadImage crossOrigin='anonymous' src={orderChange ? `${config.imgUrl}${coin?.symbol?.toUpperCase()}.png` : '/icons/iranflag.svg'} alt='' />
              <NumericFormat allowNegative={false} thousandSeparator={true} value={payment > 0 ? payment : ''} onChange={(e) => handelChangeTMN(e.target.value)} placeholder='دریافت میکنم' />
            </div>
          </div>
          <p className='wrningText opacity0'>مقدار وارد شده، از حداکثر مقدار مجاز بیشتر می‌باشد.</p>
          <div className='priceNow'>
            {/* <h4>موجودی سایت: <b>0 {coin?.symbol?.toUpperCase()}</b></h4> */}
            <h4>قیمت تومانی در لحظه:  <b>TMN {zeroFormat(ticker[orderChange ? "buy" : "sell"](coin?.symbol + 'tmn'))}</b></h4>
          </div>
          {orderChange &&
            <div className='sliderRage my-20'>
              <Box sx={{ width: '100%' }}>
                <Slider
                  aria-label='Restricted values'
                  track='inverted'
                  value={typeof valueSlider === 'number' ? valueSlider < 0 ? 100 : valueSlider : 100}
                  onChange={handleSliderBar}
                  marks={marksSlidBar}
                />
              </Box>
            </div>
          }
        </div>
        {/* {orderChange &&
          <div className='left'>
            <h4 onClick={() => handelChange(mainWalletObj['tmn']?.free)}>موجودی شما: TMN {zeroFormat(mainWalletObj['tmn']?.free)}</h4>
          </div>
        } */}
        {(orderChange && userIsLogin)
          && <div className='left'>
            <NavLink to={menu.financial.childs.depositAll.childs.depositCash.childs.default.url} className='cPointer flexCenter ml-5'>
              <IoIosAddCircleOutline color='var(--color-textDone)' />
            </NavLink>
            <h4>موجودی شما : </h4>
            <h4 className='cPointer mr-3' onClick={() => handelChange(mainWalletObj['tmn']?.free)}>{zeroFormat(mainWalletObj['tmn']?.free, 4, false)}</h4>
            <h4 className='mr-2'>TMN</h4>
          </div>}
      </div>
      {orderChange &&
        <>
          <div className='stepOrder'>
            <div>
              <span className='icon-WalletBlue'></span>
              <h4>مرحله سوم <b> آدرس کیف پول</b></h4>
              <div className='dividerVer'></div>
            </div>
            <p>آدرس کیف پول را وارد کنید کنید</p>
          </div>
          <div className='addressOrderCurrency'>
            <CustomInputText label={''} inputState={address} setInputSatet={setAddress} className={"py-12 mb-10"}
              error={addressError} classEnd={"brNone"} placeholder={"آدرس کیف پول را وارد کنید"}
              endEle={<span onClick={pastWallet} className='icon-IconPaste2 cPointer'></span>} />
          </div>
        </>}
      {userIsLogin
        // ? <div className={`btnConfirm w75 ${orderChange ? 'buy' : 'sell'}`} onClick={() => checkLevelindex.checkIndex() && (orderChange ? buy() : sellStep1())}>
        ?
        myLoading
          ? <div className={`loadingCircularOrder w75 ${orderChange ? 'buy' : 'sell'}`}><CircularProgress size={28} style={{ color: "#fff" }} /></div>
          : <div className={`btnConfirm w75 ${orderChange ? 'buy' : 'sell'}`} onClick={() => (orderChange ? buy() : sellStep1())}>
            <p> (با تایید {orderChange ? 'خرید' : 'فروش'} قوانین و مقررات را می پذیرم)</p>
            <Divider variant='middle' orientation='vertical' flexItem />
            <button>تایید {orderChange ? 'خرید' : 'فروش'}</button>
          </div>
        : <div className={`btnConfirm w75 justifyCenter ${orderChange ? 'buy' : 'sell'}`}
          onClick={() => dispatch(DialogShow.show(<NeedLogin submit={() => { dispatch(DialogShow.hide()) }} />))}>
          <p className='textCenter'>ثبت نام - ورود</p>
        </div>}
    </>
  )
}

export default BodyOrderPerfict