import React, { useState } from 'react'
import StepUpgrade from './StepUpgrade'
import { useDispatch, useSelector } from 'react-redux';
import * as DialogShow from '../../../services/redux/dialog';
import VerifyRole from '../dialogs/VerifyRole';
import { config } from '../../../config/config';
import gather from '../../../helpers/gather';
import { useTranslation } from 'react-i18next';
import * as BackdropShow from '../../../services/redux/backdrop';
import useSnack from '../../../hooks/useSnack';
import { CircularProgress } from '@mui/material';

const RoleUpgrade = ({ send }: any) => {
  const dispatch = useDispatch<any>();
  const profile = useSelector((state: any) => state.user.profile);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const getCode = async () => {
    if (profile?.plane?.level?.index > 1) {
      snackUse.showSaccess("سطح فعلی : " + profile?.plane?.level?.name);
      return
    }

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/rules`).get();

    if (result.message === 200) {
      dispatch(DialogShow.show(<VerifyRole send={send} mobile={profile?.mobile} close={() => dispatch(DialogShow.hide())} />))
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setMyLoading(false);
  }

  return (
    <>
      <StepUpgrade step={0} />
      <div className='roleUpgrade'>
        <h3 className='title1'>تعهد و تایید قوانین اکسنوین</h3>
        <div className='text'>
          <p>اینجانب "{profile?.name} {profile?.family}"  به کد ملی "{profile?.nationalCode} "  متعهد می گردم که اطلاعات بانکی و حساب کاربری خود را تحت هر عنوان و شرایطی اعم از (مشارکت در سود ، آگهی استخدام، کسب سود، کاریابی در منزل ، دریافت پول از دیگران جهت خرید ارز از اکسنوین و ....) در اختیار اشخاص دیگر قرار نداده و از رمز ارز ( ارز دیجیتال) برای وب سایت های خلاف قوانین جمهوری اسلامی ایران استفاده نکنم .
            درغیر اینصورت مسئولیت حقوقی و کیفری آن بر عهده اینجانب می باشد.</p>
        </div>
        <h4 className='title2'>وارد کردن کد تایید شما به منظور پذیرفتن قوانین می باشد</h4>
        <div className='inputBox' >
          <input type="number" readOnly value={profile?.mobile} />
          {myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button onClick={getCode}>ارسال کد</button>
          }
        </div>
      </div>
    </>
  )
}

export default RoleUpgrade