export const menu = {
  pishkhan: {
    title: 'پیشخوان | اکسنوین',
    url: '/pishkhan',
  },
  financial: {
    title: 'مالی | اکسنوین',
    url: '/finance',
    childs: {
      depositAll: {
        title: 'واریز ارزی تومانی | اکسنوین',
        url: '/finance/deposit',
        childs: {
          depositCash: {
            title: 'واریز تومانی | اکسنوین',
            url: '/finance/deposit/tmn',
            childs: {
              default: {
                title: 'واریز تومانی | اکسنوین',
                url: '/finance/deposit/tmn',
              },
              high: {
                title: 'واریز شناسه دار | اکسنوین',
                url: '/finance/deposit/byId',
              }
            }
          },
          depositCoin: {
            title: 'واریز ارزی | اکسنوین',
            url: '/finance/deposit/coin',
          },
        }
      },
      withdrawAll: {
        title: 'برداشت | اکسنوین',
        url: '/finance/withdraw',
        childs: {
          withdrawCash: {
            title: 'برداشت تومانی | اکسنوین',
            url: '/finance/withdraw/tmn',
          },
          withdrawCoin: {
            title: 'برداشت ارزی | اکسنوین',
            url: '/finance/withdraw/coin',
          },
        }
      },
      wallet: {
        title: 'کیف پول | اکسنوین',
        url: '/finance/wallet',
      },
      transactionHistory: {
        title: 'تاریخچه واریز و برداشت | اکسنوین',
        url: '/finance/history',
        childs: {
          depositCash: {
            title: 'تاریخچه واریز تومانی | اکسنوین',
            url: '/finance/history/d-tmn',
          },
          depositCoin: {
            title: 'تاریخچه واریز ارزی | اکسنوین',
            url: '/finance/history/d-coin',
          },
          withdrawCash: {
            title: 'تاریخچه برداشت تومانی | اکسنوین',
            url: '/finance/history/w-tmn',
          },
          withdrawCoin: {
            title: 'تاریخچه برداشت ارزی | اکسنوین',
            url: '/finance/history/w-coin',
          },
        }
      }
    }
  },
  ticket: {
    title: 'پشتیبانی | اکسنوین',
    url: '/ticket',
    childs: {
      default: {
        title: 'درخواست پیش فرض پشتیبانی | اکسنوین',
        url: '/ticket/default',
      },
      create: {
        title: 'درخواست پشتیبانی | اکسنوین',
        url: '/ticket/create',
      },
      history: {
        title: 'پشتیبانی | اکسنوین',
        url: '/ticket/history',
      },
      chat: {
        title: 'چت | اکسنوین',
        url: '/ticket/chat',
      },
    }
  },
  order: {
    title: 'سفارشات | اکسنوین',
    url: '/order',
    childs: {
      orederPro: {
        title: 'بازار حرفه ای | اکسنوین',
        url: '/order/market',
      },
      fastOrder: {
        title: ' | اکسنوین',
        url: '/order/fast',
      },
      currency: {
        title: 'ارز دیجیتال | اکسنوین',
        url: '/order/currency',
      },
    }
  },
  userBank: {
    title: 'حساب بانکی | اکسنوین',
    url: '/userBank',
    childs: {
      account: {
        title: 'حساب بانکی | اکسنوین',
        url: '/userBank/account',
      },
    }
  },
  setting: {
    title: 'تنظیمات | اکسنوین',
    url: '/setting',
    childs: {
      changePass: {
        title: 'تغییر رمز | اکسنوین',
        url: '/setting/changePass',
      },
      accountUser: {
        title: 'پروفایل | اکسنوین',
        url: '/setting/accountUser',
      },
      googleAuth: {
        title: 'امنیت | اکسنوین',
        url: '/setting/googleAuth',
      },
      giftFriend: {
        title: 'کد دعوت | اکسنوین',
        url: '/setting/giftFriend',
      }
    }

  },
  auths: {
    title: 'اهراز هویت | اکسنوین',
    url: '/auth',
    childs: {
      uploadDoc: {
        title: 'اپلود مدارک | اکسنوین',
        url: '/auth/uploadDoc',
      }
    }
  },
  upgradeLevel: {
    title: 'ارتقاء سطح | اکسنوین',
    url: '/upgradeLevel',
  },
  learning: {
    title: 'آموزش | اکسنوین',
    url: '/learning',
  },
  notifications: {
    title: 'اطلاعیه ها | اکسنوین',
    url: '/notifications',
  },
  alertCoin: {
    title: 'هشدار قیمت | اکسنوین',
    url: '/alertCoin',
  },
  giftCode: {
    title: 'کارت هدیه | اکسنوین',
    url: '/giftCode',
  },
  invitationCode: {
    title: 'کد دعوت | اکسنوین',
    url: '/invitationCode',
  },
  laws: {
    title: 'قوانین و مقررات | اکسنوین',
    url: '/laws',
  },
  fake: {
    url: "/#"
  }

}
