import { useEffect, useState } from "react";
import Login from "./Login";
import VerifyLogin from "./VerifyLogin";

const LoginPageNew = () => {
  const [changePage, setChangePage] = useState("login");
  const [body, setBody] = useState({});
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState("");

  const onSubmitLogin = (changeView: string, mobile: string, type: string, body: any) => {
    setBody(body);
    setMobile(mobile);
    setType(type);
    setChangePage(changeView);
  }

  useEffect(() => {
    document.title = "ورود - اکسنوین";
  }, []);


  return (
    changePage == "login"
      ? <Login onSubmitLogin={onSubmitLogin} />
      : <VerifyLogin setChangePage={setChangePage} mobile={mobile} body={body} type={type} />
  )
}

export default LoginPageNew;