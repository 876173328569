import React from 'react';
import { zeroFormat } from '../../../helpers/tools';
import useTicker from '../../../hooks/useTicker';

const ItemPriceNow = ({symbol,pair}:{symbol:any,pair:any}) => {
    const ticker=useTicker();

    return (
        <div className='priceNow'>
            <p className='tx'>قیمت فعلی : </p>
            <div className='price'>
                <p className='tx t1'>{pair?.toLocaleUpperCase()}</p>
                <p className='tx'>{symbol+pair=="usdtusdt"?"1":zeroFormat(ticker.price(symbol+pair))}</p>
            </div>
        </div>
    );
}

export default ItemPriceNow;
