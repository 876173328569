import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux';
import * as BackdropShow from '../../services/redux/backdrop';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import useSnack from '../../hooks/useSnack';
import { CircularProgress } from '@mui/material';

const ActiveGA2 = ({ submit }: any) => {
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [qrCode, setQrCode] = useState('')
  const [qrCodeText, setQrCodeText] = useState('')
  const [myLoading, setMyLoading] = useState(false);

  const get = async () => {
    setMyLoading(true);
    const result = await gather(`${config.api}/v1/user/gAuth`).get();
    if (result.code === 200) {
      if (result?.data?.qrCode) {
        let newQr = result?.data?.qrCode?.split('&secret=')
        setQrCode(result?.data?.qrCode ?? "");
        setQrCodeText(newQr[1]?.split('&')[0] ?? "");
      }
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  useEffect(() => {
    get();
  }, [])

  return (
    <div className='activeGA-st2'>
      <div className='warningTitle'>
        <h5 onClick={() => get()}><b>نکات</b> مهم</h5>
        <p>از حذف کردن اپلیکیشن Google Authenticator خودداری کرده و حتما از کد QR زیر تصویری تهیه کنید یا کد مقابل آن را ذخیره کنید تا در صورت در دسترس نبودن اپلیکیشن Google Authenticator امکان بازیابی کد دوعاملی را داشته باشید.</p>
      </div>
      <div className='body'>
        <h5>به صورت دستی‌ کد QR زیر را اسکن کنید و یا کد زیر را در برنامه Google Authenticator وارد کنید تا حساب خود را متصل نمایید.</h5>
        <div className='imgQr'>
          {qrCode
            ? <QRCode
              size={306}
              style={{ height: '139px', width: '139px' }}
              value={qrCode}
              viewBox={`0 0 139 139`}
            />
            :
            // <span className='iconLoading'></span>
            <div className="loadingCircular"><CircularProgress size={35} /></div>
          }
        </div>
        <div className='address'>
          <CopyToClipboard text={qrCodeText} onCopy={() => { qrCodeText && snackUse.showSaccess("آدرس کپی شد") }}>
            <img src="/icons/m-IconCopy3.svg" alt="" />
          </CopyToClipboard>
          <p>{qrCodeText}</p>
        </div>
        <button className='submitStep' onClick={() => submit(3)}>مرحله بعد</button>
      </div>
    </div>
  )
}

export default ActiveGA2