import { useNavigate } from 'react-router-dom';
import { menu } from '../config/menu';
import { useDispatch } from 'react-redux';
import * as DialogShow from '../services/redux/dialog';

const NeedUpgrade = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  return (
    <div className="confirmUpgrade p-35">
      <img crossOrigin='anonymous' className='mb-24' src="/icons/iconSubmitAuth.svg" alt="" />
      <h4>لطفا جهت استفاده از امکانات سایت اکسنوین   </h4>
      <p className='mb-24'>سطح کاربری خود را به سطح یک ارتقا دهید</p>
      <button className="finalCheckBtn" onClick={() => { dispatch(DialogShow.hide2()); navigate(menu.upgradeLevel.url) }}> ارتقا سطح  </button>
    </div>)
}

export default NeedUpgrade