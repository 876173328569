import { useEffect, useState } from 'react';
import { CgChevronLeftR } from 'react-icons/cg';
import * as DialogShow from '../../services/redux/dialog';
import { useDispatch, useSelector } from 'react-redux';
import AddCard from './dialog/AddCard';
import CardDetail from './dialog/CardDetail';
import { detect_bank } from '../../helpers/bankName';
import _ from 'lodash';
import { Pagination } from '@mui/material';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import FetchListLoad from '../../components/FetchListLoad';
import Footer from '../../components/Footer';
import NeedUpgrade from '../../components/NeedUpgrade';

const AccountBank = ({ inPage = false }: any) => {
  const cardBanckList = useSelector((state: any) => state.user.cards);
  const profile = useSelector((state: any) => state.user.profile);
  const dispatch = useDispatch<any>();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (inPage) {
      document.title = menu.userBank.childs.account.title
    }
  }, []);

  return (
    <>
      <div className='bodyMain'>
        {inPage && <Navbar />}
        <div className='newContainer'>
          {inPage && <Header />}
          <div className="accountBank">
            <button className="addCard" onClick={() => {
              (profile.birthDate == null || !profile?.verify?.identity)
                ? dispatch(DialogShow.show2(<NeedUpgrade />))
                : dispatch(DialogShow.show(<AddCard sendUpgrade={""} isSendUpgrade={false} close={() => dispatch(DialogShow.hide())} />))
            }}>
              افزودن کارت بانکی
              <img crossOrigin='anonymous' src="/icons/Iconly-Bold-Plus.svg" alt="" />
            </button>
            <div className='tb_View tb_CardBank'>
              <table>
                <thead>
                  <tr>
                    <th><h5>نام بانک</h5></th>
                    <th><h5>شماره کارت</h5></th>
                    <th><h5>شماره شبا</h5></th>
                    <th><h5>وضعیت</h5></th>
                    <th><h5>جزئیات</h5></th>
                  </tr >
                </thead >
                <tbody className={"relative"}>
                  {!_.isEmpty(Object.values(cardBanckList))
                    && Array.from(Object.values(cardBanckList))?.map((i: any, n: any) =>
                      <tr key={n} className="box">
                        <td>
                          <div className="nameBanck">
                            <img src={`${!_.isEmpty(detect_bank(i?.number).bank_logo) ? detect_bank(i?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
                            <span className="netdes">{i.bank}</span>
                          </div>
                        </td>
                        <td className='fontCard2'>
                          {i?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}
                        </td>
                        <td className='fontCard2'>
                          {i?.iban}
                        </td>
                        <td>
                          <div className='btnControl'>
                            {/* <span className='confirm'>تایید شد</span> */}
                            <span className={`defaultCard active`}>فعال</span>
                          </div>
                        </td>
                        <td onClick={() => dispatch(DialogShow.show(<CardDetail data={i} close={() => dispatch(DialogShow.hide())} />))}>
                          <CgChevronLeftR size={25} />
                        </td>
                      </tr>)}
                </tbody>
              </table >
              <FetchListLoad list={Object.values(cardBanckList)} load={false} />
            </div >
            <div className='paging'>
              <Pagination color="primary" page={page} count={1} onChange={(_e: any, v: any) => setPage(v)} />
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
      {inPage && <Footer />}
    </>
  )
}

export default AccountBank