import _ from "lodash"
import { config } from "../config/config"

const ListTextPopup = ({ className, listData, onClick }: any) => {
  const select = (x: any) => {
    onClick(x)
  }
  return (
    <div className={`listTextPopup ${className} listCoinPopupV2`}>
      {Array.from(listData)?.map((i: any, n: any) =>
        <div key={n} className="item" onClick={() => select(i)}>
          <span className={i?.icon}></span>
          <p>{i?.name}</p>
        </div>
      )}
    </div>
  )
}

export default ListTextPopup