import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'

const MenuWallet = () => {
  return (
    <div className="newMenuProfile MenuWallet">
      <NavLink className='item' to={menu.financial.childs.wallet.url} >
        <span className='icon-IConWallet2'></span>
        <div>
          <h5>کیف پول من</h5>
          <p>کیف پول خود را مدیریت کنید</p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.financial.childs.depositAll.childs.depositCash.childs.default.url} >
        <span className='icon-IConCardTick'></span>
        <div>
          <h5>واریز ارزی و تومانی</h5>
          <p>مدیریت راحت واریز ها </p>
        </div>
      </NavLink>
      <NavLink className='item' to={menu.financial.childs.withdrawAll.childs.withdrawCash.url} >
        <span className='icon-IConCardTick'></span>
        <div>
          <h5>برداشت ارزی و تومانی</h5>
          <p>مدیریت راحت برداشت ها </p>
        </div>
      </NavLink>
    </div>
  )
}

export default MenuWallet