import React, { useEffect, useState } from 'react'
import ItemQuestion from './ItemQuestion'
import { categoryLearn } from '../../../config/variable'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'
import { Skeleton } from '@mui/material'

const FirstPage = ({ loadData, goTo, listLearn, setDatabyId }: any) => {
  const [list, setList]: any = useState(Object.values(listLearn) ?? [])
  const [show, setShow]: any = useState(false);

  const listQuestion = [
    { title: "چه کسانی از تتر استفاده می‌کنند؟", des: "" },
    { title: "آیا برای خرید تتر باید بدانیم این ارز دیجیتال چطور کار می‌کند؟", des: "" },
    { title: "فرق تتر و بیت کویین در چیست؟", des: "برخلاف بیت کوین که یک ارز دیجیتال غیرمتمرکز و دارای نوسانات قیمتی بالا است، تتر یک استیبل کوین (ارز دیجیتال با ثبات) است که به دلار آمریکا وابسته است و نوسانات قیمتی کمتری دارد." },
    { title: "آیا خرید و فروش تتر گزینه خوبی برای سرمایه گذاری است؟", des: "" },
    { title: "اگر بعد از خرید تتر چیزی وارد حسابم نشد، چه کار کنم؟", des: "" },
  ]

  const searchItem = (text: any) => {
    if (text.trim().length == 0) {
      setShow(false)
      return
    }
    if (_.isEmpty(listLearn)) return
    const find = Array.from(Object.values(listLearn))?.filter((e: any) =>
      e?.category?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.title?.toLowerCase()?.includes(text?.toLowerCase()));


    if (find?.length > 0) {
      setList(find);
      setShow(true)
    } else {
      setList([{ "category": "NotCategory" }]);
      setShow(true)
    }
  }

  document?.getElementById("idSearchBox")?.addEventListener("blur", function () {
    setTimeout(() => {
      setShow(false)
    }, 500);
  });

  return (
    <div className='firstPage'>
      <div className='titleSearchBox'>
        {loadData
          ? <Skeleton variant="text" className='width_100d' sx={{ fontSize: '1rem' }} />
          : <h3>چجوری میتوانیم کمکتان کنیم؟</h3>
        }
        <div className='itemSearch'>
          {loadData
            ? <Skeleton variant="text" className='width_100d' sx={{ fontSize: '2rem' }} />
            : <div className='inputBox'>
              <span className='icon-IconSearchNormal'></span>
              <input type="text" id="idSearchBox" placeholder='جستجو کنید' onChange={(e) => searchItem(e.target.value)} />
            </div>}
          {loadData
            ? <Skeleton variant="circular" style={{ width: "50px", marginRight: "10px" }} sx={{ fontSize: '2rem' }} />
            : <button>جستجو کنید</button>
          }

          <div className={`listSearchBox ${show && "show"}`}>
            <div className='list'>
              {Array.from(list)?.map((i: any, n: any) => {
                if (i?.category == "NotCategory") {
                  return <div key={n} className='item'>
                    <h4>نتیجه جستجو</h4>
                    <h5> اطلاعاتی یافت نشد</h5>
                  </div>
                } else {
                  return <NavLink to={menu.learning.url + "/" + i?.category + "/" + i?._id}
                    key={n} className='item'
                    onClick={() => setDatabyId(i)}>
                    <h4>{categoryLearn[i?.category]?.text}</h4>
                    <h5>{i?.title}</h5>
                  </NavLink>
                }
              })}
            </div>
          </div>
        </div>
        {loadData
          ? <Skeleton variant="text" className='width_100d' sx={{ fontSize: '1rem' }} />
          : <div className='textDefault'>
            <p>موضوعات پربازدید:</p>
            <p className='onHover' onClick={() => goTo("registerKyc")}>ثبت نام</p>
            <p className='onHover' onClick={() => goTo("wallet")}>کیف پول</p>
            <p className='onHover' onClick={() => goTo("depositWithdraw")}>واریز و برداشت</p>
          </div>
        }
      </div>
      {loadData
        ? <div className='btnsHelp'>
          {new Array(7).fill("a").map((i, n) =>
            <div key={n} className='item'>
              <Skeleton variant="rounded" className='width_100d' sx={{ fontSize: '10rem' }} />
            </div>
          )}
        </div>
        : <div className='btnsHelp'>
          {Object.values(categoryLearn)?.map((i: any, n: number) =>
            <div key={n} className='item' onClick={() => goTo(i?.category)}>
              <div className='imgBox'>
                <span className={i?.icon}></span>
              </div>
              <h4>{i?.text}</h4>
            </div>
          )}
        </div>
      }
      <div className='divider'></div>
      <div className='defaultQustion'>
        <div className='accordionList'>
          <h3>سوالات متداول</h3>
          {listQuestion?.map((i: any, n: number) =>
            <ItemQuestion key={n} title={i?.title} des={i?.des} />
          )}
        </div>
        <img className='imgQuestion' src="/images/img-LearnUser.svg" alt="" />
      </div>
    </div>
  )
}

export default FirstPage