import React, { useState } from 'react'
import CustomInputText from '../../../components/CustomInputText'
import HeadDialog from '../../../components/HeadDialog';
import { DialogContent } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const UseVoucher = ({ submit, close }: { submit: any, close: any }) => {
  const [firstCode, setFirstCode] = useState('');
  const [firstError, setFirstError] = useState(false);
  const [secondCode, setSecondCode] = useState('');
  const [secondError, setSecondError] = useState(false);

  const past1 = async () => {
    const text = await navigator.clipboard.readText();
    setFirstCode(text);
  }

  const past2 = async () => {
    const text = await navigator.clipboard.readText();
    setSecondCode(text);
  }

  const handle10Num = (e: any) => {
    const num = e.replace(/\D/g, "").slice(0, 10);
    setFirstCode(num);
  }
  const handle16Num = (e: any) => {
    const num = e.replace(/\D/g, "").slice(0, 16);
    setSecondCode(num);
  }

  const send = () => {
    if (firstCode.length == 0) {
      setFirstError(true);
      setTimeout(() => { setFirstError(false) }, 1000);
    }
    if (secondCode.length == 0) {
      setSecondError(true);
      setTimeout(() => { setSecondError(false) }, 1000);
    }
    submit(firstCode + "-" + secondCode);
  }

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw minWidth_450'>
        <div className='dialogContainer useVoucher'>
          <div className='body'>
            <h4>کد پرفکت مانی خود را وارد کنید</h4>
            <div className='inputVoucher'>
              <p>کد 10 رقمی</p>
              <CustomInputText type={"number"} label={''} inputState={firstCode} setInputSatet={handle10Num} className={"py-16"}
                error={firstError} classEnd={"brNone"}
                endEle={<span onClick={past1} className='icon-IconPaste2 cPointer'></span>} />
              <p>کد 16 رقمی</p>
              <CustomInputText type={"number"} label={''} inputState={secondCode} setInputSatet={handle16Num} className={"py-16"}
                error={secondError} classEnd={"brNone"}
                endEle={<span onClick={past2} className='icon-IconPaste2 cPointer'></span>} />
            </div>
            <button className='finalCheckBtn' onClick={send}>تایید</button>
          </div>
        </div>
      </DialogContent>

    </>
  )
}

export default UseVoucher