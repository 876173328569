import { CircularProgress } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const ItemChart = ({ symbol }: { symbol: string }) => {
    const [flag, setFlag] = useState(false);
    const pair = symbol === 'USDT' ? 'USDTUSD' : symbol + 'USDT';
    const [theme] = useLocalStorage('themeEX', "light");

    useEffect(() => {
        setFlag(false)
        setTimeout(() => {
            setFlag(true);
        }, 700);
    }, [symbol,theme]);

    return (
        <>
            {flag
                ? <iframe className='iframeTradingViewWidget'
                    src={`https://www.tradingview-widget.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22BINANCE${symbol === 'USDT' ? 'US' : ""}%3A${pair}%7C1M%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22${theme}%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Atrue%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Atrue%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22no-values%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22headerFontSize%22%3A%22medium%22%2C%22lineWidth%22%3A2%2C%22lineType%22%3A2%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22lineColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%201)%22%2C%22topColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%200)%22%2C%22bottomColor%22%3A%22rgba(144%2C%20191%2C%20249%2C%200.15)%22%2C%22utm_source%22%3A%22exnovin.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22exnovin.net%2Fwidget-wizard%2Fen%2Flight%2Fsymbol-overview%2F%22%7D`}
                // src={coinSymbol?.toLocaleUpperCase() !== "USDT"
                //     ? `https://www.tradingview-widget.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22BINANCE%3A${coinSymbol?.toLocaleUpperCase() + "USDT"}%7C1M%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Atrue%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Atrue%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22no-values%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22headerFontSize%22%3A%22medium%22%2C%22lineWidth%22%3A2%2C%22lineType%22%3A2%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22lineColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%201)%22%2C%22topColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%200)%22%2C%22bottomColor%22%3A%22rgba(144%2C%20191%2C%20249%2C%200.15)%22%2C%22utm_source%22%3A%22exnovin.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22exnovin.net%2Fwidget-wizard%2Fen%2Flight%2Fsymbol-overview%2F%22%7D`
                //     : `https://www.tradingview-widget.com/embed-widget/symbol-overview/?locale=en#%7B%22symbols%22%3A%5B%5B%22BINANCEUS%3AUSDTUSD%7C1M%22%5D%5D%2C%22chartOnly%22%3Afalse%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22showVolume%22%3Afalse%2C%22showMA%22%3Afalse%2C%22hideDateRanges%22%3Afalse%2C%22hideMarketStatus%22%3Atrue%2C%22hideSymbolLogo%22%3Afalse%2C%22scalePosition%22%3A%22right%22%2C%22scaleMode%22%3A%22Normal%22%2C%22fontFamily%22%3A%22-apple-system%2C%20BlinkMacSystemFont%2C%20Trebuchet%20MS%2C%20Roboto%2C%20Ubuntu%2C%20sans-serif%22%2C%22fontSize%22%3A%2210%22%2C%22noTimeScale%22%3Atrue%2C%22valuesTracking%22%3A%221%22%2C%22changeMode%22%3A%22no-values%22%2C%22chartType%22%3A%22area%22%2C%22maLineColor%22%3A%22%232962FF%22%2C%22maLineWidth%22%3A1%2C%22maLength%22%3A9%2C%22headerFontSize%22%3A%22medium%22%2C%22lineWidth%22%3A2%2C%22lineType%22%3A2%2C%22dateRanges%22%3A%5B%221d%7C1%22%2C%221m%7C30%22%2C%223m%7C60%22%2C%2212m%7C1D%22%2C%2260m%7C1W%22%2C%22all%7C1M%22%5D%2C%22lineColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%201)%22%2C%22topColor%22%3A%22rgba(49%2C%20121%2C%20245%2C%200)%22%2C%22bottomColor%22%3A%22rgba(144%2C%20191%2C%20249%2C%200.15)%22%2C%22utm_source%22%3A%22exnovin.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22symbol-overview%22%2C%22page-uri%22%3A%22exnovin.net%2Fwidget-wizard%2Fen%2Flight%2Fsymbol-overview%2F%22%7D`}
                ></iframe>
                : <CircularProgress style={{ color: "#2424da" }} />}

        </>

        // <iframe className='iframeTradingViewWidget'
        //     src={`https://www.tradingview-widget.com/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22${pair}%22%2C%22frameElementId%22%3A%22tradingview_db12a%22%2C%22interval%22%3A%2260%22%2C%22hide_top_toolbar%22%3A%221%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22save_image%22%3A%220%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22light%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22Etc%2FUTC%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22demo.exnovin.net%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22USDTUSD%22%2C%22page-uri%22%3A%22demo.exnovin.net%2Forder%2Fmarket%22%7D`}
        // ></iframe>
    );
}

export default ItemChart;
