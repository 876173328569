import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const useSnack = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showError = (text: string) => {
    enqueueSnackbar(text, { variant: "error" })
  }

  const showSaccess = (text: string) => {
    enqueueSnackbar(text, { variant: "success" })
  }

  return ({ showError, showSaccess })
}

export default useSnack;
