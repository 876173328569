import React from 'react'
import TradingView from '../../../../components/TradingView'
import { useLocalStorage } from 'usehooks-ts';
import TradingViewWidget from '../../../../components/NewTradingView'

const FullScreenMap = ({ symbol, close }: { symbol: any, close: any }) => {
  const [theme] = useLocalStorage('themeEX', "light");
  return (
    <div className='fullScreenMap'>
      <TradingView symbolCoin={symbol} className='h99' theme={theme} />
      {/* <TradingViewWidget symbolCoin={symbol} theme={theme} /> */}

    </div>
  )
}

export default FullScreenMap