import { createSlice } from "@reduxjs/toolkit"

const stateBackdrop: { [key: string]: any } = {
  open: false,
}

export const BackdropShow = createSlice({
  name: "backdropState",
  initialState: stateBackdrop,
  reducers: {
    show: (state) => {
      state.open = true;
    },
    hide: (state) => {
      state.open = false
    }
  }
})


export const { show, hide } = BackdropShow.actions;
export default BackdropShow.reducer;
