import parse from 'html-react-parser';

const LearningPage = ({ data }: any) => {
  return (
    <div className='authLearningPage'>
      <div className='titleBox'>
        {/* <div className='back' onClick={back}><IoArrowForwardOutline size={20} /></div> */}
        <h3>{data?.title}</h3>
      </div>
      {parse(data?.text ?? "")}
    </div>
  )
}

export default LearningPage