import { CircularProgress, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as DialogShow from '../../../services/redux/dialog';
import { IoMdCloseCircle } from 'react-icons/io';
import EditEmail from './EditEmail';
import ConfirmEmailCode from './ConfirmEmailCode';
import * as BackdropShow from '../../../services/redux/backdrop';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import useSnack from '../../../hooks/useSnack';
import { useState } from 'react';


const WarningEmail = ({ oldEmail }: { oldEmail: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const send = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/email`).post({ "email": oldEmail })

    if (result.message === 200) {
      // snackUse.showSaccess("کد با موفقیت ارسال شد");
      dispatch(DialogShow.show(<ConfirmEmailCode value={oldEmail} />))
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <>
      <DialogContent className='dialogWarningTitel'>
        <div className='titleCloseDialog'>
          <div className='textWarning'>
            <span className='iconRed-identity'></span>
            <h4>هشدار</h4>
          </div>
          <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
        </div>
      </DialogContent>
      <DialogContent className='dialogWarning'>
        <p>{"توجه داشته باشید به منظور حفظ امنیت دارایی شما، پس از تغییر"} &nbsp;
          <strong>ایمیل</strong>&nbsp; {"به مدت ۲۴ ساعت برداشت رمز ارز  محدود میباشد"}</p>
        <div className='btns'>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <button className='btOk' onClick={send}>تایید</button>
          }
          <button onClick={() => !myLoading && dispatch(DialogShow.hide())}>بازگشت</button>
        </div>
      </DialogContent>
    </>
  )
}

export default WarningEmail;