import { useDispatch } from 'react-redux';
// import { useLocalStorage } from 'usehooks-ts';
import * as DataSaver from '../services/redux/dataSaver';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import * as dialog from '../services/redux/dialog';

const NeedLogin = ({ submit }: any) => {
  const dispatch = useDispatch<any>();
  const navigati = useNavigate()
  // const [activeLogin, setActiveLogin] = useLocalStorage('activeLogin', "");

  const handleSubit = () => {
    dispatch(DataSaver.changeUserIsReady(false));
    // setActiveLogin("true");
    // dispatch(DialogShow.hide());
    // window.location.reload();
    navigati("/login");
    dispatch(dialog.hide())
  }

  return (
    <div className="confirmUpgrade needLogin">
      <LazyLoadImage crossOrigin='anonymous' src="/icons/m-needLogin.svg" alt="" />
      <div className='divider'></div>
      <h4> جهت استفاده از امکانات سایت اکسنوین</h4>
      <button className="submit" onClick={handleSubit}>
        <p>لطفا ثبت نام / وارد شوید</p>
        <span className='icon-login3'></span>
      </button>
    </div>)
}

export default NeedLogin