import _ from "lodash"
import { config } from "../config/config"
import { detect_bank } from "../helpers/bankName"
import { NavLink } from "react-router-dom"
import { menu } from "../config/menu"

const ListBankPopup = ({ className, listData, onClick }: any) => {
  const select = (x: any) => {
    onClick(x)
  }
  return (
    <div className={`listBankPopup ${className}`}>
      {Array.from(listData)?.map((i: any, n: any) =>
        <div key={n} className="item" onClick={() => select(i)}>
          <div className="name">
            <img src={`${!_.isEmpty(detect_bank(i?.number).bank_logo) ? detect_bank(i?.number).bank_logo : "/icons/iranflag.svg"}`} height="22px" alt="/icons/iranflag.svg" />
            <p>{i?.bank}</p>
          </div>
          <p className="fontCard" >{i?.number?.match(new RegExp('.{1,4}', 'g')).join("-")}</p>
        </div>
      )}
      <NavLink className="item add" to={menu.userBank.childs.account.url}>
        <p>افزودن کارت</p>
        <span className='icon-IconAddCard'></span>
      </NavLink>
    </div>
  )
}

export default ListBankPopup