import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import { config } from '../../../config/config';
import gather from '../../../helpers/gather';
import * as BackdropShow from '../../../services/redux/backdrop';
import Countdown from 'react-countdown';
import { getProfile } from '../../../services/redux/user';
import useSnack from '../../../hooks/useSnack';

const VerifyRole = ({ send, close, mobile }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [showTimer, setShowTimer] = useState({ flag: 1, show: true, myTime: Date.now() + 240000 });

  const sendCode = async (vcode: any) => {
    dispatch(BackdropShow.show())

    const result = await gather(`${config.api}/v1/user/rules/confirm`).post({ "code": vcode });

    if (result.message === 200) {
      close();
      send("nationalCard");
      await dispatch(getProfile());
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    dispatch(BackdropShow.hide())
  }

  const reloadCode = async () => {
    if (showTimer.show) return;

    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/user/rules`).get();;

    if (result.message === 200) {
      setShowTimer({ flag: 1, show: true, myTime: Date.now() + 240000 });
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3 >AAA</h3>;
    } else {
      return <span>0{minutes}:{seconds < 10 && "0"}{seconds}</span>;
    }
  };

  return (
    <div className='verifyRole'>
      <div className='title'>
        <img src="/icons/m-IconTickSquare.svg" alt="" />
        <p>کد تایید به شماره تلفن همراه شما ارسال شد.</p>
      </div>
      <h5 className='rtl'> کد تایید به شماره {mobile} ارسال شده است به منظور تایید قوانین و مقررات، لطفا کد را وارد نمایید.</h5>
      <div className='codeNumberBox'>
        <VerificationInput
          placeholder='-'
          onComplete={(e) => sendCode(e)}
          // onChange={(e) => { Number(e) >= 0 && setCode(e) }}
          // value={code}
          inputProps={{ type: "tel" }}
          classNames={{
            container: "containerVerify",
            character: "characterVerify",
            characterFilled: "character--filled",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
          }}
        />
        {/* <button className='finalCheckBtn' onClick={sendCode}>تایید</button> */}
        <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
          {showTimer.show &&
            <div className="btn">
              <Countdown date={showTimer.myTime} renderer={renderer}
                onComplete={() => setShowTimer({ flag: 0, show: false, myTime: Date.now() + 240000 })} />
            </div>}
          {!showTimer.show &&
            <h5 className="again">ارسال مجدد</h5>}
          <div className="progress"></div>
        </div>
      </div>
    </div>
  )
}

export default VerifyRole