import { useEffect, useState } from "react";
import Signup from "./Signup";
import VerifyRegister from "./VerifyRegister";
import AddDetails from "./AddDetails";
import UserAddCard from "./UserAddCard";
import ConfirmLevelUp from "./ConfirmLevelUp";

const SignupPageNew = () => {
  const [changePage, setChangePage] = useState("signup");
  const [body, setBody] = useState({});
  const [mobile, setMobile] = useState("");

  const onSubmitLogin = (changeView: string, mobile: string, body: any) => {
    setBody(body);
    setMobile(mobile);
    setChangePage(changeView);
  }

  useEffect(() => {
    document.title = "عضویت - اکسنوین";
  }, []);

  return (
    changePage == "signup"
      ? <Signup onSubmitLogin={onSubmitLogin} />
      : changePage == "verify"
        ? <VerifyRegister setChangePage={setChangePage} mobile={mobile} body={body} />
        : changePage == "addDetails"
          ? <AddDetails setChangePage={setChangePage} />
          : changePage == "userAddCard"
            ? <UserAddCard setChangePage={setChangePage} />
            : <ConfirmLevelUp text='تبریک ! شما هم اکنون  می توانید از ویژگی های سطح یک استفاده کنید' />

  )
}

export default SignupPageNew;