import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../../services/redux/user';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import useSnack from '../../../hooks/useSnack';
import { CircularProgress } from '@mui/material';

const TopUpgrade = ({ setChangeview }: any) => {
  const inputFile: any = useRef(null);
  const [fileImage, setFileImage]: any = useState('');
  const [change, setChange]: any = useState(false);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);


  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0].type?.startsWith("image/")) {
      setFileImage(e.target.files[0])
    } else {
      setFileImage(null)
    }
  };

  const sendUpgrade = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/levelUp`).get();

    if (result.code === 200) {
      // dispatch(DialogShow.show(<ConfirmUpgrade text={"درخواست شما جهت بررسی ارسال شد"} close={() => dispatch(DialogShow.hide())} />))
      await dispatch(getProfile());
      setChange(true)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const handleCheckUpgrade = () => {
    if (change) {
      return (<div className='checkUpgradePage'>
        <img src="/icons/m-IconCheckUpgrade.svg" alt="" />
        <p className='colorRed--x'>درخواست شما جهت بررسی ارسال شد</p>
        <button className='finalCheckBtn' onClick={() => setChangeview("showAll")}>بازگشت به صفحه اصلی</button>
      </div>)
    } else {
      return (<div className='topUpgradePage'>
        <p className='force'>متن خود را بنویسید</p>
        <div className='textareaBox'>
          <textarea name="" id="" placeholder='متن'></textarea>
        </div>
        <p>آپلود مدارک</p>
        <div className='fileUploadBox'>
          <input type="file" accept="image/*" className='none' ref={inputFile} onChange={handleFileChange} placeholder='.' />
          <span className='iconDocUpload_2'></span>
          <h4 onClick={() => inputFile.current.click()}>آپلود کنید</h4>
          {fileImage?.name
            ? <p>{fileImage?.name}</p>
            : <p>پسوندهای مجاز: JPG - PNG - JPEG</p>
          }
        </div>
        {
          myLoading
            ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
            : <button className='finalCheckBtn' onClick={sendUpgrade}>ارسال درخواست</button>
        }
      </div>)
    }
  }

  return (
    <>{handleCheckUpgrade()}</>
  )
}

export default TopUpgrade