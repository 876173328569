import React, { useEffect, useState } from 'react'
import { CircularProgress, DialogContent, Divider } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import gather from '../../../helpers/gather';
import AddListWithdraw from './AddListWithdraw';
import * as DialogShow from '../../../services/redux/dialog';
import useSnack from '../../../hooks/useSnack';
import FetchListLoad from '../../../components/FetchListLoad';

const ListWithdraw = ({ setChooseItem, close, }: { setChooseItem: any, close: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [listData, setListData] = useState([]);
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const deleteItem = async (id: any) => {
    setMyLoading(true)

    const result = await gather(`${config.api}/v1/address/${id}`).delete();

    if (result.message === 200) {
      get()
      snackUse.showSaccess('حذف با موفقیت انجام شد');
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setMyLoading(false)
    }

  }

  const get = async () => {
    setMyLoading(true)

    const result = await gather(`${config.api}/v1/address`).get()

    if (result.message === 200) {
      setListData(result?.data ?? []);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false)

  }

  const selelctItemList = (item: any) => {
    setChooseItem({
      network: item?.network,
      coin: item?.coin,
      address: item?.receiver,
      memo: item?.memo ?? ""
    })
    dispatch(DialogShow.hide())
  }

  useEffect(() => {
    get()
  }, [])



  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        {/* <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" /> */}
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw width_100d'>
        <div className='btnAddDialog'>
          <button
            onClick={() => dispatch(DialogShow.show2(<AddListWithdraw reloadApi={get} />))}>
            افزودن + </button>
        </div>
        <div className='tb_View tb_ListWithdraw'>
          <table>
            <thead>
              <tr>
                <th><h5>عنوان</h5></th>
                <th><h5>ارز</h5></th>
                <th><h5>شبکه</h5></th>
                <th><h5>دریافت کننده</h5></th>
                <th><h5>MEMO/ممو</h5></th>
                <th><h5></h5></th>
                <th><h5></h5></th>
              </tr >
            </thead >
            <tbody className={"relative"}>
              {!myLoading && !_.isEmpty(listData)
                && Array.from(listData)?.map((i: any, n: any) =>
                  <tr key={n} className="box">
                    <td>{i?.title ?? "- - -"}</td>
                    <td>{i?.coin?.nameFa} / {i?.coin?.name}</td>
                    <td>{i?.network?.name ?? "- - -"}</td>
                    <td className='ltr'>{i?.receiver?.length > 18 ? i?.receiver?.slice(0, 4) + " . . . " + i?.receiver?.slice(-6, i?.receiver?.length) : i?.receiver}</td>
                    <td>{i?.memo ?? "- - -"}</td>
                    <td >
                      <div className='selectItem' onClick={() => selelctItemList(i)}>انتخاب</div>
                    </td>
                    <td><span className='iconClose cPointer' onClick={() => deleteItem(i?._id)}></span></td>
                  </tr>)}
            </tbody>
          </table >
          <FetchListLoad list={listData} load={myLoading} />
        </div >
      </DialogContent>
    </>
  )
}

export default ListWithdraw