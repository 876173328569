import * as BackdropShow from "../../services/redux/backdrop";
import VerificationInput from "react-verification-input";
import LogoHeader from '../../components/LogoHeader';
import { toEnglishDigits } from "../../helpers/tools";
import { config } from "../../config/config";
import { useTranslation } from "react-i18next";
import gather from "../../helpers/gather";
import { useDispatch } from "react-redux";
import Countdown from "react-countdown";
import { useState } from "react";
import Cookies from "js-cookie";
import _ from "lodash";
import useSnack from "../../hooks/useSnack";
import { useNavigate } from "react-router-dom";

const VerifyRegister = ({ setChangePage, mobile, body }: { setChangePage: any, mobile: string, body: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [showTimer, setShowTimer] = useState({ flag: 1, show: true, myTime: Date.now() + 240000 });
  const [code, setCode] = useState('');
  const snackUse = useSnack();
  const navigati = useNavigate();

  const send = async (code: any) => {
    dispatch(BackdropShow.show());

    let newBody: any = {
      mobile: toEnglishDigits(mobile?.toString()),
      code: toEnglishDigits(code?.toString()),
    }

    const result = await gather(`${config.api}/v1/auth/signup/confirm`).post(newBody)

    if (result.code === 200) {
      Cookies.set('accessTokenPanelEx', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('refreshTokenPanelEx', result.data.refreshToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      await new Promise(res => setTimeout(res, 500));
      
      navigati("/")
      window.location.reload();

      // dispatch(BackdropShow.hide());
      // setChangePage("addDetails")

    } else {
      snackUse.showError(t(result.message) || t("99999"));
      dispatch(BackdropShow.hide());
      setCode("");
    }
  }

  const reloadCode = async () => {
    if (showTimer.show) return;

    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/auth/signup`).post(body);

    if (result.message === 200) {
      snackUse.showSaccess("درخواست ارسال شد");
      setShowTimer({ flag: 1, show: true, myTime: Date.now() + 240000 });
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3 ></h3>;
    } else {
      return <span>0{minutes}:{seconds < 10 && "0"}{seconds}</span>;
    }
  };
  return (
    <div className="loginPage">
      <div className=" login">
        <LogoHeader />
        <div className="loginBody">
          <div className="upTitle">
            <h5>تاییدیه پیامکی</h5>
            <p>کد فعالسازی برای شماره {mobile} پیامک شد</p>
          </div>
          <div className="textCenter" >
            <p className="sign flex" onClick={() => setChangePage("signup")}>
              <span className="icon-editenum " ></span>
              <span >
                ویرایش شماره همراه
              </span>
            </p>
          </div>
          <div className="inputs">
            <div className="text-right">
              <span className="textvr">کد فعالسازی</span>
            </div>
            <div className='codeNumberBox'>
              <VerificationInput
                onComplete={(e) => send(e)}
                onChange={(e) => { Number(e) >= 0 && setCode(e) }}
                value={code}
                placeholder='-'
                inputProps={{ type: "tel" }}
                classNames={{
                  container: "containerVerify",
                  character: "characterVerify",
                  characterFilled: "character--filled",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
            </div>
            <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
              {showTimer.show &&
                <div className="btn">
                  <Countdown date={showTimer.myTime} renderer={renderer}
                    onComplete={() => setShowTimer({ flag: 0, show: false, myTime: Date.now() + 240000 })} />
                </div>}
              {!showTimer.show &&
                <h5 className="again">ارسال مجدد</h5>}
              <div className="progress"></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyRegister