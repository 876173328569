import { menu } from "./menu"

export const listMenu: any = {
  financial: [
    {
      url: menu.financial.childs.wallet.url,
      active: menu.financial.childs.wallet.url,
      text: 'کیف پول',
    },
    {
      url: menu.financial.childs.depositAll.childs.depositCash.childs.default.url,
      active: menu.financial.childs.depositAll.url,
      text: 'واریز ارزی و تومانی',
    },
    {
      url: menu.financial.childs.withdrawAll.childs.withdrawCash.url,
      active: menu.financial.childs.withdrawAll.url,
      text: 'برداشت ارزی و تومانی',
    },
    {
      url: menu.financial.childs.transactionHistory.childs.depositCash.url,
      active: menu.financial.childs.transactionHistory.url,
      text: 'تاریخچه تراکنش ها',
    }
  ],
  buyAndSell: [
    {
      url: menu.order.childs.orederPro.url,
      active: menu.order.childs.orederPro.url,
      text: 'بازار حرفه ای',
    },
    {
      url: menu.order.childs.fastOrder.url,
      active: menu.order.childs.fastOrder.url,
      text: 'خرید و فروش فوری',
    },
    {
      url: menu.order.childs.currency.url,
      active: menu.order.childs.currency.url,
      text: ' ارز دیجیتال',
    },
  ],
  ticket: [
    {
      url: menu.ticket.childs.create.url,
      active: menu.ticket.childs.default.url,
      text: 'ثبت تیکت جدید',
    },
    {
      url: menu.ticket.childs.history.url,
      active: menu.ticket.childs.history.url,
      text: 'تاریخچه',
    },
  ],
  userBank: [
    {
      url: menu.userBank.childs.account.url,
      active: menu.userBank.childs.account.url,
      text: 'کارت های بانکی',
    },
  ],
  setting: [
    {
      url: menu.setting.childs.accountUser.url,
      active: menu.setting.childs.accountUser.url,
      text: 'پروفایل',
    },
    {
      url: menu.setting.childs.changePass.url,
      active: menu.setting.childs.changePass.url,
      text: 'تغییر رمز',
    },
    {
      url: menu.setting.childs.googleAuth.url,
      active: menu.setting.childs.googleAuth.url,
      text: 'امنیت',
    },
  ],
  fakeData: [
    {
      url: '/1',
      text: 'افزایش موجودی',
      des: 'افزایش موجودی ریالی کیف پول'
    },
    {
      url: '/2',
      text: 'برداشت موجودی',
      des: 'برداشت موجودی ریالی کیف پول'
    },
    {
      url: '/3',
      text: ' موجودی',
      des: ' موجودی ریالی کیف پول'
    },
  ]

}

export const levelNameFA: any = {
  "LEVEL1": " آبی",
  "LEVEL2": " برنزی",
  "LEVEL3": " نقره ای",
  "LEVEL4": " طلایی",
  "1": " آبی",
  "2": " برنزی",
  "3": " نقره ای",
  "4": " طلایی",
}

export const statusFA: any = {
  "pending": "منتظر",
  "confirm": "موفق",
  "complete": "موفق",
  "reject": "رد شده",
  "cancel": "لغو شد",
  "waiting": "منتظر",
  "checking": "بررسی",
  "done":"انجام شد",
}

export const statusGiftFA: any = {
  "pending": "مصرف نشده",
  "done":"مصرف شده",
  "return":"منقضی شد"
}
export const verifyUserFA: any = {
  "pending": "تایید نشده",
  "confirm": "تایید شده",
}

export const pairFA: any = {
  "usdt": "تتر",
  "tmn": "تومان",
}

export const verifyFA: any = {
  "card": { text: "کارت بانکی", goTo: menu.userBank.childs.account.url },
  "document": { text: " مدارک", goTo: menu.auths.childs.uploadDoc.url },
  "email": { text: "ایمیل", goTo: menu.setting.childs.accountUser.url },
  "identity": { text: "احراز هویت", goTo: menu.upgradeLevel.url },
  "mobile": { text: "موبایل", goTo: menu.setting.childs.accountUser.url },
  "gAuth": { text: "امنیت", goTo: menu.setting.childs.googleAuth.url },
}

export const marksSlidBar = [
  {
    value: 0,
    label: '100%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 80,
    label: '20%',
  },
  {
    value: 100,
    label: '0',
  },
];

export const socialLink: any = {
  googlePlayEX: "https://play.google.com/store/apps/details?id=com.exnovin&hl=fa",
  bazarEX: "https://cafebazaar.ir/app/com.exnovin",
  pwaEX: "https://pwa.exnovin.net",
  appStorAuthIOS: "https://apps.apple.com/us/app/google-authenticator/id388497605",
  googlePlayAuthAndroid: "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US",
  bazarAuth: "https://cafebazaar.ir/app/com.google.android.apps.authenticator2",
  telegram: "https://t.me/exnovin_com",
  instagram: "https://www.instagram.com/exnovin_io/",
  aparat: "https://www.aparat.com/exnovin",
  whatsapp: "https://www.whatsapp.com",
  twitter: "https://x.com/exnovin_net",
  facebook: "https://www.facebook.com",
}

export const sideFA: any = {
  "deposit": "واریز",
  "cashout": "برداشت",
  "withdraw": "برداشت",
  "buy": "خرید",
  "sell": "فروش",
  "create": "خرید",
  "use": "فروش",
  "send":"ارسال",
  "receive":"دریافت"
}


export const typePaymentFA: any = {
  "gateway": "درگاه بانکی",
  "manual": "سیستمی",
  "cashCode": " واریز شناسه دار",
}

export const typeOrderFA: any = {
  "otc": "فوری",
  "limit": "قیمت معین",
  "market": "بازار حرفه ای",
  "stop": "حد ضرر",
}

export const typeOrder2FA: any = {
  "otc": "سفارش سریع",
  "limit": "قیمت معین",
  "market": "قیمت بازار",
  "stop": "حد ضرر",
}

export const orderStatusFA: any = {
  "complete": "انجام شده",
  "pending": "باز",
  "waiting": "حد ضرر",
  "reject": "رد شد",
  "cancel": "لغو شده",
}
export const ticketStatusFA: any = {
  pending: 'منتظر پاسخ',
  referral: 'ارجاع شد',
  close: 'بسته شد',
  answer: 'پاسخ داده شد',
}

export const bankAcoount: any = {
  "mellat": { shaba: "IR760120020000008992439961", numberAccount: "8992439961", name: "ایوان رایان پیام" },
  "saman": { shaba: "IR280560080181003061335003", numberAccount: "801-810-3061335-3", name: "تجارت الکترونیک ارسباران" },
  "ayandeh": { shaba: "IR260620000000203443585001", numberAccount: "0203443585001", name: "تجارت الکترونیک ارسباران" },
}

export const listDocUser: any = [
  { name: "کارت ملی هوشمند", index: 1, img: "/icons/m-NationalCardSample.svg" },
  { name: "کارت پایان خدمت (معافیت)", index: 2, img: "/images/m-KhedmatCardSample.jpg" },
  { name: "گواهینامه", index: 3, img: "/images/m-GovaheNamehSample.jpg" },
  { name: "شناسنامه", index: 4, img: "/images/m-ShenasNamehSample.jpg" },
]

export const digital = [
  'pm',
  'pmv',
  'wm',
];
export const tmnUsdtFilter = [
  'tmn',
  'usdt',
];

export const orderStatusVoucherFA: any = {
  "complete": "موفق",
  "pending": "در انتظار",
  "reject": "ناموفق",
  "cancel": "لغو شد",
}

export const categoryLearn: any = {
  "registerKyc": { text: "ثبت نام و احراز هویت", icon: "iconLearn_security", category: "registerKyc" },
  "depositWithdraw": { text: "واریز و برداشت رمزارز", icon: "iconLearn_WDcoin", category: "depositWithdraw" },
  "fiat": { text: "واریز و برداشت تومان", icon: "iconLearn_WDfiat", category: "fiat" },
  // "registerKyc": { text: "ثبت نام و احراز هویت", icon: "iconLearn_auth", category: "registerKyc" },
  "products": { text: "محصولات اکسنوین", icon: "iconLearn_product", category: "products" },
  "other": { text: "سایر", icon: "iconLearn_logo", category: "other" },
  "wallet": { text: "کیف پول", icon: "iconLearn_wallet", category: "wallet" },
  "buySell": { text: "خرید و فروش ", icon: "iconLearn_BScoin", category: "buySell" },
}
