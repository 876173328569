import React, { useEffect, useState } from 'react'
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import FirstPage from './item/FirstPage';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Categorys from './item/Categorys';
import { categoryLearn } from '../../config/variable';
import _ from 'lodash';
import LearningPage from './item/LearningPage';
import useSnack from '../../hooks/useSnack';
import Footer from '../../components/Footer';
import gather from '../../helpers/gather';
import { config } from '../../config/config';

const Learning = () => {
  const { t } = useTranslation();
  // const pageLearnApi = useSelector((state: any) => state.publicApis?.pageLearnApi);
  const [listLearn, setListLearn]: any = useState({});
  const [listCategory, setListCategory]: any = useState([]);
  const [txtCategory, setTxtCategory]: any = useState("");
  const [databyId, setDatabyId]: any = useState({});
  const navigate = useNavigate();
  const { learnParam, cIdParam } = useParams();
  const snackUse = useSnack();
  const [loadData, setLoadData] = useState(true);

  const goToCategory = (category: any) => {
    const newList = Object.values(listLearn)?.filter((item: any) => item?.category == category);
    if (newList?.length > 0) {
      setListCategory(newList);
      setTxtCategory(category);
      navigate(menu.learning.url + "/" + category);
    } else {
      setListCategory([]);
      setTxtCategory('');
      snackUse.showError("این بخش در دسترس نیست");
    }
  }

  const get = async () => {
    setLoadData(true);
    const result = await gather(`${config.api}/v1/page`).get();

    if (result.message === 200) {
      if (result?.data) {
        let newObj: any = {}
        result?.data?.forEach((item: any) => {
          newObj[item?.slug] = item
        })
        setListLearn(newObj);

        if (cIdParam) {
          let newSlug = cIdParam ?? ""
          setDatabyId(newObj[newSlug])
        } else {
          if (learnParam) {
            const newList = Object.values(newObj)?.filter((item: any) => item?.category == learnParam);
            if (newList?.length > 0) {
              setListCategory(newList);
              setTxtCategory(learnParam);
            }
          } else {
            setTxtCategory('');
          }
        }
      }
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }
    setLoadData(false);
  }

  useEffect(() => {
    document.title = menu.learning.title;
    get()
  }, []);

  useEffect(() => {
    if (!learnParam) {
      setDatabyId({})
      setTxtCategory('');
    }
  }, [learnParam])


  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='learning'>
            {!_.isEmpty(databyId)
              ? <LearningPage data={databyId} />
              : txtCategory?.trim()?.length > 0
                ? <Categorys data={listCategory} categoryPage={categoryLearn[txtCategory]} />
                : <FirstPage loadData={loadData} goTo={goToCategory} listLearn={listLearn} setDatabyId={setDatabyId} />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Learning