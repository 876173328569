import NavChangeDeposit from './items/NavChangeDeposit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import History from './items/History';
import _ from 'lodash';
import GeneratedID from './items/GeneratedID';
import { config } from '../../config/config';
import gather from '../../helpers/gather';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import * as PublicApis from '../../services/redux/publicApis';
import { useLocalStorage } from 'usehooks-ts';
import { menu } from '../../config/menu';
import useSnack from '../../hooks/useSnack';
import Footer from '../../components/Footer';
import { CircularProgress } from '@mui/material';


const DepositCashMax = () => {
  const codeUp25 = useSelector((state: any) => state.publicApis.codeUp25);
  const dispatch = useDispatch<any>();
  const [listIDBank, setListIDBank]: any = useState(codeUp25 ?? []);
  const [isNew, setIsNew]: any = useState(false);
  const [warning, setWarning] = useLocalStorage('warningDepo', "");
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);


  const handleSend = async () => {
    setMyLoading(true)

    const result = await gather(`${config.api}/v1/cashCode`).post({});
    if (result.message === 200) {
      let newList: any = []

      Array.from(result?.data)?.forEach((item: any) => {
        newList.push([...item?.data]);
      });

      let addSort = newList?.flat()?.map((i: any) => {
        if (i?.bank == "mellat") {
          return { ...i, "sort": 0 }
        } else {
          return { ...i, "sort": 1 }
        }
      })
      setListIDBank(addSort);
      dispatch(PublicApis.codeUp25());
      setWarning("true");
      setIsNew(true);
    } else {
      if (result.message == "10037") {
        snackUse.showError(t("10037A"));
      } else {
        snackUse.showError(t(result.message) || t("99999"));
      }
    }

    setMyLoading(false)
  }

  useEffect(() => {
    if (!_.isNull(codeUp25)) {
      setListIDBank(codeUp25)
      setIsNew(false)
    } else {
      setIsNew(true);
    }
  }, [codeUp25])

  useEffect(() => {
    document.title = menu.financial.childs.depositAll.childs.depositCash.childs.high.title;
  }, []);


  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox'>
                <NavChangeDeposit />
                {(!_.isNull(listIDBank) && !_.isEmpty(listIDBank))
                  ? <GeneratedID data={listIDBank} isNew={isNew} />
                  : <>
                    <div className='stepDepWith'>
                      <p><span>1</span></p>
                      <h5>حساب های بانکی شما</h5>
                    </div>
                    <p className='decAccountBanck1'>با انتخاب دکمه آبی رنگ " درخواست صدور شناسه واریز " سیستم به صورت خودکار ، شناسه واریز / شناسه پرداخت شما را ایجاد کرده و میتوانید بلافاصله از این سرویس استفاده کنید.
                    </p>
                    {
                      myLoading
                        ? <div className="loadingCircularMax"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
                        :
                        <button className='btnRequestID w100' onClick={handleSend}>
                          <img src="/icons/m-IconAddTag.svg" alt="" />
                          <p>درخواست صدور شناسه واریز</p>
                        </button>
                    }
                  </>}
              </div>
              <div className='detailBox'>
                <img className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                <div className='minDepoText denger'>
                  <img src="/icons/m-IconInfoCircleGreenOragne.svg" alt="" />
                  <p>کاربر گرامی، به دستور پلیس فتا محترم ؛ بعد از استفاده از واریز شناسه‌دار، امکان برداشت ارز دیجیتال تا ۳۶ ساعت را ندارید.</p>
                </div>
                <div className='infoText'>
                  <h5><b>نکات</b> مهم</h5>
                  <p>برای استفاده از سرویس واریز شناسه دار سطح کاربری 2 به بالا نیاز است.</p>
                  <p>با انتخاب دکمه آبی رنگ " درخواست صدور شناسه واریز " سیستم به صورت خودکار ، شناسه واریز / شناسه پرداخت شما را ایجاد کرده و میتوانید بلافاصله از این سرویس استفاده کنید.
                  </p>
                  <p>در ادامه شناسه واریز یا همان شناسه پرداخت برای شما ایجاد می شود و لیست حساب ها قابل مشاهده می باشد.</p>
                  <p>در آخر هنگام واریز وجه از روش های پایا، ساتنا و حساب به حساب حتما در بخش شناسه‌ی واریز، شناسه‌ی دریافتی خود را به دقت وارد کنید ، در غیر اینصورت تراکنش شما عودت خواهد شد.</p>
                  <p> توجه داشته باشید که پس از وصول مبلغ به حساب های اکسنوین ، کیف پول تومانی شما شارژ خواهد شد </p>
                </div>
                <div className='infoText'>
                  <h5>کارمزد 0.02% سرویس شناسه‌دار</h5>
                  <p>به طور مثال اگر شما مبلغ 100 میلیون تومان واریز کنید ، از مبلغ تراکنش 20 هزار تومان کسر خواهد شد.</p>
                </div>
              </div>
            </div>
            <History flag={true} reload={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DepositCashMax
