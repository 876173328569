import React from 'react'
import { formatter, zeroFormat } from '../../../../helpers/tools'
import useBalanceFix from '../../../../hooks/useBalanceFix';

const BalanceNumber = () => {
  const balance = useBalanceFix();
  return (
    <p>{formatter(Object.values(balance).reduce((n, e: any) => n + e.value.tmn, 0), 0,0, true)} تومان</p>
  )
}

export default BalanceNumber