import { useSelector } from 'react-redux';

const useTicker = () => {
  const selector = useSelector((state: any) => state.ticker);
  // console.log("first: ", selector?.tickers)

  const get = (key: string, child: any = "close", def: number = 0) => {
    return selector.tickers[key?.toLocaleLowerCase()]?.[child] || def;
  };

  const buy = (key: string, def: number = 0) => {
    return get(key, "buy", def);
  };

  const sell = (key: string, def: number = 0) => {
    return get(key, "sell", def);
  };

  const price = (key: string, def: number = 0) => {
    return get(key, "close", def);
  };

  const volume = (key: string, def: number = 0) => {
    return get(key, "volume", def);
  };

  const change = (key: string, def: number = 0) => {
    return get(key, "change", def);
  };

  const open = (key: string, def: number = 0) => {
    return get(key, "open", def);
  };

  const high = (key: string, def: number = 0) => {
    return get(key, "high", def);
  };

  const low = (key: string, def: number = 0) => {
    return get(key, "low", def);
  };

  return {
    buy,
    sell,
    price,
    volume,
    change,
    open,
    high,
    low,
  };
};

export default useTicker;
