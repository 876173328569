import React from 'react'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'

const NavChangeWithdraw = () => {
  return (
    <div className='changeNavStyle'>
      <NavLink to={menu.financial.childs.withdrawAll.childs.withdrawCash.url} className='item'>برداشت تومان</NavLink>
      <NavLink to={menu.financial.childs.withdrawAll.childs.withdrawCoin.url} className='item'>برداشت ارز دیجیتال</NavLink>
    </div>
  )
}

export default NavChangeWithdraw