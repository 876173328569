import CustomSelectBox2 from '../../components/CustomSelectBox2';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../../config/config';
import { useTranslation } from 'react-i18next';
import * as BackdropShow from '../../services/redux/backdrop';
import gather from '../../helpers/gather';
import _ from 'lodash';
import History from '../withdrawAll/items/History'
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import CopyToClipboard from 'react-copy-to-clipboard';
import NavChangeDeposit from './items/NavChangeDeposit';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import * as DialogShow from '../../services/redux/dialog';
import WarningMemo from './dialogs/WarningMemo';
import { useParams } from 'react-router-dom';
import CustomSelectBoxSearch from '../../components/CustomSelectBoxSearch';
import { menu } from '../../config/menu';
import useSnack from '../../hooks/useSnack';
import Footer from '../../components/Footer';

const DepositCoin = () => {
  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [coinSelect, setCoinSelect]: any = useState({});
  const [networkSelect, setNetworkSelect]: any = useState({});
  const [coinSelectError, setCoinSelectError] = useState(false);
  const [networkSelectError, setNetworkSelectError] = useState(false);
  const [addressCode, setAddressCode] = useState('');
  const [tagCode, setTagCode] = useState('');
  const [warningTagCode, setWarningTagCode] = useState(false);
  const [reload, setReload] = useState(new Date());
  const { coinParam } = useParams();
  const snackUse = useSnack();


  const hanleSelect = async (e: any) => {
    setWarningTagCode(false)
    setCoinSelect(e);
    setNetworkSelect({});
    setAddressCode('');
    setTagCode('')
    if (e?.networks?.length == 1) {
      sendOnlyNetwork(e?.networks[0], e)
    }
  }

  const sendOnlyNetwork = async (network: any, coin: any) => {
    setAddressCode('');
    setTagCode('');
    setNetworkSelect(network);

    if (!network?.deposit?.isActive) {
      snackUse.showError("این ارز در این شبکه قابلیت واریز ندارد");
      return
    }

    if (_.isEmpty(coin)) {
      snackUse.showError("لطفا ارز خود را وارد کنید");
      setCoinSelectError(true)
      setTimeout(() => { setCoinSelectError(false) }, 3000)
      return;
    }

    if (_.isEmpty(network)) {
      snackUse.showError("لطفا شبکه انتقال را انتخاب کنید");
      setNetworkSelectError(true)
      setTimeout(() => { setNetworkSelectError(false) }, 3000)
      return;
    }

    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/deposit/address`).post({
      "coin": coin?._id,
      "network": network?.network?._id
    });

    if (result.message === 200) {
      if (result?.data?.memo) {
        setWarningTagCode(true);
        dispatch(DialogShow.show(<WarningMemo text="واریز" coin={coinSelect?.symbol?.toUpperCase()} close={() => dispatch(DialogShow.hide())} />))
      }
      setAddressCode(result?.data?.address);
      setTagCode(result?.data?.memo);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  const send = async (e: any) => {
    setAddressCode('');
    setTagCode('');
    setNetworkSelect(e);

    if (!e?.deposit?.isActive) {
      snackUse.showError("این ارز در این شبکه قابلیت واریز ندارد");
      return
    }

    if (_.isEmpty(coinSelect)) {
      snackUse.showError("لطفا ارز خود را وارد کنید");
      setCoinSelectError(true)
      setTimeout(() => { setCoinSelectError(false) }, 3000)
      return;
    }

    if (_.isEmpty(e)) {
      snackUse.showError("لطفا شبکه انتقال را انتخاب کنید");
      setNetworkSelectError(true)
      setTimeout(() => { setNetworkSelectError(false) }, 3000)
      return;
    }

    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/deposit/address`).post({
      "coin": coinSelect?._id,
      "network": e?.network?._id
    });

    if (result.message === 200) {
      if (result?.data?.memo) {
        setWarningTagCode(true);
        dispatch(DialogShow.show(<WarningMemo text="واریز" coin={coinSelect?.symbol?.toUpperCase()} close={() => dispatch(DialogShow.hide())} />))
      }
      setAddressCode(result?.data?.address);
      setTagCode(result?.data?.memo);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  useEffect(() => {
    setTimeout(async () => {
      if (coinParam) {
        setCoinSelect(listCoinObj[coinParam?.toLocaleLowerCase()])
        sendOnlyNetwork(listCoinObj[coinParam?.toLocaleLowerCase()]?.networks[0], listCoinObj[coinParam?.toLocaleLowerCase()])
      }
    }, 10);
  }, []);

  useEffect(() => {
    document.title = menu.financial.childs.depositAll.childs.depositCoin.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="sendNew">
            <div className='bodyBoxNew'>
              <div className='chooseBox relative'>
                <NavChangeDeposit />
                <div className='stepDepWith'>
                  <p><span>1</span></p>
                  <h5>انتخاب ارز دیجیتال</h5>
                </div>
                <CustomSelectBoxSearch classNamePopup={"listCoinPopupV2"}
                  error={coinSelectError} listData={listCoinObj ?? []} defalutText={coinSelect?.nameFa ?? ""} defalteImg={coinSelect ?? {}} setInputSatet={hanleSelect} className={" mb-28"} />
                <div className='stepDepWith'>
                  <p><span>2</span></p>
                  <h5>نوع شبکه</h5>
                </div>
                <CustomSelectBox2 flag={false} element={
                  <div className='showCoin'>
                    <h4 >{networkSelect?.network?.name ?? <b>نوع شبکه را انتخاب کنید</b>}</h4>
                  </div>} typeFee={"deposit"}
                  symbol={coinSelect?.symbol?.toUpperCase() ?? ''}
                  error={networkSelectError} listData={coinSelect?.networks ?? []} setInputSatet={send} className={" mb-17"} />
                {warningTagCode
                  && <div className='warningMemoTag'>
                    <img className='imgSubmit' src="/images/warning.png" alt="" />
                    <p>لازم است آدرس و شناسه [تگ / ممو] را در پلت فرم برداشت به درستی پر کنید، در غیر این صورت سپرده <b>{coinSelect?.symbol?.toUpperCase()}</b> از بین خواهد رفت.</p>
                    <button onClick={() => setWarningTagCode(false)}>تایید می کنم</button>
                  </div>}
                <div className={`limitDepositBox ${(_.isEmpty(networkSelect) || _.isEmpty(coinSelect)) && "opacity0"}`}>
                  <p>حداقل میزان واریز ارز  {networkSelect?.deposit?.min} {coinSelect?.symbol} می‌باشد.</p>
                </div>
                {addressCode &&
                  <div className='generateCode'>
                    <div className='qrCode'>
                      <QRCode
                        size={306}
                        style={{ height: '73px', width: '73px' }}
                        value={addressCode}
                        viewBox={`0 0 73 73`}
                      />
                    </div>
                    <div className='address'>
                      <div className='txtInfo'>
                        <img crossOrigin='anonymous' src="/icons/m-IconTickSquare.svg" alt="" />
                        <h4>آدرس{coinSelect?.symbol?.toUpperCase()} برای واریز در شبکه {networkSelect?.network?.symbol?.toUpperCase()}</h4>
                      </div>
                      <div className='txtAddress'>
                        <CopyToClipboard text={addressCode} onCopy={() => { addressCode && snackUse.showSaccess("آدرس کپی شد") }}>
                          <span className='icon-IconCopy2 cPointer'></span>
                        </CopyToClipboard>
                        <h4>{addressCode}</h4>
                      </div>
                    </div>
                  </div>}
                {tagCode &&
                  <div className='generateCode'>
                    <div className='qrCode'>
                      <QRCode
                        size={306}
                        style={{ height: '73px', width: '73px' }}
                        value={tagCode}
                        viewBox={`0 0 73 73`}
                      />
                    </div>
                    <div className='address'>
                      <div className='txtInfo'>
                        <img crossOrigin='anonymous' src="/icons/m-IconTickSquare.svg" alt="" />
                        <h4>آدرس تگ / memo</h4>
                      </div>
                      <div className='txtAddress'>
                        <CopyToClipboard text={tagCode} onCopy={() => { tagCode && snackUse.showSaccess("آدرس کپی شد") }}>
                          <span className='icon-IconCopy2 cPointer'></span>
                        </CopyToClipboard>
                        <h4>{tagCode}</h4>
                      </div>
                    </div>
                  </div>}
              </div>
              <div className='detailBox'>
                <img crossOrigin='anonymous' className='imgPage' src="/icons/m-imgDepoPage.svg" alt="" />
                {/* <div className='infoText'>
                <h5><b>نکات</b> مهم</h5>
                <p>از صحت <b>آدرس صفحه‌ی پرداخت و بودن در یکی از سایت‌های سامانه‌ی شاپرک</b> مطمئن شوید.</p>
                <p>مطمئن شوید <b>مبلغ نمایش‌ داده‌شده</b> در صفحه‌ی پرداخت درست باشد.</p>
                <p>به دستور مقام قضایی، فاصله‌ی بین واریز ریال و برداشت رمزارز ممکن است تا 72 ساعت طول بکشد.</p>
              </div> */}
                <div className='infoText pb-30'>
                  <h5><b>راهنما</b></h5>
                  <p>در این صفحه آدرس واریزی کیف پول (شامل آدرس و یا در صورت لزوم تگ/ ممو و شبکه واریزی دلخواه خود را انتخاب و کپی کنید.</p>
                  <p>آدرس کیف پول و در صورت لزوم آدرس تگ/ممنو را از صفحه کپی کنید و هنگام انتقال ارز دیجیتال در کیف پول ( مبدا ) خود ، وارد کنید.</p>
                  <p>سپس منتظر بمانید ، بعداز تایید (confirm) شبکه ، به صورت خودکار مقدار رمزارز انتقالی به کیف پول شما واریز خواهد شد.</p>
                  <p>در صورت هرگونه مشکل یا مغایرت ، Hash تراکنش یا همان Transaction ID به همراه اسکرین شات انتقال رمزارز ، از طریق تیکت به پشتیبانی ارسال کنید.</p>

                </div>
              </div>
            </div>
            <History flag={true} reload={reload} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DepositCoin;