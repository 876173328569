import { createSlice } from "@reduxjs/toolkit"

const states: { [key: string]: any } = {
  open: false,
  content: null as any,
  transition: "A" as any,

  open2: false,
  content2: null as any,
}

export const DialogShow = createSlice({
  name: "dialogState",
  initialState: states,
  reducers: {
    hide: (state) => {
      state.open = false
    },
    hide2: (state) => {
      state.open2 = false;
    },
    show: (state, action) => {
      state.open = true;
      state.content = action.payload;
    },
    show2: (state, action) => {
      state.open2 = true;
      state.content2 = action.payload;
    },
    notif: (state, action) => {
      state.open = true;
      state.content = action.payload.content;
      state.transition = action.payload.transition;
    },

  }
})

export const { hide, hide2, show, show2, notif,
} = DialogShow.actions;
export default DialogShow.reducer;