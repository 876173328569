import { configureStore } from "@reduxjs/toolkit";
import BackdropShow from "./backdrop";
import DialogShow from "./dialog";
import SnackbarShow from "./snackbar";
import MenuState from "./menu";
import DrawerState from "./drawer";
import PublicApis from "./publicApis";
import DataSaver from "./dataSaver";
import user from "./user";
import ticker from "./ticker";
import orderBook from "./orderBook"

export const store = configureStore({
  reducer: {
    dialog: DialogShow,
    snackbar: SnackbarShow,
    backdrop: BackdropShow,
    menu: MenuState,
    drawer: DrawerState,
    publicApis: PublicApis,
    dataSaver: DataSaver,
    user: user,
    ticker: ticker,
    orderBook: orderBook
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});