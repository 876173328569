import React, { memo, useEffect, useState } from 'react'
import { zeroFormat } from '../../../../helpers/tools'
import { Fade, Tooltip } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import _ from 'lodash'
import { config } from '../../../../config/config'
import { addRemoveFavorite } from '../../../../services/redux/user'
import { useDispatch, useSelector } from 'react-redux'
import { menu } from '../../../../config/menu'
import useTicker from '../../../../hooks/useTicker'

const LastOrder = ({ selectCoin, setSelectCoin, navigate }:
  { selectCoin: any, setSelectCoin: any, navigate: any }) => {
  const dispatch = useDispatch<any>();
  const [symbolPrice, setSymbolPrice] = useState("tmn");
  const [listPriceCoins, setListPriceCoins]: any = useState([]);
  const favoriteList = useSelector((state: any) => state.user?.favorite);
  const listAllPairObj = useSelector((state: any) => state.publicApis?.listAllPairObj);
  const arr2 = new Array(16).fill("a");
  const [typeOrderHeader, setTypeOrderHeader] = useState(false);
  const ticker = useTicker();

  const handlePriceCoin = (item: any) => {
    setSymbolPrice(item)
    if (item == "all") {
      setListPriceCoins(Object.values(listAllPairObj));
      return
    }
    if (item == "favorite") {
      const fList = Object.values(listAllPairObj)?.filter((e: any) => favoriteList.includes(e?._id));
      setListPriceCoins(fList);
      return
    }
    const fList = Object.values(listAllPairObj)?.filter((e: any) => e.myPair == item);
    setListPriceCoins(fList);
  }

  const searchItem = (text: any) => {
    if (_.isEmpty(Object.values(listAllPairObj))) return
    const find = Array.from(Object.values(listAllPairObj)).filter((e: any) =>
      e?.name?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.symbol?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.nameFa?.toLowerCase()?.includes(text?.toLowerCase()));

    if (find) {
      setListPriceCoins(find);
    } else {
      setListPriceCoins(Object.values(listAllPairObj));
    }
  }

  const handelQuery = (item: any) => {
    setSelectCoin(item);
    navigate(menu.order.childs.orederPro.url + "/" + item?.symbol?.toUpperCase() + "-" + item?.myPair?.toUpperCase())
  }

  useEffect(() => {
    setListPriceCoins(Object.values(listAllPairObj)?.filter((e: any) => e.myPair == "tmn"));
  }, [listAllPairObj]);

  return (
    <div className='priceLastOrder '>
      <div className='btnChange'>
        <button onClick={() => setTypeOrderHeader(false)} className={`item ${!typeOrderHeader && "active"}`}>اسپات</button>
        <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} enterDelay={500} leaveDelay={200}
          title="... به زودی">
          <button className={`item opacityHalf`}>تعهدی</button>
        </Tooltip>
      </div>
      <div className='priceBox'>
        <div className='filteSymbol'>
          <div onClick={() => handlePriceCoin("favorite")} className={`item favorite ${symbolPrice == "favorite" && "active"}`}><span className={`${symbolPrice == "favorite" ? "iconStar" : "iconOffStar"}`}></span></div>
          <div onClick={() => handlePriceCoin("all")} className={`item ${symbolPrice == "all" && "active"}`}><p>همه</p></div>
          <div onClick={() => handlePriceCoin("tmn")} className={`item ${symbolPrice == "tmn" && "active"}`}><p>تومان</p></div>
          <div onClick={() => handlePriceCoin("usdt")} className={`item ${symbolPrice == "usdt" && "active"}`}><p>USDT</p></div>
        </div>
        <div className='searcBox'>
          <input onChange={(e) => searchItem(e.target.value)} type="text" placeholder='جستجو بازار ...' />
          <LazyLoadImage src="/icons/m-IconSearc2.svg" alt="" />
        </div>
        <div className='tbPrice'>
          <div className='head'>
            <div className='tr'>
              <div className='th'>
                <p>بازار</p>
                <div className='arrow'>
                  <span className='iconSortUp'></span>
                  <span className='iconSortDown'></span>
                </div>
              </div>
              <div className='th'>
                <p>قیمت</p>
                <div className='arrow'>
                  <span className='iconSortUp'></span>
                  <span className='iconSortDown'></span>
                </div>
              </div>
              <div className='th'>
                <p>تغییرات</p>
                <div className='arrow'>
                  <span className='iconSortUp'></span>
                  <span className='iconSortDown'></span>
                </div>
              </div>
            </div>
          </div>
          <div className='body'>
            {Array.from(listPriceCoins)?.map((i: any, n: any) =>
              <div key={n} className={`tr cPointer ${selectCoin?.pairSymbol==i?.pairSymbol?"active":""}`} onClick={() => handelQuery(i)}>
                <div className='td'>
                  <span onClick={() => dispatch(addRemoveFavorite(i?._id))} className={`cPointer starBox ${favoriteList.includes(i._id) ? "iconStar" : "iconOffStar"}`}></span>
                  <LazyLoadImage  crossOrigin='anonymous' className='symboleCoin' src={`${config.imgUrl}${i?.symbol?.toUpperCase()}.png`} alt="" />
                  <p className={`tx1 ta1 ${i?.pairSymbol?.length > 10 ? "font-10" : i?.pairSymbol?.length > 9 ? "font-12" : ""}`} >{i?.symbol?.toUpperCase()}<b>/{i?.myPair?.toUpperCase()}</b></p>
                </div>
                <div className='td' ><p className='ta1'>{zeroFormat(ticker.price(i?.pairSymbol))}</p></div>
                <div className="td" ><p className={`${ticker.change(i?.pairSymbol) === 0 ? "" : ticker.change(i?.pairSymbol) > 0 ? "colorGreen" : "colorRed"}`}>{ticker.change(i?.pairSymbol)}٪</p></div>
              </div>)}
          </div>
        </div>
        <p className='titleList'>لیست معاملات</p>
        <div className='tbOrderList'>
          <div className='head'>
            <div className='tr'>
              <div className='th'><p>قیمت</p></div>
              <div className='th'><p>مقدار ({selectCoin?.symbol.toUpperCase()})</p></div>
              <div className='th'><p>زمان</p></div>
            </div>
          </div>
          <div className='body'>
            {arr2?.map((i: any, n: any) =>
              <div key={n} className='tr'>
                <div className='td'><p className={`${n % 2 == 0 ? "colorRed" : "colorGreen"}`}>۳۶,۷۸۰.۶۴</p></div>
                <div className='td'><p>۰.۱۳۵۲۴۲</p></div>
                <div className='td'><p>۱۸:۱۵:۵۹</p></div>
              </div>)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(LastOrder)