import { CircularProgress, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import LogoHeader from '../../components/LogoHeader';
import { useState } from 'react';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { useTranslation } from 'react-i18next';
import { toEnglishDigits } from '../../helpers/tools';
import { CiGift } from 'react-icons/ci';
import _ from 'lodash';
import useSnack from '../../hooks/useSnack';

const Signup = ({ onSubmitLogin }: any) => {
  const { t } = useTranslation();
  const snackUse = useSnack();
  const navigati = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [confirmRole, setConfirmRole] = useState(false);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [referral, setReferral] = useState('');
  const [errorMobile, setErrorMobile] = useState(0);
  const [colorPassText, setColorPassText] = useState("colorGray");
  const [errorPass, setErrorPass] = useState(false);
  const [errorRePass, setErrorRePass] = useState(false);
  const [myLoading, setMyLoading] = useState(false);

  const handlePass = (e: any) => {
    if ((/[0-9]/.test(e.target.value) && /[A-Za-z]/.test(e.target.value) && (e.target.value.length >= 8))) {
      setColorPassText("colorGreen");
    } else {
      setColorPassText("colorGray");
    }
    setPassword(e.target.value);
  }

  const handleMobile = (e: any) => {
    const card1 = e.target.value?.replace(/\D/g, "").slice(0, 11)
    setMobile(card1)
    let newMobile = card1.startsWith("0") ? card1 : '0' + card1;
    if (newMobile.length === 11) {
      setErrorMobile(2);
    } else {
      setErrorMobile(0)
    }
  }

  const send = async () => {
    if (!confirmRole) {
      snackUse.showError("تایید قوانین و مقررات سایت ضروری می باشد");
      return
    };
    let newMobile = mobile.startsWith("0") ? mobile : '0' + mobile;
    if (newMobile.length !== 11) {
      setErrorMobile(1);
      setTimeout(() => { setErrorMobile(0) }, 3000);
      return
    }
    setErrorMobile(2);

    if (!(/[0-9]/.test(password) && /[A-Za-z]/.test(password) && (password.length >= 8))) {
      setErrorPass(true);
      setColorPassText("colorRed");
      setTimeout(() => { setErrorPass(false); setColorPassText("colorGray"); }, 3000);
      return;
    }

    if (password !== repassword) {
      setErrorRePass(true);
      setTimeout(() => { setErrorRePass(false) }, 3000);
      return
    }

    setMyLoading(true);
    let body: any = {
      mobile: toEnglishDigits(newMobile?.toString()),
      password: toEnglishDigits(password?.toString()),
      repassword: toEnglishDigits(repassword?.toString()),
    }
    if (referral.trim().length > 3) {
      body["referral"] = toEnglishDigits(referral?.toString())
    }
    const result = await gather(`${config.api}/v1/auth/signup`).post(body);

    if (result.message === 200) {
      onSubmitLogin("verify", toEnglishDigits(newMobile?.toString()), body);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <div className="loginPage">
      <div className=" login">
        <LogoHeader />
        <div className="loginBody">
          <div className="upTitle">
            <h5>ایجاد  حساب کاربری</h5>
            <p>در کمتر از 1 دقیقه فرآیند عضویت را انجام دهید</p>
          </div>
          <div className="textCenter">
            <p className="sign ">
              آیا قبلا ثبت نام کرده اید ؟
              <span className='btn' onClick={() => navigati("/login")} >
                ورود به حساب کاربری
              </span>
            </p>
          </div>
          <div className="inputs">
            <FormControl className={`formControlLogin ${errorMobile === 1 ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>شماره همراه</InputLabel>
              <OutlinedInput fullWidth label="شماره همراه" type='number'
                onChange={handleMobile}
                value={mobile}
                startAdornment={
                  <div className="iconInput cityCode">
                    <p>+98</p>
                    <img crossOrigin='anonymous' src="/icons/iranflag.svg" height="22px" alt="" />
                  </div>}
                endAdornment={<>
                  <img crossOrigin='anonymous' className={`doneit ${errorMobile === 2 ? "" : "none"}`} src="/icons/donelogin.svg" alt="" />
                  <img crossOrigin='anonymous' className={`errorit ${errorMobile === 1 ? "" : "none"}`} src="/icons/loginerror.svg" alt="" /></>} />
            </FormControl>
            <div className={`errorText ${errorMobile === 1 ? "opacity1" : ""}`}>
              <span>شماره همراه خود را بازبینی کنید</span>
            </div>
            <FormControl className={`formControlLogin focus ${errorPass ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>رمز عبور</InputLabel>
              <OutlinedInput fullWidth={true} label="رمز عبور" onChange={handlePass}
                type={showPassword ? 'text' : 'password'}
                startAdornment={<span className='icon-loginlock'></span>}
                endAdornment={<span className={`cPointer icon-IconEye${showPassword?"Close":"Open"}`}  onClick={() => setShowPassword(!showPassword)}  ></span>} />
            </FormControl>
            <div className='errorText mt-5 mx-5 mb-15 showDes opacity1'>
              <span className={colorPassText}>رمز شما باید حداقل ۸ کاراکتر شامل حروف بزرگ و کوچک و اعداد باشد</span>
            </div>
            <FormControl className={`formControlLogin ${errorRePass ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>تکرار رمز عبور</InputLabel>
              <OutlinedInput fullWidth={true} label="رمز عبور" onChange={(e) => setRePassword(e.target.value)}
                type={showRePassword ? 'text' : 'password'}
                startAdornment={<span className='icon-loginlock'></span>}
                endAdornment={<span className={`cPointer icon-IconEye${showRePassword?"Close":"Open"}`}  onClick={() => setShowRePassword(!showRePassword)}  ></span>} />
            </FormControl>
            <div className={`errorText mt-5 mx-5 mb-15 ${errorRePass ? "opacity1" : ""}`}>
              <span>رمز عبور و یا تکرار رمز  برابر نیستند</span>
            </div>
            <FormControl className={`formControlLogin mb-15`} variant="outlined">
              <InputLabel>کد دعوت (اختیاری)</InputLabel>
              <OutlinedInput fullWidth={true} label="کد دعوت (اختیاری)" onChange={(e) => setReferral(e.target.value)}
                type={'text'}
                startAdornment={ <span className='icon-IconGift2'></span>} />
            </FormControl>
            <div className="textForget">
              <div className="formCheck role">
                <input type="checkbox" aria-label="checkbox" checked={confirmRole} onChange={() => setConfirmRole(!confirmRole)}></input>
                <div>
                  <NavLink to={"https://exnovin.net/laws"}>قوانین و مقررات </NavLink>
                  <span> سایت را مطالعه نمودم و تایید میکنم </span>
                </div>
              </div>
            </div>
            {
              myLoading
                ? <div className="loadingCircular mt-15"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                : <button className="finalCheckBtn" onClick={send}> عضویت  </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup