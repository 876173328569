import React from 'react'
import { verifyUserFA } from '../../../config/variable'

const InputTextField = ({ label, edit, confirm, handleEdit, confirmText, placeholder, icon, className, value, onChange, type, readOnly, defaultValue }: any) => {
  return (
    <div className={`inputTextField ${className}`}>
      <span className={`iconImg ${icon}`}></span>
      <div>
        <label>{label} {confirm && <b id={confirmText}>{verifyUserFA[confirmText]}</b>}</label>
        <input defaultValue={defaultValue} placeholder={placeholder} type={type} value={value} onChange={onChange} readOnly={readOnly} />
      </div>
      {edit && <span className='icon-editenum  cPointer mr-0' onClick={handleEdit}></span>}
    </div>
  )
}

export default InputTextField