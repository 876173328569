import React, { useState } from 'react';

const AddImgText = ({ send, img, close }: { send: any, img: any, close: any }) => {
  const [textPm, setTextPm] = useState('');


  return (
    <div className="dialogAddImgText">
      <div className='headTitle'>
        <span className="t1">ارسال تصویر به پشتیبانی</span>
      </div>
      <div className='bodyBox'>
        <div className='imgBox'>
          <img src={URL.createObjectURL(img)} alt="img" />
        </div>
        <div className="inputBox">
          <input
            type="text"
            className=""
            placeholder="پیام خود را وارد کنید "
            value={textPm}
            onChange={(e) => setTextPm(e.target.value)}
          />
        </div>
        <div className="btnsBox">
          <button className="btnOk"
            onClick={() => send(img, textPm?.trim().length > 0 ? textPm : "تصویر ضمیمه شد")} >
            ارسال
          </button>
          <button onClick={close} className="btnCancel">
            لغو
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddImgText;
