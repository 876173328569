import { CircularProgress, DialogContent } from '@mui/material';
import { useState } from 'react';
import { detect_bank } from '../../../helpers/bankName';
import _ from 'lodash';
import CustomInputText from '../../../components/CustomInputText';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useSnack from '../../../hooks/useSnack';
import { getCards } from '../../../services/redux/user';
import { toEnglishDigits } from '../../../helpers/tools';
import * as DialogShow from '../../../services/redux/dialog';

const AddCard = ({ isSendUpgrade, sendUpgrade, close }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [card, setCard] = useState('');
  const [errorCard, setErrorCard] = useState(false);
  const [imgBank, setImgBank] = useState("");
  const space = "-";
  const [myLoading, setMyLoading] = useState(false);

  const handleCard = (e: any) => {
    const card1 = e.replace(/\D/g, "").slice(0, 16)
    setCard(card1.match(new RegExp('.{1,4}', 'g'))?.join(space))
    setImgBank(detect_bank(card1)?.bank_logo ?? "/icons/iranflag.svg")
  }

  const send = async () => {
    const newcard = card.replace(/\D/g, "").slice(0, 16)
    if (newcard.length != 16) {
      setErrorCard(true);
      setTimeout(() => { setErrorCard(false) }, 3000);
      return
    }
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/card`).post({ number: toEnglishDigits(newcard.toString()) });
    if (result.code === 200) {
      await dispatch(getCards());
      dispatch(DialogShow.hide());
      setMyLoading(false);
      if (isSendUpgrade) {
        sendUpgrade();
      }
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setMyLoading(false);
    }
  }

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw width_430'>
        <div className='body'>
          <div className="addCard">
            <h3>افزودن کارت بانکی</h3>
            <CustomInputText error={errorCard} placeholder={"شماره کارت را وارد کنید"} label={" شماره کارت *"} inputState={card} setInputSatet={handleCard} className={"py-12"} classStart={"brNone"}
              classEnd="brNone"
              endEle={!_.isEmpty(imgBank)
                ? <img className="imgSize24" src={imgBank} alt="" />
                : <span className='icon-lockwhiteSample'></span>} />
          </div>
          <div className='btns'>
            {
              myLoading
                ? <div className="loadingCircular h41"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                : <button className="finalCheckBtn" onClick={send} > ثبت شماره کارت  </button>
            }
            <button className="finalCheckBtn btnCancel2" onClick={() => !myLoading && close()} > انصراف  </button>
          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default AddCard;