import { IoIosArrowDown } from "react-icons/io";
import { ClickAwayListener } from '@mui/material';
import { useRef, useState } from 'react';



const CustomSelectBox2Small = ({ element, setInputSatet, listData, className, error, flag }: any) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false)

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={`customInput customInputSelect ${className} ${error && "brRed"}`} >
      <ClickAwayListener onClickAway={handleClose}>
        <div className='textViewBox' onClick={() => setOpen(true)}>
          {element}
          <button ref={anchorRef} className='btnPopUp' ><span className="icon-Arrow2 cPointer"></span></button>
        </div>
      </ClickAwayListener>
    </div >
  )
}

export default CustomSelectBox2Small;