
const ListNetworkPopup = ({ className, listData, onClick, typeFee, symbol }: any) => {

  return (
    <div className={`listNetworkPopup ${className} listCoinPopupV2`}>
      {listData?.map((i: any, n: any) =>
        <div key={n} className="item" onClick={() => onClick(i)}>
          <p>{i?.network?.name}</p>
          {/* <p className="fee">کارمزد شبکه : {i?.[typeFee]?.fee} {symbol}</p> */}
          <p className={`isActive  ${i?.isActive?"":"falseActive"}`}>{i?.isActive?"فعال":"غیرفعال"}</p>
        </div>
      )}
    </div>
  )
}

export default ListNetworkPopup