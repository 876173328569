import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCCAJ1potktK7Tj0u-gOcd9vjXHdSTz69E",
  authDomain: "exnovin-5e83a.firebaseapp.com",
  projectId: "exnovin-5e83a",
  storageBucket: "exnovin-5e83a.firebasestorage.app",
  messagingSenderId: "398562766834",
  appId: "1:398562766834:web:05ff4658d37e912d12456b",
  measurementId: "G-R70591MLM8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export const messaging = getMessaging(app);

// export const generatatToken = async () => {
//   const permission = await Notification.requestPermission();
//   if (permission === "granted") {
//     getToken(messaging, { vapidKey: 'BOFi9Vqhl0gmqvElW6J5d4lm4NRLq5-cokiUi-xPXEXBUuZAlQirQsH_bWGOXbMLOAJiazGNw_p9YK15T3qKoPQ' }).then((currentToken) => {
//       if (currentToken) {
//         console.log(" token : ",currentToken);
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     }).catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
//   } else {
//     alert("You denied for the notification");
//   }
// }