import React from 'react'
import MenuProfile from './menu/MenuProfile'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { logOut } from '../../helpers/authUser';
import * as BackdropShow from '../../services/redux/backdrop';
import * as MenuState from '../../services/redux/menu';
import * as DialogShow from '../../services/redux/dialog';
import { useLocalStorage } from 'usehooks-ts';
import useSnack from '../../hooks/useSnack';
import Exit from './Exit';
import { useNavigate } from 'react-router-dom';

const UserProfileHeader = ({ btTheme = false }: any) => {
  const profile: any = useSelector((state: any) => state.publicApis?.profile);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [theme, setTheme, removeTheme] = useLocalStorage('themeEX', "light");
  const navigate = useNavigate();


  const exit = async () => {
    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/auth/logout`).get();

    if (result.message === 200) {
      navigate("/");
      snackUse.showSaccess("خروج با موفقیت انجام شد");
      removeTheme();
      logOut(true);

    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  const showExit = () => {
    dispatch(MenuState.hide());
    dispatch(DialogShow.show(<Exit confirm={exit} />))
  }
  return (
    <div className="userProfileHeader hover" >
      <img crossOrigin='anonymous' src='/images/m-IconUser1.png' className="imgFluid imgprofile" alt="" />
      <div className="userText">
        <div className='textArrow'>
          <div>
            <h6>{profile?.name ?? ""} &nbsp; {profile?.family ?? ""}</h6>
            <p>  کاربر سطح {profile?.plane?.level?.name ?? "--"} &nbsp;</p>
          </div>
          <span className='icon-Arrow2 cPointer'></span>
        </div>
      </div>
      <div className='menuHover'>
        <MenuProfile exit={showExit} level={profile?.plane?.level?.name ?? "--"} btTheme={btTheme} />
      </div>
    </div>
  )
}

export default UserProfileHeader