import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

const EyeVisiblePass = ({ state, onClick }: any) => {
  return (
    <div className='visiblePass' onClick={onClick}>
      {state ? <AiFillEyeInvisible size={24} className='cPointer' />
        : <AiFillEye size={24} className='cPointer' />}
    </div>
  )
}

export default EyeVisiblePass