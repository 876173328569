import moment from 'jalali-moment';
import React from 'react'
import { useSelector } from 'react-redux';
import { TextWithLineBreaks } from '../helpers/tools';

const NoticeList = ({ close }: any) => {
  const noticeApi: any = useSelector((state: any) => state.publicApis?.noticeApi);

  return (
    <div className='noticeList'>
      <div className='head'>
        <div className='top'>
          <span className='iconClose cPointer' onClick={close}></span>
          <p>بروزرسانی ها</p>
        </div>
      </div>
      <div className='body'>
        <div className='listItem'>
          {Array.from(noticeApi)?.map((i: any, n: any) =>
            <div key={n} className='item'>
              {/* <p className='isRead'>{i?.createdAt ? moment(i?.createdAt).locale("fa").startOf('day').fromNow() : moment(new Date().getTime()).locale("fa").startOf('day').fromNow()}</p> */}
              <p className='isRead'>{i?.createdAt ? moment(i?.createdAt).locale("fa").format(' HH:mm   YYYY-MM-DD ') : "---"}</p>
              <div className='text'>
                <h4>{i?.title}</h4>
                {/* <h5>{i?.text}</h5> */}
                <h5>{i?.text ? TextWithLineBreaks(i?.text) : "- - -"}</h5>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default NoticeList