import React, { useEffect, useState } from 'react'
import { CircularProgress, DialogContent, Divider } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import { toEnglishDigits } from '../../../helpers/tools';
import moment from 'jalali-moment';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import gather from '../../../helpers/gather';
import CustomSelectBoxSearch from '../../../components/CustomSelectBoxSearch';
import CustomSelectBox2 from '../../../components/CustomSelectBox2';
import CustomInputText from '../../../components/CustomInputText';
import * as DialogShow from '../../../services/redux/dialog';
import useSnack from '../../../hooks/useSnack';

const AddListWithdraw = ({ reloadApi }: { reloadApi: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [coinSelect, setCoinSelect]: any = useState({});
  const [networkSelect, setNetworkSelect]: any = useState({});
  const [coinSelectError, setCoinSelectError] = useState(false);
  const [networkSelectError, setNetworkSelectError] = useState(false);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState(false);
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [memoTag, setMemoTag] = useState('');
  const [myLoading, setMyLoading] = useState(false);

  const listCoinObj = useSelector((state: any) => state.publicApis?.listCoinObj)

  const hanleSelect = (e: any) => {
    setCoinSelect(e);
    setNetworkSelect({});
  }

  const pastWallet = async () => {
    const text = await navigator.clipboard.readText();
    setAddress(text);
  }

  const send = async () => {
    if (_.isEmpty(coinSelect)) {
      snackUse.showError("لطفا ارز خود را وارد کنید");
      setCoinSelectError(true)
      setTimeout(() => { setCoinSelectError(false) }, 3000)
      return;
    }

    if (_.isEmpty(networkSelect)) {
      snackUse.showError("لطفا شبکه انتقال را انتخاب کنید");
      setNetworkSelectError(true)
      setTimeout(() => { setNetworkSelectError(false) }, 3000)
      return;
    }

    if (address.length < 20) {
      snackUse.showError("آدرس مقصد را بررسی کنید");
      setAddressError(true)
      setTimeout(() => { setAddressError(false) }, 3000)
      return;
    }

    if (title.length < 1) {
      snackUse.showError(" عنوان را وارد کنید");
      setTitleError(true)
      setTimeout(() => { setTitleError(false) }, 3000)
      return;
    }


    setMyLoading(true)
    const body = {
      coin: coinSelect?._id,
      network: networkSelect?.network?._id,
      title: title,
      receiver: toEnglishDigits(address?.toString()),
      memo: memoTag,
    }

    const result = await gather(`${config.api}/v1/address`).post(body);

    if (result.message === 200) {
      dispatch(DialogShow.hide2());
      reloadApi();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false)
  }


  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        {/* <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" /> */}
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={() => dispatch(DialogShow.hide2())}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw '>
        <div className='addListWithdraw'>
          <div className='stepDepWith'>
            {/* <p><span>1</span></p> */}
            <h5>انتخاب ارز دیجیتال</h5>
          </div>
          <CustomSelectBoxSearch
            error={coinSelectError} listData={listCoinObj ?? []} setInputSatet={hanleSelect} defalutText={coinSelect?.nameFa ?? ""} defalteImg={coinSelect ?? {}} className={" mb-28"} />
          <div className='stepDepWith'>
            {/* <p><span>2</span></p> */}
            <h5>نوع شبکه را انتخاب کنید</h5>
          </div>
          <CustomSelectBox2 flag={false} element={
            <div className='showCoin'>
              <h4 >{networkSelect?.network?.name ?? <b>نوع شبکه را انتخاب کنید</b>}</h4>
            </div>} typeFee={"withdraw"}
            symbol={coinSelect?.symbol?.toUpperCase() ?? ''}
            error={networkSelectError} listData={coinSelect?.networks ?? []} setInputSatet={setNetworkSelect} className={" mb-28"} />
          <div className='stepDepWith'>
            {/* <p><span>3</span></p> */}
            <h5>آدرس مقصد</h5>
          </div>
          <CustomInputText label={''} inputState={address} setInputSatet={setAddress} className={"py-12 mb-28"}
            error={addressError} classEnd={"brNone"} placeholder={"آدرس مقصد را وارد کنید"}
            endEle={<span onClick={pastWallet} className='icon-IconPaste2 cPointer'></span>} />
          <div className='stepDepWith'>
            {/* <p><span>4</span></p> */}
            <h5>عنوان (دلخواه)</h5>
          </div>
          <CustomInputText error={titleError} label={''} inputState={title} setInputSatet={setTitle} className={"py-12 mb-28"}
            classEnd={"brNone"} placeholder={"عنوان (دلخواه)"} />
          <div className='stepDepWith'>
            {/* <p><span>5</span></p> */}
            <h5>Memo/Tag (اختیاری)</h5>
          </div>
          <CustomInputText label={''} inputState={memoTag} setInputSatet={setMemoTag} className={"py-12 mb-28"}
            classEnd={"brNone"} placeholder={"Memo/Tag (اختیاری)"} />
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
              :
              <button className="finalCheckBtn" onClick={send}>+ افزودن </button>

          }
        </div>
      </DialogContent>
    </>
  )
}

export default AddListWithdraw