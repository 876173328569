import { CircularProgress } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { menu } from '../../../config/menu';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import CustomSelectBoxLabel from '../../../components/CustomSelectBoxLabel';
import CustomInputText from '../../../components/CustomInputText';
import useSnack from '../../../hooks/useSnack';

const CreateTicket = () => {
  const navigate = useNavigate()
  const inputFile: any = useRef(null);
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [fileImage, setFileImage]: any = useState('');
  const [title, setTitle] = useState('');
  const [part, setPart]: any = useState({ name: "بخش پشتیبانی" });
  const [errorPart, setErrorPart]: any = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState(false);
  const [myLoading, setMyLoading] = useState(false);

  const handleFileChange = (e: any) => {
    if (e.target.files && e.target.files[0].type?.startsWith("image/")) {
      setFileImage(e.target.files[0])
    } else {
      setFileImage(null)
    }
  };

  const handleTitle = (e: any) => {
    const newTilte = e.replace(/\[A-Z]/g, "").slice(0, 40)
    setTitle(newTilte)
  }

  const sendImg = async () => {
    if (title.trim().length < 3) {
      snackUse.showError("لطفا عنوان تیکت را وارد کنید");
      setErrorTitle(true);
      setTimeout(() => { setErrorTitle(false) }, 3000);
      return;
    }
    if (message.trim().length < 3) {
      snackUse.showError("لطفا متن پیام را وارد کنید");
      setErrorMessage(true);
      setTimeout(() => { setErrorMessage(false) }, 3000);
      return;
    }
    setMyLoading(true)

    const form_data = new FormData();
    form_data.append("type", "ticket");
    form_data.append("doc", fileImage);

    const result = await gather(`${config.api}/v1/file`).upload(form_data);

    if (result.code === 200) {
      // snackUse.showSaccess("ارسال مدارک با موفقیت انجام شد");
      send(result?.data?.link)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false)
  }

  const send = async (link: any) => {
    if (title.trim().length < 3) {
      snackUse.showError("لطفا عنوان تیکت را وارد کنید");
      setErrorTitle(true);
      setTimeout(() => { setErrorTitle(false) }, 3000);
      return;
    }
    if (message.trim().length < 3) {
      snackUse.showError("لطفا متن پیام را وارد کنید");
      setErrorMessage(true);
      setTimeout(() => { setErrorMessage(false) }, 3000);
      return;
    }

    setMyLoading(true)

    const body: any = {
      title: title,
      text: message,
      subject: "support",
    }
    if (link) body.file = link;

    const result = await gather(`${config.api}/v1/ticket`).post(body);

    if (result.message === 200) {
      snackUse.showSaccess("در خواست شما با موفقیت ثبت شد");
      setTitle("");
      setMessage("")
      navigate(menu.ticket.childs.history.url)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false)
  }

  return (
    <div className='createTicket'>
      <div className='headTitle'>
        <img crossOrigin='anonymous' src="/icons/CommentsWhite.svg" alt="" />
        <p>ثبت تیکت جدید</p>
      </div>
      <div className='body'>
        <div className='typeTicket'>
          <CustomInputText label={" عنوان تیکت *"} inputState={title} setInputSatet={handleTitle} className={"py-12"}
            error={errorTitle} classStart={"brNone"}
            startEle={<span className='icon-userprofile '></span>} />
          <CustomSelectBoxLabel flag={false} element={
            <div className='showBanck'>
              <h4 className='p-0 letSpacing0' >{_.isEmpty(part) ? "انتخاب کنید" : part?.name}</h4>
            </div>}
            error={errorPart} listData={[{ name: "بخش پشتیبانی" }]} label={"بخش *"} setInputSatet={setPart} className={"w50 styleBankSelect "} />
        </div>
        <div className='textareaStyle'>
          <div className="textRight">
            <span className="oninput">پیام *</span>
          </div>
          <div className={`inputGroup textarea ${errorMessage ? "brRed" : ""}`}>
            <textarea value={message} onChange={(e) => setMessage(e.target.value)}
              placeholder="متن پیام خود را اینجا وارد کنید "
              rows={3}></textarea>
          </div>
        </div>
        <div className='bottom'>
          <div className='right'>
            <div className='selectFile' onClick={() => inputFile.current.click()}>
              <input type="file" accept="image/*" ref={inputFile} onChange={handleFileChange} placeholder='.' />
              <div>
                <span className='icon-Attachment'></span>
                <p>انتخاب فایل</p>
              </div>
            </div>
            {fileImage && <div className='nameFile'>
              <h5>{fileImage?.name}</h5>
              <span className='iconClose' onClick={() => setFileImage('')}></span>
            </div>}
          </div>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
              :
              <button className="finalCheckBtn" onClick={() => fileImage ? sendImg() : send('')} >ارسال پیام</button>
          }
        </div>
      </div>

    </div>
  )
}

export default CreateTicket