import _ from "lodash"
import ItemCoinGift from "./ItemCoinGift"

const ListCoinGift = ({ listData, onClick,handleAdd, className }: any) => {

  return (
    <div className={`listCoinPopup listCoinGift ${className}`} onClick={onClick}>
      {listData?.map((i: any, n: any) =>
        <ItemCoinGift key={n} data={i} handleAdd={handleAdd} />
      )}
    </div>
  )
}

export default ListCoinGift