import React, { useState } from 'react';
import LearningPage from './LearningPage';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { menu } from '../../../config/menu';

const Categorys = ({ data, categoryPage }: any) => {
  const [select, setSelect] = useState({});

  return (
    <>
      {_.isEmpty(select)
        ? <div className='authLearningPage'>
          <h3>{categoryPage?.text}</h3>
          <div className='textQuesBox'>
            {Array.from(data)?.map((i: any, n: any) =>
              <NavLink to={menu.learning.url + "/" + i?.category + "/" + i?.slug}
                key={n} className='item' onClick={() => {
                  setSelect(i);
                }}>
                <span className={`iconMessageQuestion${n % 2 == 0 ? "Yellow" : "Blue"}`}></span>
                <h4>{i?.title}</h4>
              </NavLink>
            )}
          </div>
          <div className='divider'></div>
        </div>
        : <LearningPage data={select} />}
    </>
  )
}

export default Categorys;