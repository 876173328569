import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import HistoryCurrency from './items/digital/HistoryCurrency';
import _ from 'lodash';
import CoinsBox from './items/CoinsBox';
import BodyOrderVoucher from './items/digital/BodyOrderVoucher';
import BodyOrderPerfict from './items/digital/BodyOrderPerfict';
import { menu } from '../../config/menu';
import Footer from '../../components/Footer';

const OrderCurrency = () => {
  const listVoucherObj: any = useSelector((state: any) => state.publicApis?.listVoucherObj);
  const [selectCoin, setSelectCoin]: any = useState(Object.values(listVoucherObj)[0] ?? {});
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const [orderChange, setOrderChange] = useState(true);
  const [firstList, setFirstList]: any = useState({});
  const [reload, setReload] = useState('');

  const handleSelectCoin = (e: any) => {
    setSelectCoin(e)
    const newList = _.cloneDeep(firstList)
    delete newList[e.symbol];
    if (Object.keys(newList).length > 5) {
      delete newList[Object.keys(newList)[0]];
    }
    newList[e?.symbol] = e;
    setFirstList(newList);
  }

  const createList = () => {
    let newList: any = {}
    Array.from(Object.keys(listVoucherObj)).forEach((item: any, n: number) => {
      if (n < 6) {
        newList[item] = listVoucherObj[item]
      }
    });

    setFirstList(newList);
    // setSelectCoin(newList[Object.keys(newList)[Object.keys(newList).length - 1]])
    setSelectCoin(newList[Object.keys(newList)[0]])
  }

  useEffect(() => {
    createList();
  }, [listVoucherObj])

  useEffect(() => {
    document.title = menu.order.childs.currency.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='order'>
            <div className='stepOrder'>
              <div>
                <span className='icon-Stack'></span>
                <h4>مرحله اول <b>نوع ارز</b></h4>
                <div className='dividerVer'></div>
              </div>
              <p>ارز موردنظر را انتخاب کنید</p>
            </div>
            <CoinsBox listCoin={listVoucherObj} firstList={firstList} handleSelectCoin={handleSelectCoin}
              setOrderChange={setOrderChange} selectCoin={selectCoin} setSelectCoin={setSelectCoin} />
            {/* {selectCoin?.symbol &&
            <div className='choosingCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${selectCoin?.symbol?.toUpperCase()}.png`} alt="" />
              <h4>شما در حال معامله <b>{selectCoin?.nameFa ?? "---"}</b> می باشید</h4>
            </div>} */}
            {selectCoin.symbol === "pmv"
              ? <BodyOrderVoucher setReload={setReload} orderChange={orderChange} setOrderChange={setOrderChange} coin={selectCoin} />
              : <BodyOrderPerfict setReload={setReload} orderChange={orderChange} setOrderChange={setOrderChange} coin={selectCoin} />
            }
          </div>

          {userIsLogin && <HistoryCurrency fID={selectCoin?._id} reload={reload} />}
        </div>
      </div>
      <Footer />
    </>

  )
}

export default OrderCurrency