import { DialogContent, Divider } from '@mui/material'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { orderStatusFA, orderStatusVoucherFA, sideFA, statusFA } from '../../../config/variable'
import moment from 'jalali-moment'
import { formatter, zeroFormat } from '../../../helpers/tools'
import { IoMdCloseCircle } from 'react-icons/io'
import { config } from '../../../config/config'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useSnack from '../../../hooks/useSnack'

const DetailOrderVoucher = ({ data, close }: any) => {
  const snackUse = useSnack();

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        {/* <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-${theme == "dark" ? "logo-4" : "logo-2"}.svg`} alt="" /> */}
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw'>
        <div className='body'>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>ثبت درخواست</h4>
              <p>{moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step mb-24'>
            <div className='circlePoint'>
              <span></span>
            </div>
            <div className='textDate'>
              <h4>پردازش سیستم</h4>
              <p>{moment(data?.createdAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
            <div className='linerStep'>
              <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconLine2Blue.svg" alt="" />
              <LazyLoadImage crossOrigin='anonymous' className='line' src="/icons/m-IconLineBlue.svg" alt="" />
            </div>
          </div>
          <div className='step'>
            <div className={`circlePoint ${data?.status}`}>
              <span></span>
            </div>
            <div className={`textDate ${data?.status}`}>
              <h4 id={`${data?.status}`}>{orderStatusFA[data?.status]}</h4>
              <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
            </div>
          </div>

          {/* <div className='textDec'>لطفا توجه داشته باشید پس از تکمیل شدن فرایند یک ایمیل دریافت خواهید کرد.
            <NavLink to={"#"}>
              ایمیل دریافت نکرده ام
            </NavLink> .</div> */}
          {/* <NavLink to={"#"} className='reportWarning'>
            <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconDangerRed.svg" alt="" />
            <p>گزارش کلاهبرداری</p>
          </NavLink> */}
          <Divider className='divder' />
          <div className='dec'>
            <p>شناسه درخواست</p>
            <div className='shaba'>
              <CopyToClipboard text={data?._id} onCopy={() => { data?._id && snackUse.showSaccess("آدرس کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?._id}</p>
            </div>
          </div>
          <div className='dec'>
            <p>وضعیت</p>
            <div className='status confirm'>
              {data?.status == "reject"
                ? <IoMdCloseCircle className='close' size={22} />
                : data?.status == "complete" ? <span className='icon-TickSquare'></span> : <span className='icon-IconInfoCircle'></span>
              }
              <p id={`${data?.status}`}>{orderStatusVoucherFA[data?.status]}</p>
            </div>
          </div>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p>سمت</p>
            <p className={`color-${data?.side}`}>{sideFA[data?.side]}</p>
          </div>
          <div className='dec'>
            <p>نوع ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
              <p>{data?.coin?.nameFa ?? "---"}</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار ارز</p>
            <p className='ltr'>{zeroFormat(data?.amount)} {data?.coin?.symbol?.toUpperCase() ?? "---"}</p>
          </div>
          <div className='dec'>
            <p>کارمزد</p>
            <p className='ltr'>{zeroFormat(data?.fee?.amount)} {data?.pair}</p>
          </div>
          <div className='dec'>
            <p>قیمت</p>
            <p className='ltr'>{zeroFormat(data?.price) ?? 0} {data?.pair}</p>
          </div>
          <div className='dec'>
            <p>مجموع {sideFA[data?.side]}</p>
            <p className='ltr'>{zeroFormat(data?.total) ?? 0} {data?.pair}</p>
          </div>
          <div className='dec'>
            <p>مقدار نهایی</p>
            <p className='ltr'>{zeroFormat(data?.final) ?? 0} {data?.pair}</p>
          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default DetailOrderVoucher