import { CircularProgress, FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react'
import EyeVisiblePass from '../../components/EyeVisiblePass';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import CustomInputText from '../../components/CustomInputText';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as BackdropShow from '../../services/redux/backdrop';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { menu } from '../../config/menu';
import { useSnackbar } from 'notistack';
import useSnack from '../../hooks/useSnack';
import Footer from '../../components/Footer';

const ChangePass = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [oldPassword, setOldPassword]: any = useState({ show: false, value: '', error: false });
  const [password, setPassword]: any = useState({ show: false, value: '', error: false });
  const [rePassword, setRePassword]: any = useState({ show: false, value: '', error: false });
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);


  const send = async () => {

    if (oldPassword.value.length < 1) {
      setOldPassword((s: any) => ({ ...s, error: true }));
      setTimeout(() => { setOldPassword((s: any) => ({ ...s, error: false })) }, 3000);
      return
    }

    if (password.value.length < 1) {
      setPassword((s: any) => ({ ...s, error: true }));
      setTimeout(() => { setPassword((s: any) => ({ ...s, error: false })) }, 3000);
      return
    }

    if (rePassword.value.length < 1) {
      setRePassword((s: any) => ({ ...s, error: true }));
      setTimeout(() => { setRePassword((s: any) => ({ ...s, error: false })) }, 3000);
      return
    }

    if (password.value !== rePassword.value) {
      snackUse.showError("رمز عبور و تکرار آن مشابه نمی باشد");
      setRePassword((s: any) => ({ ...s, error: true }));
      setPassword((s: any) => ({ ...s, error: true }));
      setTimeout(() => { setPassword((s: any) => ({ ...s, error: false })) }, 3000);
      setTimeout(() => { setRePassword((s: any) => ({ ...s, error: false })) }, 3000);
      return
    }

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/password`).put({
      "oldPassword": oldPassword.value,
      "password": password.value,
      "repassword": rePassword.value
    });

    if (result.code === 200) {
      snackUse.showSaccess("تغییر رمز عبور با موفقیت انجام شد");
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  useEffect(() => {
    document.title = menu.setting.childs.changePass.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='changePass'>
            <h3>قوانین تغییر رمز عبور</h3>
            <br />
            <div className='description'>
              <div className='item'>
                <p className='rtl'>رمز عبور باید بیشتر از ۶ حرف باشد.</p>
                <p className='rtl'>رمز عبور باید از حروف انگلیسی و عدد ترکیب باشد.</p>
              </div>
              <div className='item'>
                <p className='rtl'>از علامت های سیمبل استفاده کنید.</p>
                <p className='rtl'>۲ در سال بار می توانید رمز خود را عوض کنید. </p>
              </div>
              <div className='item'>
                <p className='rtl'>مسئولیت حفظ و امنیت از نام کاربری و رمز عبور، بر عهده شماست .</p>
              </div>
            </div>
            <br />
            <div className='body'>
              <div className='right'>
                <CustomInputText error={oldPassword.error} label={" رمز عبور فعلی *"} inputState={oldPassword.value} setInputSatet={(e: any) => setOldPassword((s: any) => ({ ...s, value: e }))} className={"py-12"} classStart={"brNone"}
                  type={oldPassword.show ? 'text' : 'password'}
                  startEle={<EyeVisiblePass state={oldPassword.show} onClick={() => setOldPassword((s: any) => ({ ...s, show: !oldPassword.show }))} />}
                  endEle={<span className='icon-loginlock'></span>} />
                <div className={`errorText ${oldPassword.error ? "opacity1" : ""}`}>
                  <span>ورودی خود را بازبینی کنید</span>
                </div>
                <CustomInputText error={password.error} label={" رمز عبور جدید *"} inputState={password.value} setInputSatet={(e: any) => setPassword((s: any) => ({ ...s, value: e }))} className={"py-12"} classStart={"brNone"}
                  type={password.show ? 'text' : 'password'}
                  startEle={<EyeVisiblePass state={password.show} onClick={() => setPassword((s: any) => ({ ...s, show: !password.show }))} />}
                  endEle={<span className='icon-loginlock'></span>} />
                <div className={`errorText ${password.error ? "opacity1" : ""}`}>
                  <span>ورودی خود را بازبینی کنید</span>
                </div>
                <CustomInputText error={rePassword.error} label={" تکرار رمز عبور جدید *"} inputState={rePassword.value} setInputSatet={(e: any) => setRePassword((s: any) => ({ ...s, value: e }))} className={"py-12"} classStart={"brNone"}
                  type={rePassword.show ? 'text' : 'password'}
                  startEle={<EyeVisiblePass state={rePassword.show} onClick={() => setRePassword((s: any) => ({ ...s, show: !rePassword.show }))} />}
                  endEle={<span className='icon-loginlock'></span>} />
                <div className={`errorText ${rePassword.error ? "opacity1" : ""}`}>
                  <span>ورودی خود را بازبینی کنید</span>
                </div>
                {
                  myLoading
                    ? <div className="loadingCircular"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
                    :
                    <button className="finalCheckBtn" onClick={send}> تغییر روز عبور  </button>
                }
              </div>
              <div className='left'>
                <img crossOrigin='anonymous' src="/icons/changepass.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ChangePass;