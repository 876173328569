import React, { memo } from 'react'
import Countdown from "react-countdown";

const BtnTimer = ({ showTimer, setShowTimer, reloadCode }: { showTimer: any, setShowTimer: any, reloadCode: any }) => {

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3 >AAA</h3>;
    } else {
      return <span>0{minutes}:{seconds < 10 && "0"}{seconds}</span>;
    }
  };
  return (
    <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
      {showTimer.show &&
        <div className="btn">
          <Countdown date={Date.now() + 240000} renderer={renderer}
            onComplete={() => setShowTimer({ flag: 0, show: false })} />
        </div>}
      {!showTimer.show &&
        <h5 className="again">ارسال مجدد</h5>}
      <div className="progress"></div>
    </div>
  )
}

export default memo(BtnTimer)