import React from 'react';
import useTicker from '../../../hooks/useTicker';
import { formatter, zeroFormat } from '../../../helpers/tools';

const ItemTotalTmn = ({ data }: { data: any }) => {
    const ticker = useTicker();

    const handleTotal = () => {
        let sum = 0
        data?.forEach((i: any) => {
            if(i?.fiat){
                sum = sum + i?.amount
            }else{
                sum = sum + Number(i?.amount * ticker.price(i?.coin?.symbol + "tmn"))
            }
        });

        return formatter(sum, 0, 0,true)
    }

    return (
        <p className='p1 '>{handleTotal()} تومان</p>
    );
}

export default ItemTotalTmn;
