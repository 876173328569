import { createSlice } from "@reduxjs/toolkit"

export interface CounterState {
  open: boolean,
  content: any,
  horizontal: string,
  anchorEl: null | HTMLElement
}

const initialState: CounterState = {
  open: false,
  content: null,
  horizontal: "right",
  anchorEl: null
}

export const MenuState: any = createSlice({
  name: "menuState",
  initialState,
  reducers: {
    show: (state, action) => {
      state.open = true
      state.horizontal = action.payload.horizontal
      state.content = action.payload.content
      if (action.payload.anchorEl)
        state.anchorEl = action.payload.anchorEl
    },
    hide: (state) => {
      state.open = false
    }

  }
})

export const { show, hide } = MenuState.actions
export default MenuState.reducer
