import React from 'react'
import { zeroFormat } from '../../../../helpers/tools';
import useTicker from '../../../../hooks/useTicker';
let oldData: any = {};

const EleItemOther = ({ symbol, pair, type }: { symbol: any, pair: any, type: any }) => {
  const ticker = useTicker();

  const handleColor = (symbol2: any) => {
    let color = ""
    let icon = ""
    if (ticker.price(symbol2) > oldData[symbol2]?.p) {
      color = 'Green';
      icon = "icon-Arrow2Up bgGreen"
    } else if (ticker.price(symbol2) < oldData[symbol2]?.p) {
      color = 'Red';
      icon = "icon-Arrow2 bgRed"
    } else {
      color = oldData[symbol2]?.c
      icon = oldData[symbol2]?.i
    }
    oldData[symbol2] = { p: ticker.price(symbol2), c: color, i: icon }
    return { color, icon };
  }

  const getPrice = () => {
    if (pair === "usdt") {
      if (symbol == "usdt") {
        return "$ 1"
      } else {
        return " $ " + zeroFormat(ticker[type == "buy" ? "buy" : type == "sell" ? "sell" : "price"](symbol + pair))
      }
    } else {
      return (zeroFormat(ticker[type == "buy" ? "buy" : type == "sell" ? "sell" : "price"](symbol + pair)))
    }
  }

  return (
    <>
      <p className={`color${handleColor(symbol + pair).color}`}>{getPrice()}</p>
      <span className={handleColor(symbol + pair).icon}></span>
    </>
  )
}

export default EleItemOther