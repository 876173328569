import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Header from '../../components/header/Header';
import Navbar from '../../components/navbar/Navbar';
import { menu } from '../../config/menu';
import * as PublicApis from '../../services/redux/publicApis';
import ItemNotif from './item/ItemNotif';
import Footer from '../../components/Footer';

const NotifLsit = () => {
  const alarmApi: any = useSelector((state: any) => state.publicApis?.alarmApi);
  const dispatch = useDispatch<any>();
  const [list, setList]: any = useState([]);
  const [active, setActive] = useState(true)

  const handleFilter = (e: any) => {
    setActive(e);
    if (e == false) {
      const newlist = Array.from(alarmApi)?.filter((i: any) => i?.isRead === false);
      if (newlist) {
        setList(newlist)
      } else {
        setList([])
      }
    } else {
      setList(alarmApi)
    }
  }

  useEffect(() => {
    setList(alarmApi)
  }, [alarmApi])


  useEffect(() => {
    document.title = menu.notifications.title
    dispatch(PublicApis.alarmRead());
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="notifLsit">
            <div className='btnsControl'>
              <button onClick={() => handleFilter(true)} className={`${active ? "active" : ""}`}>همه اطلاعیه ها</button>
              <button onClick={() => handleFilter(false)} className={`${active ? "" : "active"}`}>اطلاعیه خوانده نشده</button>
            </div>
            {list?.length == 0
              ? <h2>لیست خالی می باشد</h2>
              : Array.from(list)?.map((i: any, n: number) => <ItemNotif key={n} data={i} />)}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NotifLsit