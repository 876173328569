import { useNavigate } from 'react-router-dom';
import LogoHeader from '../../components/LogoHeader';
import MoblieStep from './MoblieStep';
import StepByStep from './StepByStep';

const ConfirmLevelUp = ({ text }: { text: string }) => {
  const navigati = useNavigate();

  return (
    <div className="authPage">
      <div className='container'>
        <div className=" auth">
          <LogoHeader />
          <div className='authBody'>
            <div className="top">
              <div className="titleBack opacity0">
                <div></div>
                <h5></h5>
              </div>
              <MoblieStep classNames={"none"} step={"3"} />
            </div>
            <StepByStep current={3} />
            <div className="imgComfirm width_100d">
              <img crossOrigin='anonymous' src="/icons/iconSubmitAuth.svg" alt="" />
              <h4>تکمیل شد</h4>
              <p>{text}</p>
            </div>
            <button className="finalCheckBtn" onClick={() => { navigati("/"); window.location.reload(); }}> بازگشت به داشبورد  </button>
          </div>
        </div>
      </div>
    </div>)
}

export default ConfirmLevelUp