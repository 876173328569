import Cookies from "js-cookie";
import moment from "moment";
import gather from "./gather";
import { config } from "../config/config";

const logOut = (redirect = false) => {
  Cookies.remove('accessTokenPanelEx', { path: '/' });
  Cookies.remove('refreshTokenPanelEx', { path: '/' });
  Cookies.remove('refreshTimePanelEx', { path: '/' });
  Cookies.remove('socketTokenPanelEx', { path: '/' });
  if (redirect) {
    window.location.reload();
    // await new Promise(re => setTimeout(re, 500))
  }
  return true
}

const userAuth = async (auto: boolean = false) => {
  const time = moment().format('YYYY-MM-DD hh:mm:ss');
  const refreshTime = Cookies.get('refreshTimePanelEx') ?? time;
  if (time >= refreshTime || auto) {

    const result = await gather(`${config.api}/v1/auth/refreshToken`).get({ 'Authorization': cookieRefreshToken() });
    if (result.code === 200) {
      Cookies.set('accessTokenPanelEx', result.data.accessToken, { expires: 30 * 24 * 60 * 60, path: '/' });
      Cookies.set('refreshTimePanelEx', moment().add(4, 'm').format('YYYY-MM-DD hh:mm:ss'));
    } else {
      if (result.code === 401) {
        logOut(true)
      }
    }
  }
}

const cookieAccessToken = () => {
  return Cookies.get('accessTokenPanelEx');
}

const cookieRefreshToken = () => {
  return Cookies.get('refreshTokenPanelEx');
}

const cookieSocketToken = () => {
  return Cookies.get('socketTokenPanelEx');
}


export { cookieSocketToken, cookieRefreshToken, cookieAccessToken, userAuth, logOut }