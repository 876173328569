import React, { useState } from 'react'
import AccordionTickt from './AccordionTickt'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'

const DefaultTicket = () => {


  return (
    <div className='defaultTicket'>
      <h3>قبل از ارسال تیکت به بخش پشتیبانی نکات زیر را مطالعه کنید</h3>
      <div className='description'>
        <p className='rtl'> لطفا از ارسال تیکت تکراری خودداری کنید زیرا پشتیبانی سایت به اولین تیکت در انتظار شما پاسخ خواهد داد .</p>
        <p className='rtl'> تنها برای پیگیری سفارشات ( در صورت عدم واریز طی ۲۴ ساعت گذشته روز کاری ) و مسائل فنی هنگام خرید ، فروش و سایر مشکلات تیکت ارسال کنید.</p>
        <p className='rtl'> زمان پاسخگویی به تیکت ها در ساعات و روز های کاری تا حداکثر ۲ ساعت و در روز های تعطیلی تا حداکثر ۵ ساعت می باشد.</p>
      </div>
      <h4>ثبت تیکت جدید</h4>
      <div className='accordionBox'>
        <AccordionTickt num={"1"} title={"چگونه اقدام به خرید و فروش کنیم ؟"}
          dec={"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"} />
        <AccordionTickt num={"2"} title={"مدارک احراز هویت ارسال کردم ، چقدر زمان برای تایید مدارک لازم است؟"}
          dec={"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS"} />
        <AccordionTickt num={"3"} title={"من پرداخت کردم مبلغ از حسابم کسر شد اما سفارش انجام نشد"}
          dec={"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS"} />
        <AccordionTickt num={"4"} title={"من خرید کردم ، چقدر زمان نیاز هست که ارز به دستم برسد؟"}
          dec={"لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد"} />
      </div>
      <NavLink to={menu.ticket.childs.create.url} className='btnNewTicket'>
        <div className='text'>
          <img crossOrigin='anonymous' src="/icons/CommentsWhite.svg" alt="" />
          <p> اگر همچنان جواب سوال خود را پیدا نکردید یک تیکت برای همکاران ارسال کنید</p>
        </div>
        <div className='imgArrowCircle'>
          <span className='icon-Arrow2'></span>
        </div>
      </NavLink>
    </div>
  )
}

export default DefaultTicket