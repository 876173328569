import { useEffect, useState } from "react";
import ForgetPass from "./ForgetPass";
import VerifyForgetPass from "./VerifyForgetPass";

const ForgetPageNew = () => {
  const [changePage, setChangePage] = useState("forget");
  const [mobile, setMobile] = useState("");

  const onSubmitLogin = (changeView: string, mobile: string) => {
    setMobile(mobile);
    setChangePage(changeView);
  }

  useEffect(() => {
    document.title = "فراموشی رمز - اکسنوین";
  }, []);

  return (
    changePage == "forget"
      ? <ForgetPass onSubmitLogin={onSubmitLogin} />
      : <VerifyForgetPass setChangePage={setChangePage} mobile={mobile} />
  )
}

export default ForgetPageNew;