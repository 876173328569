import { removeComma } from '../helpers/tools'

const CustomInputText = ({ inputState, setInputSatet, onPaste, type = "text", label, startEle, endEle, className, placeholder, error, classStart, classEnd }: any) => {
  return (
    <div className={`customInput ${className} ${error && "brRed"}`}>
      <label className="labelInput">{label}</label>
      {startEle && <div className={`startEle ${classStart}`}>{startEle}</div>}
      <input className='inputNumeric' value={inputState} type={type}
        //  onPaste={(e) => setInputSatet(e.clipboardData.getData('text'))}
        onChange={(e) => setInputSatet(e.target.value)} placeholder={placeholder} />
      {endEle && <div className={`endEle ${classEnd}`}>{endEle}</div>}
    </div>
  )
}

export default CustomInputText