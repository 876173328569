import _ from "lodash";
import React from "react";

export const formatter = (price: number, min: number = 0, max: number = 8, zeroMax = false) => {

  // const newMax = zeroMax ? 0 : max <= min ? min + 1 : max
  if (price) {
    return price.toLocaleString('en-US', {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    });
  } else {
    return zeroMax ? "0" : ''
  }
}

export const zeroFormat = (price: number, max: number = 4, isApi: boolean = true) => {
  // const newMax = max <= 0 ? 1 : max
  if (isApi && price < 10) {
    if (price < 1) {
      return price == 0 ? 0 : formatter(price, 0, 10);
    } else if (price < 5) {
      return price == 0 ? 0 : formatter(price, 0, 7);
    } else {
      return price == 0 ? 0 : formatter(price, 0, 5);
    }
  }

  if (price === Infinity) return ' - - ';

  if (price) {
    return formatter(price, 0, max);
  } else {
    return '0'
  }
}

export const autoFormatter = (price: number, isTmn: boolean = false) => {
  if (price) {
    let max = 0;

    if (isTmn) {
      max = 0;
    } else if (price >= 50000) {
      max = 0;
    } else if (price >= 1000) {
      max = 2;
    } else if (price >= 100) {
      max = 3;
    } else if (price >= 10) {
      max = 4;
    } else if (price >= 1) {
      max = 5;
    } else {
      const demical = price?.toString().split('.')[1];
      if (demical) {
        max = Number(demical.match(/^0*/)?.[0].length) + 4;
      }
    }
    return zeroFormat(price, max, false);
  } else {
    return '0'
  }
}

export const toFixed = (number: number, digits: number = 8) => {
  return Number(Number(number)?.toFixed(digits));
};

export const removeComma = (value: any) => {
  value = value?.toString().replace(/,/g, '');
  return value
}

export const toEnglishDigits = (str: any) => {
  // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
  var e = '۰'.charCodeAt(0);
  str = str.replace(/[۰-۹]/g, function (t: any) {
    return t.charCodeAt(0) - e;
  });

  // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
  e = '٠'.charCodeAt(0);
  str = str.replace(/[٠-٩]/g, function (t: any) {
    return t.charCodeAt(0) - e;
  });
  return str;
}

export const toFarsiNumber = (num: any) => {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

  return num.toString().replace(/\d/g, (x: any) => farsiDigits[x]);
}

export const findDateQuery = (data: any, division: any = false, and = false) => {
  let query: string = "";

  if (!data) return query;

  if (data["date"] && data["date"]?.length > 0) {
    if (division) {
      query = `&from=${(data["date"][0] / 1000).toFixed(0)}&to=${(data["date"][1] / 1000).toFixed(0)}`
    } else {
      query = `&from=${data["date"][0]}&to=${data["date"][1]}`
    }
  }

  Object.keys(data).forEach((key: any) => {
    if (key == "date") return

    if (data[key] && data[key] != "" && data[key] != "all") {
      query = query + `&${key}=${data[key]}`
    }
    return
  })

  return (and ? query : query.replace("&", '?'));
}

export const sortDown = (list: any, sortBy: string) => {
  const sortListUP = list?.sort((a: any, b: any) => {
    let priceA: any = 0;
    let priceB: any = 0;

    priceA = a[sortBy];
    priceB = b[sortBy];

    if (priceA < priceB) {
      return -1;
    }
    if (priceA > priceB) {
      return 1;
    }
    return 0;
  });
  return sortListUP
}

export const sortUp = (list: any, sortBy: string) => {
  const sortListDOWN = list?.sort((a: any, b: any) => {
    let priceA: any = 0;
    let priceB: any = 0;

    priceA = a[sortBy];
    priceB = b[sortBy];

    if (priceA > priceB) {
      return -1;
    }
    if (priceA < priceB) {
      return 1;
    }
    return 0;
  });
  return sortListDOWN
}

export const sumBalance = (asset: any, mainWalletObj: any, ticker2: any) => {
  let sum = 0
  if (asset && !_.isEmpty(asset)) {
    Array.from(asset).forEach((e: any) => {
      sum = sum + (Number(e.free) * Number(ticker2[(e?.coin?.symbol + "tmn")?.toLocaleLowerCase()]?.close))
    });
  }
  if (mainWalletObj["tmn"] && !_.isEmpty(mainWalletObj["tmn"])) {
    sum = sum + Number(mainWalletObj["tmn"]?.free);
  }
  return sum ?? 0
}

export const toCeil = (number: number, value: number = 8) => {
  const precision = Math.pow(10, value);
  return Math.ceil(number * precision) / precision;
};

export const TextWithLineBreaks = (text: any) => {
  const textWithBreaks = text?.split('\n').map((text: any, index: any) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ));

  return textWithBreaks;
}

export const crateQuerySearch = (listQuery: any) => {
  let txtQuery = "";

  if (listQuery && listQuery?.length > 0) {
    Array.from(listQuery)?.forEach((element: any) => {
      if (
        element?.value !== undefined &&
        element?.value?.toString()?.trim() !== "" &&
        element?.value !== null &&
        element?.value !== "NaN"
      ) {
        txtQuery = txtQuery + "&" + element?.key + "=" + element?.value
      }
    });
  }

  // return txtQuery?.replace("&", "?")
  return txtQuery
}