import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { zeroFormat } from '../../../helpers/tools';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { menu } from '../../../config/menu';
import useSnack from '../../../hooks/useSnack';
import moment from 'jalali-moment';

function SamplePrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

function SampleNextArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className}`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const ShowAllLevel = ({ setChangeview }: { setChangeview: any }) => {
  const snackUse = useSnack();
  const listLevelObj = useSelector((state: any) => state.publicApis.listLevelObj);
  const profile = useSelector((state: any) => state.user.profile);
  const [userProfile, setUserProfile]: any = useState({})

  const detailNeedLevel: any = {
    0: {
      "text1": "بدون نیاز",
      "text2": "بدون نیاز",
      "send": "detailUSer",
      "verify": ["", ""]
    },
    1: {
      "text1": "ثبت شماره تلفن همراه",
      "text2": "ثبت کارت بانکی",
      "send": "addCard",
      "verify": ["mobile", "card"]
    },
    2: {
      "text1": "تایید و تعهد قوانین اکسنوین",
      "text2": "ثبت و بارگزاری اطلاعات هویتی",
      "send": "role",
      "verify": ["rules", "document"]
    },
    3: {
      "text1": "ارسال درخواست و مدارک",
      "text2": "منتطر بررسی کارشناس اکسنوین",
      "send": "DocUpgrade",
      "verify": ["", ""]
    },
    4: {
      "text1": "ارسال درخواست و مدارک",
      "text2": "منتطر بررسی کارشناس اکسنوین",
      "send": 'DocUpgrade',
      "verify": ["", ""]
    }
  }

  const reqLevelUp = (level: any) => {
    if(userProfile?.plane?.request == "reject" && userProfile?.plane?.nextTime >= new Date().getTime()){
      snackUse.showError(`زمان درخواست بعدی : ${moment(userProfile?.plane?.nextTime).locale("fa").format('YYYY-MM-DD')} ساعت ${moment(userProfile?.plane?.nextTime).locale("fa").format('HH:mm')}`);
      return
    }
    if (profile.plane.request == "pending") {
      snackUse.showError("در انتظار بررسی ارتقای سطح کاربری");
    } else {
      setChangeview(level)
    }
  }

  let settings_3 = {
    dots: false,
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 2,
    rtl: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [{
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
    ]
  };

  const checkProfile = () => {
    if (profile?.plane?.request == "reject") {
      let newProfile: any = _.cloneDeep(profile);
      newProfile["plane"]["level"]["index"] = profile?.plane?.level?.index + 1;
      setUserProfile(newProfile);
    } else {
      setUserProfile(profile)
    }
  }

  useEffect(() => {
    checkProfile();
  }, [profile])

  return (
    <div className='detailLevelPage'>
      <div className="alertBoxAuth">
        {userProfile?.plane?.request == "reject"
          && <div className='item rejectLevel'>
            <div className='textAut'>
              <span className={`iconRed-identity`}></span>
              <h5 className='brNone rtl' style={{ textAlign: "right" }}>  دلیل رد درخواست ارتقا حساب : {userProfile?.plane?.reason ?? "خطا در ارتقا سطح"}</h5>
            </div>
            <div className='textAut'>
              <span className={`icon-Clock`}></span>
              <h5 className='brNone rtl' style={{ textAlign: "right" }}>  زمان درخواست بعدی : {moment(userProfile?.plane?.nextTime).locale("fa").format('YYYY-MM-DD')} ساعت {moment(userProfile?.plane?.nextTime).locale("fa").format('HH:mm')}</h5>
            </div>
          </div>}
      </div>
      <div className='cards'>
        <Slider {...settings_3}>
          {Array.from(Object.values(listLevelObj)).map((i: any, n: number) => <div className={`cardItem level-${userProfile?.plane?.level?.index > i.index ? "old" : (userProfile?.plane?.level?.index === i?.index && userProfile?.plane?.request != "reject") ? "now" : (userProfile?.plane?.level?.index === i?.index && userProfile?.plane?.request == "reject") ? "reject" : "next"}`} key={n}>
            <div className={`headTop`}>
              <h2>سطح {i.index}</h2>
              <h4>{userProfile?.plane?.level?.index > i.index ? "تایید شده" : (userProfile?.plane?.level?.index === i?.index && userProfile?.plane?.request != "reject") ? "سطح فعلی شما" : userProfile?.plane?.request == "reject" ? "رد شده" : ""}</h4>
            </div>
            <div className='bodyDetail'>
              <div className='desDetail'>
                <h3>اطلاعات مورد نیاز</h3>
                <div className='item'>
                  <img className='iconReject' src={`/icons/iconCloseRed.svg`} alt="" />
                  <img className='iconOk' src={`/icons/${i.index === 0 ? "iconCloseRed" : "m-Icon-tickCircleGreen"}.svg`} alt="" />
                  <img className='iconNot' src={`/icons/${((userProfile?.verify && userProfile?.verify[detailNeedLevel[i.index]?.verify[0]]) ? "m-Icon-tickCircleGreen" : "m-IconCircleGray")}.svg`} alt="" />
                  <h5 className={i.index === 0 ? "colorRed" : ((userProfile?.verify && userProfile?.verify[detailNeedLevel[i.index]?.verify[0]]) ? "colorGreen" : "")}>{detailNeedLevel[i.index].text1}</h5>
                </div>
                <div className={`horzDivider ${i.index === 0 ? "bgRed" : ""}`}></div>
                <div className='item'>
                  <img className='iconReject' src={`/icons/iconCloseRed.svg`} alt="" />
                  <img className='iconOk' src={`/icons/${i.index === 0 ? "iconCloseRed" : "m-Icon-tickCircleGreen"}.svg`} alt="" />
                  <img className='iconNot' src={`/icons/${((userProfile?.verify && userProfile?.verify[detailNeedLevel[i.index]?.verify[1]]) ? "m-Icon-tickCircleGreen" : "m-IconCircleGray")}.svg`} alt="" />
                  <h5 className={i.index === 0 ? "colorRed" : ((userProfile?.verify && userProfile?.verify[detailNeedLevel[i.index]?.verify[1]]) ? "colorGreen" : "")}>{detailNeedLevel[i.index].text2}</h5>
                </div>
              </div>
              <div className='deslevel'>
                <h3>امکانات</h3>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>واریز تومانی روزانه</h5>
                  </div>
                  <h5>نا محدود</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>برداشت تومانی روزانه</h5>
                  </div>
                  <h5>{zeroFormat(i?.withdraw?.fiat?.daily)}</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>واریز دلاری روزانه</h5>
                  </div>
                  <h5>نا محدود</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>برداشت دلاری روزانه</h5>
                  </div>
                  <h5>{zeroFormat(i?.withdraw?.coin?.daily)}</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>واریز تومانی ماهانه</h5>
                  </div>
                  <h5>نا محدود</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>برداشت تومانی ماهانه</h5>
                  </div>
                  <h5>{zeroFormat(i?.withdraw?.fiat?.monthly)}</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>واریز دلاری ماهانه</h5>
                  </div>
                  <h5>نا محدود</h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5>برداشت دلاری ماهانه</h5>
                  </div>
                  <h5>{zeroFormat(i?.withdraw?.coin?.monthly)}</h5>
                </div>
              </div>
              <div className='deslevel'>
                <h3>کارمزد</h3>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5 className='colorGreen'>کارمزد خرید تومانی</h5>
                  </div>
                  <h5 className='fontYekanBakh'>{i?.fee?.tmn?.buy} درصد </h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5 className='colorRed'>کارمزد فروش تومانی</h5>
                  </div>
                  <h5 className='fontYekanBakh'>{i?.fee?.usdt?.sell} درصد </h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5 className='colorGreen'>کارمزد خرید تتری</h5>
                  </div>
                  <h5 className='fontYekanBakh'>{i?.fee?.tmn?.buy} درصد </h5>
                </div>
                <div className='item'>
                  <div>
                    <img src="/icons/m-Icon-tickOnlyGreen.svg" alt="" />
                    <h5 className='colorRed'>کارمزد فروش تتری</h5>
                  </div>
                  <h5 className='fontYekanBakh'>{i?.fee?.usdt?.sell} درصد </h5>
                </div>
              </div>
              {(userProfile?.plane?.level?.index + 1 === i.index && userProfile?.plane?.request != "reject")
                ? <div className='requsetLevel'>
                  {i.index == 3 ? <p>بدون نیاز به پر کردن فرم (ارتقا خودکار)</p> : <p className='opacity0'>بدون نیاز به پر کردن فرم (ارتقا خودکار)</p>}
                  <button className="finalCheckBtn fontYekanBakh" onClick={() => reqLevelUp(detailNeedLevel[i.index].send)}> {userProfile?.plane?.request == "pending" ? "منتظر بررسی" : `ارتقا به سطح کاربری ${i.index}`}  </button>
                </div>
                : (userProfile?.plane?.level?.index === i.index && userProfile?.plane?.request == "reject")
                  ? <Tooltip title={userProfile?.plane?.reason}>
                    <div className='requsetLevel'>
                      <p className='opacity0 '>. . </p>
                      <button className="finalCheckBtn rejectBtn" onClick={() => reqLevelUp(detailNeedLevel[i.index].send)}>رد شد</button>
                    </div>
                  </Tooltip>
                  : <div className='requsetLevel opacity0'>
                    <p className='opacity0'>...</p>
                    <button className="finalCheckBtn">..</button>
                  </div>
              }
            </div>
          </div>)}
        </Slider>
      </div>
      <div className='needUpgradeMore'>
        <p>آیا به سطح بالاتر نیاز دارید ?</p>
        <NavLink to={menu.ticket.childs.create.url}>ارتقا به سطح بالاتر</NavLink>
      </div>
    </div>
  )
}

export default ShowAllLevel