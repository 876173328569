import { ClickAwayListener } from '@mui/material';
import { useRef, useState } from 'react';
import _ from "lodash";
import ListCoinGift from './ListCoinGift';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { zeroFormat } from '../../../helpers/tools';


const SelectCoinGift = ({ setListSelect, listSelect, listData, error, reset}: any) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    // setOpen(false);
  };

  const handelAddList = (e: any) => {
    let newObj=_.cloneDeep(listSelect);
    if(e._id=="tmn"){
      newObj[e._id]={fiat: e?._id, amount: e?.giftAmount,name:e?.nameFa}
    }else{
      newObj[e._id]={coin: e?._id, amount: e?.giftAmount,name:e?.nameFa}
    }
    setListSelect(newObj)
  }

  const handleDelete=(id:any)=>{
    let newObj=_.cloneDeep(listSelect);
    delete newObj[id]
    setListSelect(newObj)
  }

  return (
    <div className={`customInput customInputSelect customSelectGift  ${error && "brRed"}`} >
      {/* <ClickAwayListener onClickAway={handleClose}> */}
        <div className='textViewBox' onClick={() => setOpen(!open)}>
          <div className='showCoinsSelect'>
            {_.isEmpty(Object.keys(listSelect))
              ? <p className='def'>انتخاب ارز </p>
              : Array.from(Object.values(listSelect))?.map((i: any, n: any) =>
                <div key={n} className='itemSelect'>
                  <p>{i?.name ?? "--"}</p>
                  <p>{zeroFormat(i?.amount) ?? "0"}</p>
                  <IoCloseCircleOutline size={17} className='cPointer' onClick={()=>handleDelete(i?.coin)}/>
                </div>
              )}
          </div>
          <button ref={anchorRef} className='btnPopUp' ><span className={`icon-Arrow2 cPointer ${open && "rotate"}`}></span></button>
        </div>
      {/* </ClickAwayListener> */}
      <ListCoinGift listData={Object.values(listData)??[]} className={`${open ? "" : "none"}`} handleAdd={(e: any) => { handelAddList(e); }}
        onClick={()=>{}} />
    </div >
  )
}

export default SelectCoinGift;