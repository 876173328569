import { ClickAwayListener } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ListCoinPopup from './ListCoinPopup';
import _ from "lodash";
import { config } from '../config/config';
import ListCoinArrayPopup from './ListCoinArrayPopup';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CustomSelectBoxSearch = ({ setInputSatet, listData, className, error, reset, defalutText = "", defalteImg = {}, classNamePopup = "" }: any) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState(Object.values(listData) ?? []);
  const [input, setInput]: any = useState(defalutText)
  const [img, setImg]: any = useState(defalteImg);
  const [firstTime, setFirstTime]: any = useState(false);


  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const searchItem = (text: any) => {
    setInput(text)
    if (_.isEmpty(Object.values(listData))) return
    const find = Array.from(Object.values(listData)).filter((e: any) =>
      e?.name?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.symbol?.toLowerCase()?.includes(text?.toLowerCase())
      || e?.nameFa?.toLowerCase()?.includes(text?.toLowerCase()));

    if (find) {
      // const list = find.reduce((a: any, v: any) => ({ ...a, [v?.coin?.symbol]: v }), {});
      setList(find);
    } else {
      setList(Object.values(listData));
    }
  }

  useEffect(() => {
    if (firstTime) {
      setImg({});
      setInput('');
    } else {
      setFirstTime(true);
    }
  }, [reset])

  useEffect(() => {
    if (!_.isEmpty(defalteImg)) {
      setImg(defalteImg);
      setInput(defalutText);
    }
  }, [defalteImg, defalutText])

  useEffect(() => {
    setList(Object.values(listData) ?? []);
  }, [listData]);


  return (
    <div className={`customInput customInputSelect ${className} ${error && "brRed"}`} >
      <ClickAwayListener onClickAway={handleClose}>
        <div className='textViewBox' onClick={() => setOpen(true)}>
          <div className='showCoinSearch'>
            {!_.isEmpty(img) && <img crossOrigin='anonymous' src={`${config.imgUrl}${img?.symbol?.toUpperCase()}.png`} alt="" />}
            <input type="text" value={input} onChange={(e) => searchItem(e.target.value)} placeholder='انتخاب ارز' />
          </div>
          <button ref={anchorRef} className='btnPopUp' ><span className="icon-Arrow2 cPointer"></span></button>
        </div>
      </ClickAwayListener>
      <ListCoinArrayPopup listData={list} className={`${classNamePopup} ${open ? "" : "none"}`} onClick={(e: any) => { setInputSatet(e); setInput(e?.nameFa); setImg(e) }} />
    </div >
  )
}

export default CustomSelectBoxSearch;