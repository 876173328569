import * as  BackdropShow from '../../services/redux/backdrop';
import AccountBank from '../Profile/AccountBank';
import { IoArrowForwardOutline } from 'react-icons/io5';
import LogoHeader from '../../components/LogoHeader';
import { config } from '../../config/config';
import gather from '../../helpers/gather';
import { useDispatch } from 'react-redux';
import StepByStep from './StepByStep';
import MoblieStep from './MoblieStep';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getProfile } from '../../services/redux/user';
import useSnack from '../../hooks/useSnack';
import { CircularProgress } from '@mui/material';

const UserAddCard = ({ setChangePage }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const send = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/levelUp`).get();

    if (result.code === 200) {
      setChangePage("confirmLevelUp")
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  const get = async () => {
    await dispatch(getProfile())
  }

  useEffect(() => {
    get()
  }, [])


  return (
    <div className="authPage">
      <div className='container'>
        <div className=" auth">
          <LogoHeader />
          <div className='authBody'>
            <div className="top">
              <div className="titleBack">
                <div onClick={() => setChangePage("addDetails")}><IoArrowForwardOutline size={20} /></div>
                <h5> احراز هویت</h5>
              </div>
              <MoblieStep classNames={"none"} step={"2"} />
            </div>
            <StepByStep current={2} />
            <AccountBank />
            <div className='btn' >
              {
                myLoading
                  ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                  : <button className="finalCheckBtn upbtn" onClick={send}>
                    <img crossOrigin='anonymous' src="/icons/gonext.svg" width="15px" alt="" />
                    ثبت و مرحله بعد
                  </button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>)
}

export default UserAddCard