import React, { memo, useState } from 'react'
import { config } from '../../../../config/config'
import { zeroFormat } from '../../../../helpers/tools'
import { useSelector } from 'react-redux';
import EleItemOther from './EleItemOther';

const ItemOther = ({ list, data, onClick, isMarket }: any) => {
  const mainWalletObj: any = useSelector((state: any) => state.user?.mainWalletObj);

  return (
    <div className="box">
      <div id='nameCoin'>
        <img crossOrigin='anonymous' src={`${config.imgUrl}${list[data]?.symbol?.toUpperCase()}.png`} alt="" />
        <div>
          {list[data]?.myPair
            ? <>
              <p className='colorGray fontBold'>{list[data]?.symbol?.toUpperCase()}/{list[data]?.myPair?.toUpperCase()}</p>
              <p>{list[data]?.nameFa ?? "- - -"}/<b className='colorGray'>{list[data]?.myPairFa}</b></p>
            </>
            : <>
              <p className='colorGray fontBold'>{list[data]?.symbol?.toUpperCase()}</p>
              <p>{list[data]?.nameFa ?? "- - -"}</p>
            </>}
        </div>
      </div>
      <div className='bursStyle'>
        {/* <span className={handleColor(list[data]?.symbol + "USDT").icon}></span> */}
        {/* <p className={`color${handleColor(list[data]?.symbol + "USDT").color}`}>{list[data].symbol == "usdt" ? 1 : zeroFormat(ticker(list[data]?.symbol + "USDT"), listCoinObj[list[data]?.symbol]?.precision)} $</p> */}
        <EleItemOther symbol={list[data]?.symbol} type={"price"} pair={"usdt"} />
      </div>
      <div className='color/green'>{zeroFormat(mainWalletObj[list[data]?.symbol]?.free)} {list[data]?.symbol?.toUpperCase()}</div>
      {isMarket
        ?
        <>
          <div className='bursStyle'>
            <EleItemOther symbol={list[data]?.symbol} type={"price"} pair={"tmn"} />
          </div>
          <div id='orders'>
            <div className='buy' onClick={() => onClick(true, list[data])}>
              <img src="/icons/m-CardBuy.svg" alt="" />
              <p>انتخاب</p>
            </div>
          </div>
        </>
        : <>
          <div className='bursStyle'>
            {/* <span className={handleColor(list[data]?.symbol + "TMN").icon}></span> */}
            {/* <p className={`color${handleColor(list[data]?.symbol + "TMN").color}`}>{zeroFormat(ticker(list[data]?.symbol + "TMN"), 1)}</p> */}
            <EleItemOther symbol={list[data]?.symbol} type={"buy"} pair={"tmn"} />
          </div>
          <div className='bursStyle'>
            {/* <span className={handleColor(list[data]?.symbol + "TMN").icon}></span> */}
            {/* <p className={`color${handleColor(list[data]?.symbol + "TMN").color}`}>{zeroFormat(ticker(list[data]?.symbol + "TMN"), 1)}</p> */}
            <EleItemOther symbol={list[data]?.symbol} type={"sell"} pair={"tmn"} />
          </div>
          <div id='orders'>
            <div className='buy' onClick={() => onClick(true, list[data])}>
              <img src="/icons/m-CardBuy.svg" alt="" />
              <p>خرید</p>
            </div>
            <div className='sell' onClick={() => onClick(false, list[data])}>
              <img src="/icons/m-CardSell.svg" alt="" />
              <p>فروش</p>
            </div>
          </div>

        </>
      }
    </div>
  )
}

export default memo(ItemOther) 