import { CircularProgress, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { toEnglishDigits } from '../../helpers/tools';
import LogoHeader from '../../components/LogoHeader';
import { config } from '../../config/config';
import { useTranslation } from 'react-i18next';
import gather from '../../helpers/gather';
import { useState } from 'react';
import useSnack from '../../hooks/useSnack';
import { useNavigate } from 'react-router-dom';

const Login = ({ onSubmitLogin }: any) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [errorMobile, setErrorMobile] = useState(0);
  const [errorPass, setErrorPass] = useState(false);
  const snackUse = useSnack();
  const navigati = useNavigate();
  const [myLoading, setMyLoading] = useState(false);

  const handleMobile = (e: any) => {
    const number = e.target.value?.replace(/\D/g, "").slice(0, 11)
    setMobile(number)
    let newMobile = number.startsWith("0") ? number : '0' + number;
    if (newMobile.length === 11) {
      setErrorMobile(2);
    } else {
      setErrorMobile(0)
    }
  }

  const send = async () => {
    let newMobile = mobile.startsWith("0") ? mobile : '0' + mobile;
    if (newMobile.length !== 11) {
      setErrorMobile(1);
      setTimeout(() => { setErrorMobile(0) }, 3000);
      return
    }
    setErrorMobile(2);

    if (password.length < 1) {
      setErrorPass(true);
      setTimeout(() => { setErrorPass(false) }, 3000);
      return
    }

    setMyLoading(true);
    const body = {
      mobile: toEnglishDigits(newMobile?.toString()),
      password: toEnglishDigits(password?.toString()),
    }

    const result = await gather(`${config.api}/v1/auth/signin`).post(body);

    if (result.message === 200) {
      sendVerify(toEnglishDigits(newMobile?.toString()), result?.data?.type)
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setMyLoading(false);
    }

  }

  const sendVerify = async (mobile: any, type: any) => {
    setMyLoading(true);
    const body = {
      "mobile": mobile,
      "type": type // sms - gAuth - email
    }

    const result = await gather(`${config.api}/v1/auth/signin/confirm`).post(body);

    if (result.message === 200) {
      onSubmitLogin("verify", mobile, type, body);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <div className="loginPage">
      <div className=" login">
        <LogoHeader />
        <div className="loginBody">
          {/* <div className="loginBack">
            <IoArrowForwardOutline size={20} />
          </div> */}
          <div className="upTitle">
            <h5>ورود به حساب کاربری</h5>
            <p>خوش آمدید به اکسنوین</p>
          </div>
          <div className="textCenter">
            <p className="sign">
              در اکسنوین عضو نیستید؟
              <span className='btn' onClick={() => navigati("/register")} >
                ایجاد حساب کاربری
              </span>
            </p>
          </div>
          <div className="inputs">
            <FormControl className={`formControlLogin ${errorMobile === 1 ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>شماره همراه</InputLabel>
              <OutlinedInput fullWidth label="شماره همراه" type='number'
                onChange={handleMobile}
                value={mobile}
                startAdornment={
                  <div className="iconInput cityCode">
                    <p>+98</p>
                    <img crossOrigin='anonymous' src="/icons/iranflag.svg" height="22px" alt="" />
                  </div>}
                endAdornment={<>
                  <img crossOrigin='anonymous' className={`doneit ${errorMobile === 2 ? "" : "none"}`} src="/icons/donelogin.svg" alt="" />
                  <img crossOrigin='anonymous' className={`errorit ${errorMobile === 1 ? "" : "none"}`} src="/icons/loginerror.svg" alt="" /></>} />
            </FormControl>
            <div className={`errorText ${errorMobile === 1 ? "opacity1" : ""}`}>
              <span>شماره همراه خود را بازبینی کنید</span>
            </div>
            <FormControl className={`formControlLogin ${errorPass ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>رمز عبور</InputLabel>
              <OutlinedInput fullWidth={true} label="رمز عبور"
                type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)}
                startAdornment={<span className='icon-loginlock'></span>}
                endAdornment={<span className={`cPointer icon-IconEye${showPassword?"Close":"Open"}`}  onClick={() => setShowPassword(!showPassword)}  ></span>} />
            </FormControl>
            <div className={`errorText ${errorPass ? "opacity1" : ""}`}>
              <span>ورودی خود را بازبینی کنید</span>
            </div>
            <div className="textForget">
              <div className="formCheck">
                <label >مرا بخاطر بسپار <input type="checkbox" /></label>
              </div>
              <div className="forget" onClick={() => navigati("/forget")} >
                فراموشی رمز عبور
                <img crossOrigin='anonymous' src="/icons/forgetlogin.svg" width="20px " alt="" />
              </div>
            </div>
            {
              myLoading
                ? <div className="loadingCircular mt-15"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
                :
                <button className="finalCheckBtn" onClick={send}> ورود  </button>
            }
          </div>
        </div>
      </div>
    </div>)
}

export default Login;