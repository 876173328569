import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Header from '../../components/header/Header';
import _ from 'lodash';
import FetchListLoad from '../../components/FetchListLoad';
import Footer from '../../components/Footer';
import { Box, CircularProgress, Divider, Slider } from '@mui/material';
import { marksSlidBar } from '../../config/variable';
import { menu } from '../../config/menu';

const InvitationCode = () => {
  const [listHistory, setListHistory]: any = useState(["","","",""]);
  const [loadHistory, setLoadHistory] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nameCode, setNameCode] = useState("");
  const [valueSlider, setValueSlider] = useState(100);

  const handleSliderBar = (event: Event, newValue: number | number[]) => {
    setValueSlider(newValue as number);

  }

  const get = () => {

  }

  useEffect(() => {
    document.title = menu.invitationCode.title;
    get();
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className="invitationBody">
            <div className='rigth'>
              <p className='txTop'>ایجاد کد دعوت جدید</p>
              <p className='txDetail'>شما می توانید کارمزد خود و دوستان خود را مشخص کنید و کد دعوت جدید را دریافت کنید.</p>
              <div className='titleBox'>
                <span className='s1'>1</span>
                <p className='s2'>نام دعوتنامه</p>
              </div>
              <div className='inputBox'>
                <input type="text" value={nameCode} onChange={(e: any) => setNameCode(e.target.value)} placeholder='نام دعوتنامه' />
              </div>
              <div className='titleBox'>
                <span className='s1'>2</span>
                <p className='s2'>تعیین کارمزد</p>
              </div>
              <div className='sliderRage'>
                <Box sx={{ width: '100%' }}>
                  <Slider
                    aria-label='Restricted values'
                    track='inverted'
                    value={typeof valueSlider === 'number' ? valueSlider < 0 ? 100 : valueSlider : 100}
                    onChange={handleSliderBar}
                    marks={marksSlidBar}
                  // step={25}
                  // sx={{
                  //   color: theme.palette.mode === 'dark' ? '#fff' : '#0f0f0f33',
                  //   '& .MuiSlider-track': {
                  //     border: `3px solid ${'#2424da'}`,
                  //     boxShadow: '0px 7.45px 14.91px 1.45px #2424da',
                  //   },
                  //   '& .MuiSlider-thumb': {
                  //     width: 19,
                  //     height: 19,
                  //     backgroundColor: '#2424da',
                  //     border: `4px solid ${'#2424da'}`,
                  //     // position: 'relative',
                  //     '&::before': {
                  //       boxShadow: '1px 12px 20px 3px #2424da',
                  //     },
                  //     '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  //       boxShadow: 'none',
                  //     },
                  //     '&::after': {
                  //       position: 'absolute',
                  //       content: '""',
                  //       width: '2px',
                  //       height: '15px',
                  //       background: '#2424da',
                  //       top: '23px',
                  //       right: '5px',
                  //       borderRadius: '0px'
                  //     },
                  //   },
                  //   '& .MuiSlider-mark': {
                  //     backgroundColor: '#e0e0e0',
                  //     width: 8,
                  //     height: 8,
                  //     borderRadius: 50,
                  //   },
                  //   '& .MuiSlider-markActive': {
                  //     backgroundColor: `${'#2424da'}`,
                  //   }
                  // }}
                  />
                </Box>
              </div>
              <div className='divider'></div>
              <div className='txtBox'>
                <p className='t1'>کارمزد شما</p>
                <p className='t2'><b>%</b> 30</p>
              </div>
              <div className='txtBox'>
                <p className='t1'>کارمزد همتاوب</p>
                <p className='t2'><b>%</b> 10</p>
              </div>

              <div className='btnBox'>
                <div className='pairBtn'>
                  <button className='btn'>تومان</button>
                  <button className='btn'>تتر</button>
                </div>
                <button className='submit'>ایجاد کد دعوت نامه</button>
              </div>

            </div>
            <div className='left'>
              <div className='amar'>
                <div className='boxTxt'>
                  <span className='icon-IconUsers1'></span>
                  <p className='t1'>۲۵ کابر</p>
                  <p className='t2'>دعوت شده</p>
                </div>
                <div className='boxTxt'>
                  <span className='icon-IconCards2'></span>
                  <p className='t1'>۱۳۵.۰۰۰ تومان</p>
                  <p className='t2'>حجم معاملات</p>
                </div>
                <div className='boxTxt'>
                  <span className='icon-IconChart2'></span>
                  <p className='t1'>۲۵ کابر</p>
                  <p className='t2'>تعداد معاملات</p>
                </div>

              </div>
              <div className='defaultCode'>
                <p className='txTop'>دعوت نامه پیش فرض</p>
                <p className='txDetail'>با دعوت از دوستان خود در اکسنوین ، بخشی از کارمزد معاملات انها را دریافت کنید.</p>
                <div className='titleBox'>
                  <span className='s1'>1</span>
                  <p className='s2'>دعوت نامه با لینک</p>
                </div>
                <div className='textBox mb-24'>
                  <p className='tx1'>https://hamtaweb.com/portfolio/</p>
                  <span className='icon-IconCopy4'></span>
                </div>
                <div className='titleBox'>
                  <span className='s1'>2</span>
                  <p className='s2'>دعوت نامه با توکن</p>
                </div>
                <div className='textBox'>
                  <p className='tx1'>https://hamtaweb.com/portfolio/</p>
                  <span className='icon-IconCopy4'></span>
                </div>
              </div>
            </div>
          </div>

          <div className='historyInvitation'>
            <div className='tb_opratorBox'>
              <h4>کد های دعوت نامه فعال </h4>
              <div className='refreshAndInfo'>
                <button className='refresh' onClick={() => get()}>
                  <img crossOrigin='anonymous' src="/icons/m-IconRefresh.svg" alt="" />
                  <p>بروزرسانی</p>
                </button>
              </div>
            </div>
            <div className='tb_View tb_Invitation'>
              <table>
                <thead>
                  <tr>
                    <th><h5>نام</h5></th>
                    <th><h5>توکن</h5></th>
                    <th><h5>کد دعوت</h5></th>
                    <th><h5>سهم شما</h5></th>
                    <th><h5>سهم دوستان</h5></th>
                    <th><h5>تعداد دوستان</h5></th>
                    <th><h5>مجموع دریافتی شما</h5></th>
                    <th><h5>لینک دعوتنامه</h5></th>
                  </tr >
                </thead >
                <tbody className={"relative"}>
                  {(!loadHistory && !_.isEmpty(listHistory))
                    && Array.from(listHistory)?.map((i: any, n: any) =>
                      <tr key={n} className="box">
                        <td>
                          <div className='name'>
                            <span className='t1'>همتاوب</span>
                            <span className={`t1 ${n%2==0?"colorGreen":"colorRed"}`}>(فعال)</span>
                          </div>
                        </td>
                        <td>USDT</td>
                        <td>123456789</td>
                        <td>30%</td>
                        <td>10%</td>
                        <td>25 کابر</td>
                        <td>250.000 تومان</td>
                        <td>
                          <div className='btn'>
                            <p>کپی کردن لینک</p>
                            <span className='icon-IconCopy4'></span>
                          </div>
                        </td>
                      </tr>)}
                </tbody>
              </table >
              <FetchListLoad list={listHistory} load={loadHistory} />
            </div >
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InvitationCode;
