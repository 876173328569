import { DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as DialogShow from '../../../services/redux/dialog';
import EditPhone from './EditPhone';
import { IoMdCloseCircle } from 'react-icons/io';


const Warning = ({ value }: { value: any }) => {
  const dispatch = useDispatch<any>();

  return (
    <>
      <DialogContent className='dialogWarningTitel'>
        <div className='titleCloseDialog'>
          <div className='textWarning'>
            <span className='iconRed-identity'></span>
            <h4>هشدار</h4>
          </div>
          <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
        </div>
      </DialogContent>
      <DialogContent className='dialogWarning'>
        <p>{"توجه داشته باشید به منظور حفظ امنیت دارایی شما، پس از تغییر"} &nbsp;
          <strong>موبایل</strong>&nbsp; {"به مدت ۲۴ ساعت برداشت رمز ارز  محدود میباشد"}</p>
        <div className='btns'>
          <button className='btOk' onClick={() => dispatch(DialogShow.show(<EditPhone oldMobile={value} />))}>تایید</button>
          <button onClick={() => dispatch(DialogShow.hide())}>بازگشت</button>
        </div>
      </DialogContent>
    </>
  )
}

export default Warning;