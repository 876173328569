import { useNavigate } from 'react-router-dom';
import LogoHeader from '../../../components/LogoHeader';
import { menu } from '../../../config/menu';

const ConfirmUpgrade = ({ text, close }: { text: string, close: any }) => {


  return (
    <div className="confirmUpgrade">
      <img crossOrigin='anonymous' src="/icons/iconSubmitAuth.svg" alt="" />
      <h4>تکمیل شد</h4>
      <p>{text}</p>
      <button className="finalCheckBtn" onClick={close}> متوجه شدم  </button>
    </div>)
}

export default ConfirmUpgrade