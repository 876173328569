import React, { useState } from 'react'
import { DialogContent, Divider } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import Countdown from "react-countdown";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import VerificationInput from 'react-verification-input';
import * as BackdropShow from '../../../services/redux/backdrop';
import gather from '../../../helpers/gather';
import _ from 'lodash';
import useSnack from '../../../hooks/useSnack';

const VerifyWithdrawCoin = ({ body, type, close, submit, mobile }: { body: any, type: string, close: any, submit: any, mobile: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [showTimer, setShowTimer] = useState({ flag: 1, show: true, myTime: Date.now() + 240000 });
  const [code, setCode] = useState('');
  const snackUse = useSnack();

  const send = (e: any) => {
    const newBody: any = _.cloneDeep(body);
    newBody.code = e;

    submit(newBody)
  }

  const reloadCode = async () => {
    if (showTimer.show) return;

    dispatch(BackdropShow.show());

    const result = await gather(`${config.api}/v1/withdraw`).post(body)

    if (result.message === 200) {
      setShowTimer({ flag: 1, show: true, myTime: Date.now() + 240000 });
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    dispatch(BackdropShow.hide());
  }

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return <h3 ></h3>;
    } else {
      return <span>0{minutes}:{seconds < 10 && "0"}{seconds}</span>;
    }
  };

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='verifyWithdrawCoin'>
        <div className="body">
          {(type == "gAuth")
            ? <div className="upTitle">
              <h5 className="rtl"> کد تایید Google Authenticator</h5>
              <p className="rtl">کد دو عاملی خود را وارد کنید</p>
            </div>
            : <div className="upTitle">
              <h5>تاییدیه پیامکی</h5>
              <p>کد تاییدیه برای شماره {mobile} پیامک شد</p>
            </div>
          }
          <div className="inputs">
            <div className="text-right">
              {(type == "gAuth")
                ? <span className="textvr rtl">کد Google Authenticator</span>
                : <span className="textvr">کد تاییدیه</span>}
            </div>
            <div className='codeNumberBox'>
              <VerificationInput
                onComplete={(e) => send(e)}
                onChange={(e) => { Number(e) >= 0 && setCode(e) }}
                value={code}
                placeholder='-'
                inputProps={{ type: "tel" }}
                classNames={{
                  container: "containerVerify",
                  character: "characterVerify",
                  characterFilled: "character--filled",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
            </div>
            {(type == "gAuth")
              ? <div className="disableBtn ">
                <h5 className="again">ارسال مجدد</h5>
              </div>
              : <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
                {showTimer.show &&
                  <div className="btn">
                    <Countdown date={showTimer.myTime} renderer={renderer}
                      onComplete={() => setShowTimer({ flag: 0, show: false, myTime: Date.now() + 240000 })} />
                  </div>}
                {!showTimer.show &&
                  <h5 className="again">ارسال مجدد</h5>}
                <div className="progress"></div>
              </div>
            }

          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default VerifyWithdrawCoin