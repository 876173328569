import React from 'react';
import { MdVpnLock } from "react-icons/md";


const WarningVPN = ({ close }: { close: any }) => {
  return (
    <div className='warningMemo warningVPN'>
      <div className='top'>
        <p>توجه!</p>
        <span className='iconClose cPointer' onClick={close} ></span>
      </div>
      <MdVpnLock size={100} color='var(--color-red1)' />
      <h3>فیلترشکن</h3>
      <h4 className='text'>برای ورود به درگاه پرداخت لطفا <b>VPN</b> خود را خاموش کنید</h4>
      <button className='finalCheckBtn' onClick={close}>متوجه شدم</button>
    </div>
  )
}

export default WarningVPN