import React from 'react'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'
import { useLocalStorage } from 'usehooks-ts'

const WarningMaxDeposit = ({ close }: { close: any }) => {
  const [warning, setWarning, removeWrning] = useLocalStorage('warningDepo', "")
  const hide = () => {
    removeWrning();
    close();
  }
  return (
    <div className='warningMemo'>
      <div className='top'>
        <p>توجه!</p>
        <img onClick={close} src="/icons/m-IconCloseBlack.svg" alt="" />
      </div>
      <h4 className='text'>
        کاربر گرامی ! فقط از شماره شبا های که به نام خودتان در سایت اکسنوین ثبت شده است  نسبت به واریز وجه اقدام نمایید . درغیر اینصورت تراکنش شما ثبت نمی گردد   </h4>
      <div className='btnControl'>
        <button className='hideLater' onClick={hide}>دیگر نشان نده</button>
        <NavLink to={menu.userBank.childs.account.url} className='goto' onClick={close}>ثبت شمار شبا جدید</NavLink>
      </div>
    </div>
  )
}

export default WarningMaxDeposit