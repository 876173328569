import { memo, useEffect, useState } from 'react';
import { autoFormatter, removeComma, zeroFormat } from '../../../../helpers/tools';

const Depth = ({ orderBookData, precision, bgGreen, setPriceB, setPriceS, setAmountB,
  setAmountS, setTotalS, setTotalB, typePrice }: any) => {
  const [dataList, setDataList] = useState([])

  const handleClick = (n: any) => {
    if (typePrice == "market") return;
    
    if (bgGreen) {
      setPriceS(removeComma(handleAvgPrice(n)))
      setAmountS(removeComma(handleTotalVolume(n)))
      setTotalS(removeComma(handleTotalVPrice(n)))
    } else {
      setPriceB(removeComma(handleAvgPrice(n)))
      setAmountB(removeComma(handleTotalVolume(n)))
      setTotalB(removeComma(handleTotalVPrice(n)))
    }
  }

  const handleAvgPrice = (numI: number) => {
    let number = 0;
    Array.from(dataList)?.reverse()?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += element?.price
      }
    });
    if (number > 0) {
      number = number / (dataList.length - numI);
    }

    return zeroFormat(number, precision, false)
  }

  const handleTotalVolume = (numI: number) => {
    let number = 0;
    Array.from(dataList)?.reverse()?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += element?.amount
      }
    });

    return zeroFormat(number)
  }

  const handleTotalVPrice = (numI: number) => {
    let number = 0;
    Array.from(dataList)?.reverse()?.forEach((element: any, n: any) => {
      if (n >= numI) {
        number += (Number(element?.price) * Number(element?.amount))
      }
    });

    return zeroFormat(number)
  }

  const handleQuantity = (i: any) => {
    const max = Math.max(...dataList.map((e: any) => e?.quantity));
    const min = Math.min(...dataList.map((e: any) => e?.quantity));
    let percent = ((i - min) / (max - min)) * 100;

    if (percent < 1) {
      percent = 1;
    }

    return percent + "%"
  }

  useEffect(() => {
    setDataList(orderBookData ?? [])
  }, [orderBookData])

  return <>
    {Array.from(dataList)?.reverse()?.map((i: any, n: any) =>
      <div key={n} className='tr cPointer' onClick={() => handleClick(n)}>
        <div className='td '>
          <p className={`${bgGreen ? "colorGreen" : "colorRed"}`}>{zeroFormat(i?.price)}</p>
        </div>
        <div className='td'><p>{autoFormatter(i?.amount)}</p></div>
        <div className='td '><p>{zeroFormat((Number(i?.price) * Number(i?.amount)), precision, false)}</p></div>
        <div className={`progOrder ${bgGreen && "bgGreen2"}`} style={{ width: handleQuantity(i?.quantity) }}></div>

        <div className='hoverOrderBook'>
          <div className='itemHover'>
            <p className='p1'>میانگین قیمت : </p>
            <p className='p1'>{handleAvgPrice(n)}</p>
          </div>
          <div className='itemHover'>
            <p className='p1'>حجم کل : </p>
            <p className='p1'>{handleTotalVolume(n)}</p>
          </div>
          <div className='itemHover'>
            <p className='p1'>ارزش کل : </p>
            <p className='p1'>{handleTotalVPrice(n)}</p>
          </div>
        </div>
      </div>
    )}
  </>

};

export default memo(Depth);
// export default Depth;
