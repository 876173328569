import React from 'react';
import useTicker from '../../../hooks/useTicker';
import { zeroFormat } from '../../../helpers/tools';

const ItemPriceCoin = ({pair}:{pair:any}) => {
    const ticker=useTicker();

    return (
        <p className='p1 '>{zeroFormat(ticker.price(pair))}</p>
    );
}

export default ItemPriceCoin;
