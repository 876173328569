import React, { useEffect, useState } from 'react'
import { bankAcoount } from '../../../config/variable'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'
import * as DialogShow from '../../../services/redux/dialog'
import WarningMaxDeposit from '../dialogs/WarningMaxDeposit'
import { useLocalStorage } from 'usehooks-ts'
import { Menu, Tooltip } from '@mui/material'
import QRCode from 'react-qr-code';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import useSnack from '../../../hooks/useSnack'

const GeneratedID = ({ data, isNew }: any) => {
  const dispatch = useDispatch<any>();
  const snackUse = useSnack();
  const [warning, setWarning, removeWrning] = useLocalStorage('warningDepo', "");
  const [numberGenerated, setNumberGenerated] = useState('23');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, num: any) => {
    setNumberGenerated(num);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const eleToolTip = (num: any) => {
    return (<div className='qrCodeGeneratedID'>
      <QRCode
        size={306}
        style={{ height: '73px', width: '73px' }}
        value={num}
        viewBox={`0 0 73 73`}
      />
    </div>)
  }

  useEffect(() => {
    if (warning == "true") {
      dispatch(DialogShow.show(<WarningMaxDeposit close={() => dispatch(DialogShow.hide())} />))
    }
  }, [])


  return (
    <>
      <div className='reCreateID'>
        {isNew && <div className='textOkCreate'>شناسه واریز شما با موفقیت ساخته شد</div>}
        {/* {data?.length < 3 && <button className='btnRequestID' >
          <img src="/icons/m-IconAddTag.svg" alt="" />
          <p>درخواست صدور شناسه واریز جدید</p>
        </button>} */}
      </div>
      {Array.from(data)?.sort((a: any, b: any) => a.sort - b.sort)?.map((i: any, n: number) =>
        <div key={n} className='generatedID'>
          <div className='item'>
            <p>وضعیت</p>
            <span className='dividerDash'></span>
            <p className='colorGreen'>فعال</p>
          </div>
          <div className='item'>
            <p>شماره شبا مقصد</p>
            <span className='dividerDash'></span>
            <div className='imgOpatrtor'>
              {/* <QrCode2Icon className='cPointer' onClick={(e: any) => handleClick(e, bankAcoount[i?.bank]?.shaba)} /> */}
              <Tooltip title={eleToolTip(bankAcoount[i?.bank]?.shaba)}>
                <QrCode2Icon className='cPointer' />
              </Tooltip>
              <CopyToClipboard text={bankAcoount[i?.bank]?.shaba} onCopy={() => { bankAcoount[i?.bank]?.shaba && snackUse.showSaccess("شماره شبا کپی شد") }}>
                <span className='icon-IconCopy2'></span>
              </CopyToClipboard>
              <p>{bankAcoount[i?.bank]?.shaba}</p>
              <img src={`/icons/Icon-${i?.bank}.svg`} alt="" />
            </div>
          </div>
          <div className='item'>
            <p>شماره حساب</p>
            <span className='dividerDash'></span>
            <div className='imgOpatrtor'>
              {/* <QrCode2Icon className='cPointer' onClick={(e: any) => handleClick(e, bankAcoount[i?.bank]?.numberAccount)} /> */}
              <Tooltip title={eleToolTip(bankAcoount[i?.bank]?.numberAccount)}>
                <QrCode2Icon className='cPointer' />
              </Tooltip>
              <CopyToClipboard text={bankAcoount[i?.bank]?.numberAccount} onCopy={() => { bankAcoount[i?.bank]?.numberAccount && snackUse.showSaccess("شماره حساب کپی شد") }}>
                <span className='icon-IconCopy2'></span>
              </CopyToClipboard>
              <p>{bankAcoount[i?.bank]?.numberAccount}</p>
            </div>
          </div>
          <div className='item'>
            <p>شناسه پرداخت</p>
            <span className='dividerDash'></span>
            <div className='imgOpatrtor'>
              {/* <QrCode2Icon className='cPointer' onClick={(e: any) => handleClick(e, i?.code)} /> */}
              <Tooltip title={eleToolTip(i?.code)}>
                <QrCode2Icon className='cPointer' />
              </Tooltip>
              <CopyToClipboard text={i?.code} onCopy={() => { i?.code && snackUse.showSaccess("شناسه کپی شد") }}>
                <span className='icon-IconCopy2'></span>
              </CopyToClipboard>
              <p>{i?.code}</p>
            </div>
          </div>
          <div className='item'>
            <p>نام صاحب حساب</p>
            <span className='dividerDash'></span>
            <div className='imgOpatrtor'>
              <p>{bankAcoount[i?.bank]?.name}</p>
            </div>
          </div>
        </div>)}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {numberGenerated
          ? <div className='qrCodeGeneratedID'>
            <QRCode
              size={306}
              style={{ height: '73px', width: '73px' }}
              value={numberGenerated}
              viewBox={`0 0 73 73`}
            />
          </div>
          : ""}
      </Menu>
    </>
  )
}

export default GeneratedID