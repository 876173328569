import React, { useEffect, useState } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import "../../asset/css/style404.css"
import { NavLink, useNavigate } from 'react-router-dom'
import { menu } from '../../config/menu'
import { useDispatch, useSelector } from 'react-redux'
import * as dialog from '../../services/redux/dialog'
import NeedLogin from '../../components/NeedLogin'
import Footer from '../../components/Footer'

const Page404 = () => {
  const dispatch = useDispatch<any>()
  const [isReady, setIsReady] = useState(false);
  const userIsLogin = useSelector((state: any) => state.dataSaver.userIsLogin);
  const navigate = useNavigate()


  useEffect(() => {
    if (!userIsLogin) {
      setIsReady(false);
      dispatch(dialog.show(<NeedLogin submit={() => { dispatch(dialog.hide()) }} />))
      navigate(menu.order.childs.fastOrder.url);
    } else {
      setIsReady(true);
    }
  }, [userIsLogin])


  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          {isReady
            && <div id="notfound">
              <div className="notfound">
                <div className="notfound-404">
                  <h1>4<span>0</span>4</h1>
                </div>
                <p>صفحه ای که به دنبال آن هستید ممکن است وجود نداشته باشد یا به طور موقت در دسترس نباشد.</p>
                <NavLink to={menu.order.childs.fastOrder.url}>صفحه اصلی</NavLink>
              </div>
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Page404