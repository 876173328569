import { CircularProgress, FormControl, InputLabel, OutlinedInput } from '@mui/material'
import { IoArrowForwardOutline } from "react-icons/io5";
import LogoHeader from '../../components/LogoHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as BackdropShow from '../../services/redux/backdrop';
import gather from '../../helpers/gather';
import { config } from '../../config/config';
import { useTranslation } from 'react-i18next';
import { toEnglishDigits } from '../../helpers/tools';
import useSnack from '../../hooks/useSnack';
import { useNavigate } from 'react-router-dom';

const ForgetPass = ({ onSubmitLogin }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [mobile, setMobile] = useState('');
  const [errorMobile, setErrorMobile] = useState(0);
  const snackUse = useSnack();
  const navigati = useNavigate();
  const [myLoading, setMyLoading] = useState(false);

  const handleMobile = (e: any) => {
    setMobile(e.target.value)
    let newMobile = e.target.value.startsWith("0") ? e.target.value : '0' + e.target.value;
    if (newMobile.length === 11) {
      setErrorMobile(2);
    } else {
      setErrorMobile(0)
    }
  }

  const send = async () => {
    let newMobile = mobile.startsWith("0") ? mobile : '0' + mobile;
    if (newMobile.length !== 11) {
      setErrorMobile(1);
      setTimeout(() => { setErrorMobile(0) }, 3000);
      return
    }
    setErrorMobile(2);

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/auth/forgetPassword`).post({ mobile: toEnglishDigits(newMobile?.toString()) });

    if (result.message === 200) {
      onSubmitLogin("verify", toEnglishDigits(newMobile?.toString()))
    } else {
      if (result.message == "404") {
        snackUse.showError("حساب کاربری یافت نشد");
      } else {
        snackUse.showError(t(result.message) || t("99999"));
      }
    }

    setMyLoading(false);
  }

  return (
    <div className="loginPage">
      <div className=" login">
        <LogoHeader />
        <div className="loginBody">
          <div className="loginBack" onClick={() => navigati("/login")} >
            <IoArrowForwardOutline size={20} />
          </div>
          <div className="upTitle">
            <h5>فراموشی رمز</h5>
            <p> اکسنوین</p>
          </div>
          <div className="textCenter">
          </div>
          <div className="inputs">
            <FormControl className={`formControlLogin ${errorMobile === 1 ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>شماره همراه</InputLabel>
              <OutlinedInput fullWidth label="شماره همراه" type='number'
                onChange={handleMobile}
                startAdornment={
                  <div className="iconInput cityCode">
                    <p>+98</p>
                    <img crossOrigin='anonymous' src="/icons/iranflag.svg" height="22px" alt="" />
                  </div>}
                endAdornment={<>
                  <img crossOrigin='anonymous' className={`doneit ${errorMobile === 2 ? "" : "none"}`} src="/icons/donelogin.svg" alt="" />
                  <img crossOrigin='anonymous' className={`errorit ${errorMobile === 1 ? "" : "none"}`} src="/icons/loginerror.svg" alt="" /></>} />
            </FormControl>
            <div className={`errorText ${errorMobile === 1 ? "opacity1" : ""}`}>
              <span>شماره همراه خود را بازبینی کنید</span>
            </div>
            {
              myLoading
                ? <div className="loadingCircular"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
                :
                <button className="finalCheckBtn" onClick={send}>دریافت کد   </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPass