import React from 'react'
import { FiMenu } from 'react-icons/fi'
import Navbar from '../navbar/Navbar'
import * as DrawerState from '../../services/redux/drawer';
import { useDispatch } from 'react-redux';

const SandwichMenu = () => {
  const dispatch = useDispatch<any>();
  return (
    <div className="btnMenuDrawer">
      <FiMenu size={33} onClick={() => dispatch(DrawerState.showMediumR(
        <div className='menuDrawerShow'><Navbar close={() => dispatch(DrawerState.hide())} /></div>
      ))} />
    </div>
  )
}

export default SandwichMenu