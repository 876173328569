import { createSlice } from "@reduxjs/toolkit"
export interface CounterState {
  open: boolean,
  content: any,
  size: object,
  anchor: string
}

const initialState: CounterState = {
  open: false,
  content: null,
  size: { width: "417px" },
  anchor: "left"
}

export const DrawerState = createSlice({
  name: "drawerState",
  initialState,
  reducers: {
    show: (state, action) => {
      state.open = true;
      state.content = action.payload.content;
      state.anchor = action.payload.anchor;
      state.size = action.payload.size;
    },
    hide: (state) => {
      state.open = false;
    },
    showSmallR: (state, action) => {
      state.open = true;
      state.content = action.payload;
      state.anchor = "right";
      state.size = { width: "240px" };
    },
    showMediumR: (state, action) => {
      state.open = true;
      state.content = action.payload;
      state.anchor = "right";
      state.size = { width: "300px" };
    },
    showMediumL: (state, action) => {
      state.open = true;
      state.content = action.payload;
      state.anchor = "left";
      state.size = { width: "430px" };
    },
  }
})

export const { show, hide, showSmallR, showMediumR, showMediumL } = DrawerState.actions;
export default DrawerState.reducer
