import { CircularProgress, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { config } from '../../../config/config'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch } from 'react-redux'
import gather from '../../../helpers/gather'
import { useTranslation } from 'react-i18next'
import moment from 'jalali-moment'
import { statusFA } from '../../../config/variable'
import _ from 'lodash'
import { findDateQuery, zeroFormat } from '../../../helpers/tools'
import * as DialogShow from '../../../services/redux/dialog'
import { CgChevronLeftR } from 'react-icons/cg'
import { NavLink } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import DetailWDCoin from '../dialogs/DetailWDCoin'
import useSnack from '../../../hooks/useSnack'
import FetchListLoad from '../../../components/FetchListLoad'

const History = ({ reload, flag, all = false, fID = '' }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [loadHistory, setLoadHistory] = useState(true);
  const [listHistory, setListHistory] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [from, _setFrom] = useState(Math.floor(moment().subtract(30, 'd').valueOf()));
  const [to, _setTo] = useState(Math.floor(moment().add(10, 'm').valueOf()));

  const get = async () => {
    setLoadHistory(true);

    const result = flag
      ? await gather(`${config.api}/v1/deposit?sortBy=createdAt&orderBy=desc&page=${pageCurrent}&fromDate=${from}&toDate=${to}${findDateQuery({ coin: fID }, false, true)}`).get()
      : await gather(`${config.api}/v1/withdraw?sortBy=createdAt&orderBy=desc&page=${pageCurrent}&fromDate=${from}&toDate=${to}${findDateQuery({ coin: fID }, false, true)}`).get();//asc

    if (result.message === 200) {
      setListHistory(result?.data ?? []);
      setPageCount(result?.data?.pages?.count ?? 1);
      setPageCurrent(result?.data?.pages?.current ?? 1);
    } else {
      // snackUse.showError(t(result.message) || t("99999"));
    }

    setLoadHistory(false);
  }

  useEffect(() => {
    get();
  }, [reload, fID, pageCurrent])

  return (
    <div className={`historyBoxAll ${all && "allHistoryStyle"}`}>
      <div className='tb_opratorBox'>
        <h4>آخرین {flag ? "واریز " : " برداشت"} های  ارزی</h4>
        <div className='refreshAndInfo'>
          {/* <div className='info'>
            <p>هنوز برداشت نشده؟</p>
            <span className='icon-IconInfoCircle'></span>
          </div> */}
          <button className='refresh' onClick={() => get()}>
            <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconRefresh.svg" alt="" />
            <p>بروزرسانی</p>
          </button>
        </div>
      </div>
      <div className='tb_View tb_Send'>
        <table>
          <thead>
            <tr>
              <th><h5>شناسه درخواست</h5></th>
              <th><h5>تاریخ و زمان</h5></th>
              <th><h5>رمز ارز</h5></th>
              <th><h5>مقدار</h5></th>
              <th><h5>شبکه</h5></th>
              <th><h5>کیف پول</h5></th>
              <th><h5>وضعیت</h5></th>
              <th><h5>هش تراکنش (TxID)</h5></th>
              <th><h5></h5></th>
            </tr >
          </thead >
          <tbody className={"relative"}>
            {(!loadHistory && !_.isEmpty(listHistory))
              && Array.from(listHistory)?.map((i: any, n: any) =>
                <tr key={n} className="box">
                  <td>{i?.uniqueId}</td>
                  <td> <div className='time'>{moment(i?.createdAt).locale("fa").format('HH:mm | YYYY-MM-DD')}</div></td>
                  <td>
                    <div className='name right'>
                      <LazyLoadImage crossOrigin='anonymous' className='coin' src={`${config.imgUrl}${i?.coin?.symbol?.toUpperCase()}.png`} alt="" />
                      <p>{i?.coin?.nameFa ?? "---"}</p>
                    </div>
                  </td>
                  <td>{zeroFormat(i?.amount)}&nbsp; <b>{i?.coin?.symbol?.toUpperCase()}</b></td>
                  <td>{i?.network?.name ?? "- - -"}</td>
                  <td>
                    {(flag ? i?.sender : i?.receiver) &&
                      <div className='name'>
                        <CopyToClipboard text={flag ? i.sender : i?.receiver} onCopy={() => { (flag ? i.sender : i?.receiver) && snackUse.showSaccess("آدرس کپی شد") }}>
                          <span className='icon-IconCopy3 cPointer'></span>
                        </CopyToClipboard>
                        {flag
                          ? <p className='ltr'>{i.sender?.length > 18 ? i.sender?.slice(0, 6) + ". . ." + i.sender?.slice(-6, i.sender?.length) : i.sender}</p>
                          : <p className='ltr'>{i.receiver?.length > 18 ? i.receiver?.slice(0, 6) + ". . ." + i.receiver?.slice(-6, i.receiver?.length) : i.receiver}</p>
                        }
                      </div>}
                  </td>
                  <td><span className={`status ${i?.status}`}>{statusFA[i?.status]}</span></td>
                  <td>
                    {i?.hash &&
                      <div className='name'>
                        <NavLink to={i?.network?.scanner + "/" + i?.hash} target="_blank" ><span className='icon-IconLink2 cPointer'></span></NavLink>
                        <CopyToClipboard text={i?.hash} onCopy={() => { i?.hash && snackUse.showSaccess("هش تراکنش کپی شد") }}>
                          <span className='icon-IconCopy3 cPointer'></span>
                        </CopyToClipboard>
                        <p className='ltr'>{i?.hash?.length > 18 ? i?.hash?.slice(0, 6) + ". . ." + i?.hash?.slice(-6, i?.hash?.length) : i?.hash}</p>
                      </div>}
                  </td>
                  <td className='cPointer' onClick={() => dispatch(DialogShow.show(<DetailWDCoin data={i} deposit={flag} close={() => dispatch(DialogShow.hide())} />))}>
                    <CgChevronLeftR size={25} />
                  </td>
                </tr>)}
          </tbody>
        </table >
        <FetchListLoad list={listHistory} load={loadHistory} />
      </div >
      <div className='paging'>
        <Pagination color="primary" page={pageCurrent} count={pageCount === 0 ? 1 : pageCount} onChange={(_e: any, v: any) => setPageCurrent(v)} />
      </div>
    </div>
  )
}

export default History;

