import React, { useEffect, useRef, useState } from 'react'

const ItemQuestion = ({ title, des }: any) => {
  const refShowFull: any = useRef(null);
  const refLongText: any = useRef(null);
  const [showText, setShowText] = useState(false);


  useEffect(() => {
    const heightLongText = refLongText?.current?.getBoundingClientRect().height;
    if (showText) {
      refShowFull.current.style.height = `${heightLongText}px`;
    } else {
      refShowFull.current.style.height = "0px";
    }
  }, [showText])
  return (
    <div className={`item cPointer ${showText && "show"}`}>
      <div className='textAccord' onClick={() => setShowText(!showText)}>
        <h4>{title}</h4>
        <div className='detail' ref={refShowFull}>
          <h5 ref={refLongText}>{des}</h5>
        </div>
      </div>
      <div className={`selectIcon ${showText && "show"}`} onClick={() => setShowText(!showText)}>
        <span className={`icon_Onlyplus`} ></span>
      </div>
    </div>
  )
}

export default ItemQuestion