import { createSlice } from "@reduxjs/toolkit"
import { snackbarEnum } from "../../enums/enumApp";

const stateSnackbar: { [key: string]: any } = {
  open: false,
  text: "XXXXXXX",
  severity: "warning"
}

export const SnackbarShow = createSlice({
  name: "snackbarState",
  initialState: stateSnackbar,
  reducers: {
    warning: (state, action) => {
      state.open = true;
      state.text = action.payload
      state.severity = snackbarEnum.warning
    },
    success: (state, action) => {
      state.open = true;
      state.text = action.payload
      state.severity = snackbarEnum.success
    },
    hide: (state) => {
      state.open = false
    }
  }
})


export const { hide, warning, success } = SnackbarShow.actions;
export default SnackbarShow.reducer;
