import LogoHeader from '../../../components/LogoHeader';
import { menu } from '../../../config/menu';

const FaildUpgrade = ({ text, close }: { text: string, close: any }) => {

  return (
    <div className="confirmUpgrade">
      <img crossOrigin='anonymous' src="/icons/m-IconDangerRed.svg" alt="" />
      <h4>خطا در ارسال مدارک</h4>
      <p>{text}</p>
      <button className="finalCheckBtn" onClick={close}> تایید </button>
    </div>)
}

export default FaildUpgrade