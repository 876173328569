import React, { memo, useEffect, useState } from 'react'
import CustomSelectBox2Small from '../../../../components/CustomSelectBox2Small'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { zeroFormat } from '../../../../helpers/tools';
import DepthApi from './DepthApi';
import { menu } from '../../../../config/menu';
import useTicker from '../../../../hooks/useTicker';
import _ from 'lodash';
import { CircularProgress, Skeleton, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
let oldPrice = 0;

const OrderBook = ({ typePrice, selectCoin, pair, setPriceB, setPriceS, setAmountB, setAmountS, setTotalS, setTotalB }:
  { typePrice: any, selectCoin: any, pair: any, setPriceB: any, setPriceS: any, setAmountB: any, setAmountS: any, setTotalS: any, setTotalB: any }) => {
  const ticker = useTicker();
  const [statusShowOrderBook, setStatusShowOrderBook] = useState(2);
  const [up, setUp] = useState(true);
  const coinPrice = ticker.price(selectCoin?.pairSymbol);
  const orderBData = useSelector((state: any) => state.orderBook?.orderBook);

  // console.log(orderBData);


  useEffect(() => {
    if (coinPrice > oldPrice) {
      setUp(true);
    } else {
      setUp(false);
    }

    oldPrice = coinPrice;
  }, [coinPrice]);


  const handleTitleBar = () => {
    let title = `${zeroFormat(ticker.price(selectCoin?.pairSymbol))}`
      + " " + (selectCoin?.myPairFa ?? " -- ")
      + " | " + " " + (selectCoin?.nameFa ?? " -- ")
      + " " + menu.order.childs.orederPro.title;

    document.title = title
  }

  useEffect(() => {
    handleTitleBar()
  }, [selectCoin, ticker]);


  return (

    <div className='openOrderNow'>
      <div className='opratorControl'>
        <CustomSelectBox2Small className={"selectBox"} element={<p className='seletcing'>20</p>} />
        <div className='boxImgs'>
          <LazyLoadImage onClick={() => setStatusShowOrderBook(0)} className={`itemImg ${statusShowOrderBook == 0 && "active"}`} src="/icons/m-IconOnlyBuy.svg" alt="" />
          <LazyLoadImage onClick={() => setStatusShowOrderBook(1)} className={`itemImg ${statusShowOrderBook == 1 && "active"}`} src="/icons/m-IconOnlySell.svg" alt="" />
          <LazyLoadImage onClick={() => setStatusShowOrderBook(2)} className={`itemImg ${statusShowOrderBook == 2 && "active"}`} src="/icons/m-IconBuySell.svg" alt="" />
        </div>
      </div>
      <div className='tbOpenOrderList'>
        <div className='head m-0'>
          <div className='tr'>
            <div className='th'><p>قیمت ({selectCoin?.myPair.toUpperCase()})</p></div>
            <div className='th'><p>مقدار ({selectCoin?.symbol.toUpperCase()})</p></div>
            <div className='th'><p>قیمت کل ({selectCoin?.myPair.toUpperCase()})</p></div>
          </div>
        </div>
        {statusShowOrderBook != 0
          && <div className='body'>
            {_.isEmpty(orderBData)
              ? <div className='loadingBook'>
                <Stack spacing={0} style={{ width: "100%" }}>
                  {new Array(10)?.fill("a").map((i, n) =>
                    <Skeleton key={n} variant="text" sx={{ fontSize: '2rem' }} />
                  )}
                </Stack>
              </div>
              :
              <DepthApi bgGreen={false} typePrice={typePrice}
                setTotalS={setTotalS} setTotalB={setTotalB}
                setAmountS={setAmountS} setAmountB={setAmountB}
                setPriceB={setPriceB} setPriceS={setPriceS}
                orderBookData={[...orderBData?.sell]?.slice(0, 19)} precision={selectCoin?.pair[pair]?.precision} />
            }
          </div>}
      </div>
      <div className={`priceNow ${statusShowOrderBook == 0 && "order-1"}`}>
        <h4>1 {selectCoin?.symbol.toUpperCase()} =</h4>
        <div>
          <p className={` cPointer ${up ? "colorGrrn" : "colorRed"}`} onClick={() => { setPriceB(coinPrice); setPriceS(coinPrice); }}>{zeroFormat(coinPrice, selectCoin?.pair[pair]?.precision, false)} {pair?.toUpperCase()}</p>
          <span className={`${up ? "iconArrowUpGreen" : "iconArrowDownRed"}`}></span>
        </div>
      </div>
      {statusShowOrderBook != 1 &&
        <div className='tbOpenOrderList'>
          <div className='body columnReverse'>
            {_.isEmpty(orderBData)
              ? <div className='loadingBook'>
                <Stack spacing={0} style={{ width: "100%" }}>
                  {new Array(10)?.fill("a").map((i, n) =>
                    <Skeleton key={n} variant="text" sx={{ fontSize: '2rem' }} />
                  )}
                </Stack>
              </div>
              :
              <DepthApi bgGreen={true} typePrice={typePrice}
                setTotalS={setTotalS} setTotalB={setTotalB}
                setAmountS={setAmountS} setAmountB={setAmountB}
                setPriceB={setPriceB} setPriceS={setPriceS}
                orderBookData={[...orderBData?.buy]?.slice(0, 19)} precision={selectCoin?.pair[pair]?.precision} />
            }
          </div>
        </div>}
    </div>
  )
}

export default memo(OrderBook)