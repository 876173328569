import { DialogContent, Divider } from '@mui/material'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { orderStatusFA, sideFA, typeOrder2FA } from '../../../config/variable'
import moment from 'jalali-moment'
import { zeroFormat } from '../../../helpers/tools'
import { config } from '../../../config/config'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useSnack from '../../../hooks/useSnack'

const DetailOrders = ({ data, close }: any) => {
  const snackUse = useSnack();

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw'>
        <div className='body'>
          <div className='dec'>
            <p>شناسه درخواست</p>
            <div className='shaba'>
              <CopyToClipboard text={data?._id} onCopy={() => { data?._id && snackUse.showSaccess("آدرس کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?._id}</p>
            </div>
          </div>
          <div className='dec'>
            <p>شماره سفارش</p>
            <div className='shaba'>
              <CopyToClipboard text={data?.updatedAt} onCopy={() => { data?.updatedAt && snackUse.showSaccess("شماره سفارش کپی شد") }}>
                <span className='icon-IconCopy3 cPointer'></span>
              </CopyToClipboard>
              <p>{data?.updatedAt}</p>
            </div>
          </div>
          <div className='dec'>
            <p>وضعیت</p>
            <div className='status confirm'>
              <p id={`${data?.status}`}>{orderStatusFA[data?.status]}</p>
            </div>
          </div>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{moment(data?.updatedAt).locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
          </div>
          <div className='dec'>
            <p>نوع معامله</p>
            <p className='ltr'>{typeOrder2FA[data?.trade]}</p>
          </div>
          <div className='dec'>
            <p>سمت</p>
            <p className={`color-${data?.side}`}>{sideFA[data?.side]}</p>
          </div>
          <div className='dec'>
            <p>نوع ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
              <p>{data?.coin?.nameFa ?? "---"}</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار ارز</p>
            <p className='ltr'>{zeroFormat(data?.amount)} {data?.coin?.symbol?.toUpperCase() ?? "---"}</p>
          </div>
          <div className='dec'>
            <p>کارمزد</p>
            <p className='ltr'>{zeroFormat(data?.fee?.amount)} {data?.side=="buy"?data?.pair?.toUpperCase():data?.coin?.symbol?.toUpperCase()}</p>
          </div>
          {data?.stop &&
            <div className='dec'>
              <p>حد ضرر</p>
              <p className='ltr'>{zeroFormat(data?.stop)} {data?.pair?.toUpperCase()}</p>
            </div>}
          <div className='dec'>
            <p>قیمت</p>
            <p className='ltr'>{zeroFormat(data?.price) ?? 0} {data?.pair?.toUpperCase()}</p>
          </div>
          <div className='dec'>
            <p>مجموع {sideFA[data?.side]}</p>
            <p className='ltr'>{zeroFormat(data?.total) ?? 0} {data?.pair?.toUpperCase()}</p>
          </div>
        </div>
      </DialogContent>
    </>
  )
}

export default DetailOrders