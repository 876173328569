import React from 'react'
import { NavLink } from 'react-router-dom'
import { menu } from '../../../config/menu'

const DepositConfirm = ({ success, message, close, txt, hash }: any) => {
  return (
    <div className='activeGA-st2 activeGA-st3 p-10'>
      <div className='bodyConfirm'>
        <img className='imgSubmit' src={`/icons/${(message == "200" && success == "true") ? "iconSubmitAuth" : "m-IconSellRed"}.svg`} alt="" />
        <h4 className='txtDeposit'>{txt}</h4>
        {/* <h4>کدپیگیری</h4>
        <h4>{hash}</h4> */}
        {(message == "200" && success == "true")
          ? <h5>واریز با موفقیت انجام شد</h5>
          : <h5>واریز انجام نشد</h5>}
        <NavLink to={menu.financial.childs.wallet.url} onClick={close} className={`submit ${(message == "200" && success == "true") ? "bgGreen" : "bgRed"}`}>بازگشت به کیف پول</NavLink>
      </div>
    </div>
  )
}

export default DepositConfirm