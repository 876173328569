const StepByStep = ({ current }: any) => {
  const list = ["ثبت مشخصات فردی", "افزودن حساب", "تکمیل"]

  return (
    <div className="stepByStep">
      <div className="stepsBody">
        {list.map((i: any, n: number) =>
          <div key={n} className={`steps ${n + 1 === current ? "current" : n + 1 < current ? "done" : ""} ${current == 3 && "done"}`}>
            <div className='row'>
              <div className="stepBorder">
                <div className="step">
                  <span className="stepcount">{n + 1}</span>
                  <img crossOrigin='anonymous' src="/icons/doneehraz.svg" alt="" />
                </div>
              </div>
              <p className="stepcap">
                {i}
              </p>
            </div>
            {n + 1 < list.length ? <div className="line"></div> : ""}
          </div>
        )}
      </div>
    </div>
  )
}

export default StepByStep