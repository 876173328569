import React from 'react'
import VerificationInput from 'react-verification-input'

const ActiveGAConfirm = ({ submit }: any) => {
  return (

    <div className='activeGA-st2 activeGA-st3'>
      <div className='warningTitle'>
        <h5><b>نکات</b> مهم</h5>
        <p>از حذف کردن اپلیکیشن Google Authenticator خودداری کرده و حتما از کد QR زیر تصویری تهیه کنید یا کد مقابل آن را ذخیره کنید تا در صورت در دسترس نبودن اپلیکیشن Google Authenticator امکان بازیابی کد دوعاملی را داشته باشید.</p>
      </div>
      <div className='bodyConfirm'>
        <img className='imgSubmit' src="/icons/m-IconSubmitGreen.svg" alt="" />
        <h5>شناسایی دوعاملی برای حساب شما<b> با موفقیت فعال شد</b>. از این پس برای ورود به اکسنوین، میبایست نرم‌افزار Google Authenticator را بر روی تلفن همراه خود نصب داشته باشید و کد ایجاد شده را وارد کنید.</h5>
        <button className='submit' onClick={() => submit(0)}>تایید</button>
      </div>

    </div>
  )
}

export default ActiveGAConfirm