import React from 'react'

const StepUpgrade = ({ step }: { step: any }) => {
  return (
    <div className='steps'>
      <div className={`item ${step >= 1 && "checkTick"}`}>
        <div className='img'>
          <img src={`/icons/m-${step >= 1 ? "IconCallingGreen" : "IconCalling"}.svg`} alt="" />
        </div>
        <p>تایید قوانین</p>
      </div>
      <img className='divderHorz' src={`/icons/m-${step >= 1 ? "IconDivderGreen" : "IconDivderGray"}.svg`} alt="" />
      {/* <div className={`item ${step >= 2 && "checkTick"}`}>
        <div className='img'><span className='icon-IConFingerScan'></span></div>
        <p>محل سکونت</p>
      </div>
      <img className='divderHorz' src={`/icons/m-${step >= 2 ? "IconDivderGreen" : "IconDivderGray"}.svg`} alt="" /> */}
      <div className={`item ${step >= 2 && "checkTick"}`}>
        <div className='img'><img src="/icons/m-IconSecurityUser.svg" alt="" /></div>
        <p>مدارک شناسایی</p>
      </div>
    </div>
  )
}

export default StepUpgrade