import VerificationInput from "react-verification-input";
import LogoHeader from '../../components/LogoHeader';
import { IoArrowForwardOutline } from 'react-icons/io5';
import { config } from "../../config/config";
import gather from "../../helpers/gather";
import { useState } from "react";
import _ from "lodash";
import { CircularProgress, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toEnglishDigits } from "../../helpers/tools";
import useSnack from "../../hooks/useSnack";
import { useNavigate } from "react-router-dom";

const VerifyForgetPass = ({ setChangePage, mobile }: { setChangePage: any, mobile: string }) => {
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [errorPass, setErrorPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [code, setCode] = useState('');
  const [colorPassText, setColorPassText] = useState("colorGray");
  const navigati = useNavigate();
  const [myLoading, setMyLoading] = useState(false);

  const handlePass = (e: any) => {
    if ((/[0-9]/.test(e.target.value) && /[A-Za-z]/.test(e.target.value) && (e.target.value.length >= 8))) {
      setColorPassText("colorGreen");
    } else {
      setColorPassText("colorGray");
    }
    setNewPassword(e.target.value);
  }

  const send = async () => {
    if (!(/[0-9]/.test(newPassword) && /[A-Za-z]/.test(newPassword) && (newPassword.length >= 8))) {
      setErrorPass(true);
      setColorPassText("colorRed");
      setTimeout(() => { setErrorPass(false); setColorPassText("colorGray"); }, 3000);
      return;
    }

    setMyLoading(true);

    const result = await gather(`${config.api}/v1/auth/forgetPassword/confirm`).post({
      mobile: toEnglishDigits(mobile?.toString()),
      code: toEnglishDigits(code?.toString()),
      newPassword: toEnglishDigits(newPassword?.toString())
    });

    if (result.code === 200) {
      navigati("/login")
      window.location.reload();
    } else {
      setCode("");
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <div className="loginPage">
      <div className=" login">
        <LogoHeader />
        <div className="loginBody">
          <div className="loginBack" onClick={() => setChangePage("forget")}>
            <IoArrowForwardOutline size={20} />
          </div>
          <div className="upTitle">
            <h5>تاییدیه پیامکی</h5>
            <p>کد فعالسازی برای شماره {mobile} پیامک شد</p>
          </div>
          <div className="inputs">
            <div className="text-right">
              <span className="textvr">کد فعالسازی</span>
            </div>
            <div className='codeNumberBox mb-15'>
              <VerificationInput
                // onComplete={(e) => send(e)}
                onChange={(e) => { Number(e) >= 0 && setCode(e) }}
                value={code}
                placeholder='-'
                classNames={{
                  container: "containerVerify",
                  character: "characterVerify",
                  characterFilled: "character--filled",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
            </div>
            <div className='errorText mt-24 mx-5 mb-15 showDes opacity1'>
              <span className={colorPassText}>رمز شما باید حداقل ۸ کاراکتر شامل حروف بزرگ و کوچک و اعداد باشد</span>
            </div>
            <FormControl className={`formControlLogin ${errorPass ? "redBorder" : ""}`} variant="outlined">
              <InputLabel>رمز عبور جدید</InputLabel>
              <OutlinedInput fullWidth={true} label="رمز عبور جدید" onChange={handlePass}
                type={showPassword ? 'text' : 'password'}
                startAdornment={<span className='icon-loginlock'></span>}
                endAdornment={<span className={`cPointer icon-IconEye${showPassword?"Close":"Open"}`}  onClick={() => setShowPassword(!showPassword)}  ></span>} />
            </FormControl>
            {
              myLoading
                ? <div className="loadingCircular mt-15"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
                :
                <button className="finalCheckBtn" onClick={send}> ثبت  </button>
            }
            {/* <div className={`progress-btn ${showTimer.show ? "active" : ""}`} data-progress-style="fill-back" onClick={reloadCode}>
              {showTimer.show &&
                <div className="btn">
                  <Countdown date={Date.now() + 60000} renderer={renderer}
                    onComplete={() => setShowTimer({ flag: 0, show: false })} />
                </div>}
              {!showTimer.show &&
                <h5 className="again">ارسال مجدد</h5>}
              <div className="progress"></div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VerifyForgetPass