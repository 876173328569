import React from 'react';

const MessageNotif = ({ notification }:any) => {
  return (
    <>
      <div id="notificationHeader">
        {notification?.image && (
          <div id="imageContainer">
            <img src={notification?.image} width={100} />
          </div>
        )}
        <span>{notification?.title??"---"}</span>
      </div>
      <div id="notificationBody">{notification?.body??"---"}</div>
    </>
  );
}

export default MessageNotif;
