import React, { useEffect, useRef, memo, useState } from 'react';
let tvScriptLoadingPromise;

const TradingView = ({ symbolCoin, className = "", hideTool = false, theme = "light" }) => {
  const onLoadScriptRef = useRef();

  const symbol = () => {
    const regex = /tmn/i;
    if (symbolCoin == "USDTTMN") return ("USDTUSD");

    return (symbolCoin.replace(regex, "USDT"));
  }

  useEffect(() => {
    const createWidget = () => {
      if (document.getElementById(`tradingview_10cc6${symbolCoin}`) && 'TradingView' in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: `${symbol()}`,
          interval: "60",
          timezone: "Etc/UTC",
          theme: theme,
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          withdateranges: false,
          hide_side_toolbar: hideTool,
          allow_symbol_change: hideTool,
          hide_top_toolbar: hideTool,
          calendar: false,
          // hide_legend: hideTool,
          // show_popup_button: true,
          // popup_width: "1000",
          // popup_height: "650",
          container_id: `tradingview_10cc6${symbolCoin}`
        });
      }
    }

    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = 'tradingview-widget-loading-script';
        script.src = 'https://s3.tradingview.com/tv.js';
        script.type = 'text/javascript';
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => onLoadScriptRef.current = null;
  }, [symbolCoin, theme]);

  return (
    <div className={`tradingview-widget-container chart  ${className}`}>
      <div className={`itemTradingView ${className}`} id={`tradingview_10cc6${symbolCoin}`} />
    </div>
  );
}

export default memo(TradingView);
