import { CircularProgress, DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import * as DialogShow from '../../../services/redux/dialog';
import { IoMdCloseCircle } from 'react-icons/io';
import AddEmail from './AddEmail';
import gather from '../../../helpers/gather';
import { config } from '../../../config/config';
import VerifyCodeEmailPhone from './VerifyCodeEmailPhone';
import useSnack from '../../../hooks/useSnack';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';


const QustionAddOrEditEmail = ({ value }: { value: any }) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const [myLoading, setMyLoading] = useState(false);

  const sendCodeToExistEmial = async () => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/email`).post({ "email": value })

    if (result.message === 200) {
      dispatch(DialogShow.show(<VerifyCodeEmailPhone flag={false} value={value} />))

    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <>
      <DialogContent className='dialogWarningTitel'>
        <div className='titleCloseDialog'>
          <div className='textWarning'>
            <span className='iconRed-identity'></span>
            <h4>ایمیل</h4>
          </div>
          <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
        </div>
      </DialogContent>
      <DialogContent className='dialogWarning'>
        <p>
          کاربر گرامی شما می توانید با ایمیل موجود  {value}  ادامه دهید و
          یا ایمیل جدید ثبت نمایید.
        </p>
        <div className='btns'>
          {
            myLoading
              ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#fff" }} /></div>
              : <button className='btOk' onClick={sendCodeToExistEmial}>ادامه</button>
          }
          <button onClick={() => !myLoading && dispatch(DialogShow.show(<AddEmail />))}>ثبت ایمیل جدید</button>
        </div>
      </DialogContent>
    </>
  )
}

export default QustionAddOrEditEmail;