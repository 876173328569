import React, { useState } from 'react'
import VerificationInput from 'react-verification-input'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { config } from '../../config/config';
import gather from '../../helpers/gather';
import { getProfile } from '../../services/redux/user';
import useSnack from '../../hooks/useSnack';
import { CircularProgress } from '@mui/material';

const ActiveGA3 = ({ submit }: any) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const snackUse = useSnack();
  const profile = useSelector((state: any) => state.user.profile);
  const [code, setCode] = useState('');
  const [myLoading, setMyLoading] = useState(false);

  const send = async (item: any) => {
    setMyLoading(true);

    const result = await gather(`${config.api}/v1/user/gAuth/confirm`).post({ "code": item });

    if (result.message === 200) {
      await sendApi();
    } else {
      snackUse.showError(t(result.message) || t("99999"));
      setMyLoading(false);
      setCode("");
    }

  }

  const sendApi = async () => {
    setMyLoading(true);

    const body: any = {
      "twoStep": "gAuth",
      "hideAsset": profile?.setting?.hideAsset
    }

    const result = await gather(`${config.api}/v1/user/setting`).post(body);

    if (result.message === 200) {
      await dispatch(getProfile());
      submit(4);
    } else {
      snackUse.showError(t(result.message) || t("99999"));
    }

    setMyLoading(false);
  }

  return (
    <div className='activeGA-st2 activeGA-st3'>
      <div className='warningTitle'>
        <h5><b>نکات</b> مهم</h5>
        <p>از حذف کردن اپلیکیشن Google Authenticator خودداری کرده و حتما از کد QR زیر تصویری تهیه کنید یا کد مقابل آن را ذخیره کنید تا در صورت در دسترس نبودن اپلیکیشن Google Authenticator امکان بازیابی کد دوعاملی را داشته باشید.</p>
      </div>
      <div className='bodyConfirm'>
        <img className='imgLock' src="/icons/m-IconLockBgBlue.svg" alt="" />
        <h5>جهت تایید صحت فرایند فعال‌سازی کد دوعاملی عدد 6 رقمی نمایش داده شده در اپلیکیشن Google Authenticator را وارد کنید.</h5>
        <div className='verCode'>
          <VerificationInput
            onComplete={(e) => send(e)}
            onChange={(e) => { Number(e) >= 0 && setCode(e) }}
            value={code}
            placeholder='-'
            inputProps={{ type: "tel" }}
            classNames={{
              container: "containerVerify",
              character: "characterVerify",
              characterFilled: "character--filled",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
            }}
          />
        </div>
        {
          myLoading
            ? <div className="loadingCircular"><CircularProgress size={25} style={{ color: "#fff" }} /></div>
            :
            <button className='submit' onClick={() => send(code)}>تایید</button>
        }
      </div>

    </div>
  )
}

export default ActiveGA3