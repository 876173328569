import { DialogContent } from '@mui/material';
import { useDispatch } from 'react-redux';
import { IoMdCloseCircle } from 'react-icons/io';
import * as DialogShow from '../../services/redux/dialog';


const Exit = ({ confirm }: { confirm: any }) => {
  const dispatch = useDispatch<any>();


  return (
    <>
      <DialogContent className='dialogWarningTitel'>
        <div className='titleCloseDialog'>
          <div className='textWarning'>
            <span className='iconRed-identity'></span>
            <h4>خروج از حساب کاربری</h4>
          </div>
          <IoMdCloseCircle className='close cPointer' size={24} onClick={() => dispatch(DialogShow.hide())} />
        </div>
      </DialogContent>
      <DialogContent className='dialogWarning'>
        <p>
          کاربر گرامی آیا از خروج حساب کاربری خود اطمینان دارید ؟
        </p>
        <div className='btns'>
          <button className='btOk' onClick={confirm}>خروج</button>
          <button onClick={() => dispatch(DialogShow.hide())}>لغو</button>
        </div>
      </DialogContent>
    </>
  )
}

export default Exit;