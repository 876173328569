import { CircularProgress, Skeleton, Stack } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const FetchListLoad = ({ load, list }: { load: any, list: any }) => {
  return (
    <div className='fetchListLoad'>
      {load
        ? <div className='emptyLoad'>
          {/* <CircularProgress style={{ color: "#2424da" }} />
          <p>درحال دریافت اطلاعات . . . </p> */}
          <Stack spacing={1} style={{ width: "100%" }}>
            {new Array(5)?.fill("a").map((i, n) =>
              <Skeleton key={n} variant="text" sx={{ fontSize: '2rem' }} />
            )}
          </Stack>
        </div>
        : _.isEmpty(list)
        && <div className='emptyLoad'>
          <LazyLoadImage crossOrigin='anonymous' src="/icons/m-IconNotingList2.svg" alt="" />
          <p>موردی یافت نشد</p>
        </div>
      }
    </div>

  )
}

export default FetchListLoad