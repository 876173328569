import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import gather from '../../helpers/gather';
import { config } from '../../config/config';

const initialState = {
  tickers: {} as any,
  tickersApi: {} as any,
};

const getTickers = createAsyncThunk('price/fetchPrice', async () => {
  return await gather(config.api + '/v1/ticker').get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateTicker: (state, action) => {
      if (action.payload?.length > 0) {
        action.payload?.forEach((element: any) => {
          state.tickers[element.coin + element.pair] = element;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTickers.fulfilled, (state, action) => {
      if (action.payload.code === 200) {
        action.payload.data.forEach((value: any) => {
          state.tickers[value.coin + value.pair] = value;
          state.tickersApi[value.coin + value.pair] = value;
        });
      }
    });
  },
});

export { getTickers };
export const { updateTicker } = slice.actions;
export default slice.reducer;
