import React from 'react'
import { useDispatch } from 'react-redux';
import * as DialogShow from '../../../services/redux/dialog';
import OtherCoins from './otherCoins/OtherCoins';
import { config } from '../../../config/config';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CoinsBox = ({ listCoin, firstList, handleSelectCoin, setOrderChange, selectCoin, setSelectCoin }: any) => {
  const dispatch = useDispatch<any>();

  return (
    <div className={`coinsBox ${Object.keys(firstList).length > 3 && "respons"}`}>
      {Object.keys(firstList)?.map((i: any, n: number) =>
        <div key={n} className={`item ${selectCoin?.symbol === firstList[i]?.symbol && "active"}`}
          onClick={() => setSelectCoin(firstList[i])}>
          <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${firstList[i]?.symbol?.toUpperCase()}.png`} alt="" />
          <h4>{firstList[i]?.name} <b>{firstList[i]?.symbol?.toUpperCase()}</b></h4>
          <h4>{firstList[i]?.nameFa ?? "- - -"}</h4>
        </div>
      )}
      {Object.keys(listCoin).length > 4
        && <div className="other" onClick={() => dispatch(DialogShow.show(<OtherCoins ListCoin={listCoin}
          close={() => dispatch(DialogShow.hide())}
          selectCoin={handleSelectCoin} selectOrder={setOrderChange} />))}>
          <h5>سایر ارزها</h5>
          <span className='icon-ArrowLeft'></span>
        </div>}
    </div>
  )
}

export default CoinsBox