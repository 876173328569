const MoblieStep = ({ classNames, step }: any) => {
  return (
    <div className={`mobileSteps ${classNames}`}>
      <svg className="radial-progress" data-percentage="82" viewBox="0 0 80 80">
        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
        <circle className={`complete step-${step}`} cx="40" cy="40" r="35"></circle>
        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">{step}/3</text>
      </svg>
    </div>
  )
}

export default MoblieStep