import { DialogContent, Divider } from '@mui/material'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { sideFA, statusFA } from '../../../config/variable'
import moment from 'jalali-moment'
import { zeroFormat } from '../../../helpers/tools'
import { IoMdCloseCircle } from 'react-icons/io'
import { config } from '../../../config/config'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const CheckVoucher = ({ data, close, myData }: any) => {
  const dispatch = useDispatch<any>();

  return (
    <>
      <DialogContent className='detailWithdrawLogoBox newStyle'>
        <LazyLoadImage crossOrigin='anonymous' src={`/images/logo/svg/Exnovin-logo.svg`} alt="" />
        <span className='iconClose' onClick={close}></span>
      </DialogContent>
      <DialogContent className='detailWithdraw minWidth_450'>
        <div className='body'>
          <div className='dec'>
            <p>نوع ارز</p>
            <div className='typeCoin'>
              <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${myData?.coin?.symbol?.toUpperCase()}.png`} alt="" />
              <p>{myData?.coin?.nameFa ?? myData?.coin?.symbol?.toUpperCase()}</p>
            </div>
          </div>
          <div className='dec'>
            <p>مقدار</p>
            <p className='ltr'>{zeroFormat(data?.amount)}&nbsp;{myData?.coin?.symbol}</p>
          </div>
          <div className='dec'>
            <p>کیف پول مقصد</p>
            <p className='ltr'>{data?.receiver ?? "---"}</p>
          </div>
          <div className='dec'>
            <p>بچ نامبر</p>
            <p className='ltr'>{data?.reference ?? "---"}</p>
          </div>
          <div className='dec'>
            <p>وضعیت</p>
            <p className={`ltr ${(data?.receiver && data?.dateActive) ? "colorGreen" : "colorRed"}`}>{(data?.receiver && data?.dateActive) ? "استفاده شده" : "استفاده نشده"}</p>
          </div>
          <div className='dec'>
            <p>تاریخ</p>
            <p>{data?.dateActive ? moment(data?.dateActive).locale("fa").subtract(2, 'hour').format('HH:mm  YYYY-MM-DD') : "---"}</p>
          </div>
          <button className="finalCheckBtn" onClick={close}>تایید</button>
        </div>
      </DialogContent>
    </>
  )
}

export default CheckVoucher