import Footer from '../../components/Footer';
import Header from '../../components/header/Header';
import { Outlet } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import { useEffect } from 'react';
import { menu } from '../../config/menu';

const Ticket = () => {

  useEffect(() => {
    document.title = menu.ticket.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Ticket