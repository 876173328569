import { Checkbox, DialogContent } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { config } from '../../../config/config';
import { autoFormatter, zeroFormat } from '../../../helpers/tools';
import moment from 'jalali-moment';
import { sideFA, typeOrder2FA } from '../../../config/variable';
import { useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useDispatch } from 'react-redux';
import * as BackdropShow from '../../../services/redux/backdrop';

const ConfirmBuySell = ({ data, send, close }: { data: any, send: any, close: any }) => {
  const dispatch = useDispatch<any>();
  const [checked, setChecked] = useState(false);
  const [confiemOrder, setConfiemOrder] = useLocalStorage('confiemOrderPro', "false");

  const hideDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setConfiemOrder(event.target.checked + "")
  }

  return (
    <DialogContent className='detailWithdraw'>
      <div className='body'>
        <div className='dec'>
          <p>نوع سفارش</p>
          <p className={data?.side == "buy" ? "colorGreen" : "colorRed"}>{sideFA[data?.side]}</p>
        </div>
        <div className='dec'>
          <p> نحوه سفارش</p>
          <p >{typeOrder2FA[data?.trade]}</p>
        </div>
        <div className='dec'>
          <p>تاریخ</p>
          <p>{moment().locale("fa").format('HH:mm  YYYY-MM-DD')}</p>
        </div>
        <div className='dec'>
          <p>رمز ارز</p>
          <div className='typeCoin'>
            <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${data?.coin?.symbol?.toUpperCase()}.png`} alt="" />
            <p className='ltr'>{data?.coin?.symbol?.toUpperCase() ?? "تومان"}</p>
          </div>
        </div>
        {data?.stop &&
          <div className='dec'>
            <p>قیمت توقف</p>
            <p className='ltr'>{zeroFormat(data?.stop)}</p>
          </div>}
        <div className='dec'>
          <p>مقدار</p>
          <p className='ltr'>{zeroFormat(data?.amount)}&nbsp;{data?.coin?.symbol?.toUpperCase()}</p>
        </div>
        <div className='dec'>
          <p>قیمت</p>
          <p className='ltr'>{zeroFormat(data?.price)}</p>
        </div>
        <div className='dec'>
          <p>کارمزد</p>
          <p >{zeroFormat(data?.fee)} درصد</p>
        </div>
        <div className='dec'>
          <p>مبلغ نهایی</p>
          <p >{autoFormatter(data?.total)} تومان</p>
        </div>
        <div className='divider'></div>
        <div className='checkBoxDialog'>
          <Checkbox id='checkBox' checked={checked} onChange={hideDialog} sx={{
            color: "var(--color-black5)",
          }} />
          <label htmlFor='checkBox'>این تاییدیه را در معاملات بعد نشان نده</label>
        </div>
        <div className='btns'>
          <button className="finalCheckBtn" onClick={() => { dispatch(BackdropShow.show()); send(); }}  > تایید  </button>
          <button className="finalCheckBtn btnCancel2" onClick={() => close()} > لغو  </button>
        </div>
      </div>
    </DialogContent>
  )
}

export default ConfirmBuySell