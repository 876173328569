import { useSelector } from 'react-redux';

const useBalanceFix = () => {
  const listCoins = useSelector((state: any) => state.publicApis?.listCoinObjAll);
  const ticker = useSelector((state: any) => state.ticker?.tickers);
  const user = useSelector((state: any) => state.user);

  const handleUsdtTmn = (symbol: any, pair: any, obj: any) => {
    if (symbol?.toLowerCase() === pair) return user.balance.asset?.[symbol]?.[obj]
    if (user.balance.asset?.[symbol]?.[obj]) {
      return Number((user.balance.asset?.[symbol]?.[obj] ?? 0) * (ticker[symbol + pair]?.close ?? 0))
    } else {
      return 0
    }

  }

  const balance = {
    name: 'Toman',
    nameFa: 'تومان',
    symbol: 'tmn',
    icon: './images/tmn.png',
    mySort: 2,
    balance: user.balance.fiat.free,
    amount: user.balance.fiat.amount,
    blocked: user.balance.fiat.blocked,
    value: {
      usdt: Number(user.balance.fiat.amount / ticker["usdttmn"]?.close) ? Number(user.balance.fiat.amount / ticker["usdttmn"]?.close) : 0,
      tmn: user.balance.fiat.amount ?? 0,
      balanceTmn: user.balance.fiat.free ?? 0,
      balanceUsdt: Number(user.balance.fiat.free / ticker["usdttmn"]?.close) ? Number(user.balance.fiat.free / ticker["usdttmn"]?.close) : 0,
      blockedTmn: user.balance.fiat.blocked ?? 0,
      blockedUsdt: Number(user.balance.fiat.blocked / ticker["usdttmn"]?.close) ? Number(user.balance.fiat.blocked / ticker["usdttmn"]?.close) : 0,
    },
  };

  const coins = Object.values(listCoins).map((e: any) => {
    return {
      name: e.name,
      nameFa: e.nameFa,
      symbol: e.symbol,
      icon: e.icon,
      mySort: 1,
      balance: user.balance.asset[e.symbol]?.free || 0,
      amount: user.balance.asset[e.symbol]?.amount || 0,
      blocked: user.balance.asset[e.symbol]?.blocked || 0,
      value: {
        // usdt: Number((user.balance.asset[e.symbol]?.free || 0) * ticker[e.symbol + 'usdt']?.close) ?? 0,
        usdt: handleUsdtTmn(e.symbol, "usdt", "amount"),
        tmn: handleUsdtTmn(e.symbol, "tmn", "amount"),
        balanceTmn: handleUsdtTmn(e.symbol, "tmn", "free"),
        balanceUsdt: handleUsdtTmn(e.symbol, "usdt", "free"),
        blockedTmn: handleUsdtTmn(e.symbol, "tmn", "blocked"),
        blockedUsdt: handleUsdtTmn(e.symbol, "usdt", "blocked"),
        // tmn: Number((user.balance.asset[e.symbol]?.free || 0) * ticker[e.symbol + 'tmn']?.close) ?? 0,

      },
    };
  });

  coins.push(balance);

  return coins.reduce((a, v) => ({ ...a, [v.symbol]: v }), {}) as { [key: string]: any; };
};

export default useBalanceFix;
