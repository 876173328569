import { LazyLoadImage } from "react-lazy-load-image-component"
import { config } from "../config/config"

const ListCoinPopup = ({ className, listData, onClick }: any) => {

  return (
    <div className={`listCoinPopup ${className}`}>
      {Object.keys(listData)?.map((i: any, n: any) =>
        <div key={n} className="item" onClick={() => onClick(listData[i])}>
          <div className="name">
            <LazyLoadImage crossOrigin='anonymous' src={`${config.imgUrl}${i?.toUpperCase()}.png`} alt="" />
            <p>{listData[i]?.nameFa}</p>
            <p>{i?.toUpperCase()}</p>
          </div>
          <p>{listData[i]?.name}</p>
        </div>
      )}
    </div>
  )
}

export default ListCoinPopup