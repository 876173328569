import { IoMdCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { menu } from "../../config/menu";
import { useNavigate } from "react-router-dom";
import * as MenuState from "../../services/redux/menu";
import ItemNotifcation from "./ItemNotifcation";
import * as PublicApis from "../../services/redux/publicApis";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

const Notifcation = ({ flag = true, close }: any) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const alarmApi: any = useSelector((state: any) => state.publicApis?.alarmApi);
  const alarmNumUnread: any = useSelector((state: any) => state.publicApis?.alarmNumUnread);
  const [myLoading, setMyLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(PublicApis.alarmRead());
  // }, [])

  const handleClick = async () => {
    setMyLoading(true);
    await dispatch(PublicApis.alarmRead());
    dispatch(PublicApis.setZeroCountAlarm());
    dispatch(PublicApis.alarm());
    setMyLoading(false);
  }

  return (
    <div className={`notifPopup ${flag ? "small" : "large"}`}>
      <div className="title">
        {flag ?
          <div className="btns">
            {/* <span>{alarmApi?.length || 0}</span> */}
            {
              myLoading
                ? <div className="loadingCircular"><CircularProgress size={28} style={{ color: "#2424da" }} /></div>
                : <button className={alarmNumUnread == 0 ? "disable" : ""} onClick={() => { alarmNumUnread > 0 && handleClick() }}>خواندن همه</button>
            }
          </div>
          : <IoMdCloseCircle className='close' size={20} onClick={close} />}
        <div>
          <p>اطلاعیه ها</p>
          <div className=" ringdrop">
            <span className="icon-ring"></span>
          </div>
        </div>
      </div>
      {
        Array.from(alarmApi)?.slice(0, 4)?.map((i: any, n: number) =>
          <ItemNotifcation key={n} data={i} />)
      }
      <div className="btnAll" onClick={() => { navigate(menu.notifications.url); dispatch(MenuState.hide()) }}>
        <p>نمایش همه</p>
        <span className="icon-showall"></span>
      </div>
    </div >
  )
}

export default Notifcation