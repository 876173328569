import React from 'react'
import { NavLink } from 'react-router-dom'
import { socialLink } from '../../config/variable'

const ActiveGA1 = ({ submit }: any) => {
  return (
    <div className='activeGA-st1'>
      <p>برای فعال‌سازی کد دوعاملی اپلیکیشن Google Authenticator را دانلود و نصب کنید</p>
      <div className='listApps'>
        <NavLink target="_blank" to={socialLink.bazarAuth} className='imgApp'><img src="/icons/m-DownloadBazar.svg" alt="" /></NavLink>
        <NavLink target="_blank" to={socialLink.googlePlayAuthAndroid} className='imgApp'><img src="/icons/m-DownloadGoogle.svg" alt="" /></NavLink>
        <NavLink target="_blank" to={socialLink.appStorAuthIOS} className='imgApp'><img src="/icons/m-DownloadAppStore.svg" alt="" /></NavLink>
      </div>
      <button className='submitStep' onClick={() => submit(2)}>مرحله بعد</button>
    </div>
  )
}

export default ActiveGA1