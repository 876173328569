import { IoIosArrowDown } from "react-icons/io";
import { ClickAwayListener } from '@mui/material';
import { useRef, useState } from 'react';
import ListBankPopup from "./ListBankPopup";
import ListTextPopup from "./ListTextPopup";

const CustomSelectBoxLabel = ({ element, setInputSatet, listData, className, error, flag = true, label }: any) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false)

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={`customInput customInputSelect ${className} ${error && "brRed"}`} >
      <label className="labelInput">{label}</label>
      <ClickAwayListener onClickAway={handleClose}>
        <div className='textViewBox' onClick={() => setOpen(true)}>
          {element}
          <button ref={anchorRef} className='btnPopUp' > <span className="icon-Arrow2 cPointer"></span></button>
        </div>
      </ClickAwayListener>
      {flag
        ? <ListBankPopup listData={listData} className={open ? "" : "none"} onClick={(e: any) => setInputSatet(e)} />
        : <ListTextPopup listData={listData} className={open ? "" : "none"} onClick={(e: any) => setInputSatet(e)} />
        // : <ListAcountNumerBankPopup listData={listData} className={open ? "" : "none"} onClick={(e: any) => setInputSatet(e)} />
      }
    </div >
  )
}

export default CustomSelectBoxLabel