import React, { useState, memo, useEffect } from 'react'
import Navbar from '../../components/navbar/Navbar'
import Header from '../../components/header/Header'
import NavChangeHistory from './item/NavChangeHistory'
import { menu } from '../../config/menu'
import Footer from '../../components/Footer'
import _ from 'lodash'
import CoinHistory from './item/CoinHistory'

const HistoryDepositCoin = () => {

  useEffect(() => {
    document.title = menu.financial.childs.transactionHistory.childs.depositCoin.title;
  }, []);

  return (
    <>
      <div className='bodyMain'>
        <Navbar />
        <div className='newContainer'>
          <Header />
          <div className='transactionHistory'>
            <NavChangeHistory />
            <CoinHistory flag={true} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default memo(HistoryDepositCoin)