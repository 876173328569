import { useState } from "react";

const AccordionTickt = ({ num, title, dec }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <div className='accordion item'>
      <div className={`title ${open ? "open" : ""}`} onClick={() => setOpen(!open)}>
        <div>
          <span>{num}</span>
          <p>{title}</p>
        </div>
        <span className='icon-Arrow2'></span>
      </div>
      <div className={`des ${open ? "open" : ""}`}>
        <p>{dec}</p>
      </div>
    </div>
  )
}

export default AccordionTickt