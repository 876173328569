import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import gather from '../../helpers/gather';
import { config } from '../../config/config';

const initialState = {
  orderBook: {} as any,
};

const getOrderBook = createAsyncThunk('orderBook/fetchOrderBook', async (symbol: string) => {
  return await gather(config.api + `/v1/orderBook/${symbol}`).get();
});

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    updateOrderBook: (state, action) => {
      if (action.payload) {
        state.orderBook = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrderBook.fulfilled, (state, action) => {
      if (action.payload.code === 200) {
        state.orderBook = action.payload.data;
      }
    });
  },
});

export { getOrderBook };
export const { updateOrderBook } = slice.actions;
export default slice.reducer;
